export const TTS_VOICE_OPTIONS = [
  {
    value: 'none',
    label: 'None',
    description: 'Disable text-to-speech.',
  },
  {
    value: 'Joanna',
    label: 'Joanna',
    description: 'The default Female voice.',
  },
  {
    value: 'Matthew',
    label: 'Matthew',
    description: 'The default Male voice.',
  },
  {
    value: 'Kendra',
    label: 'Kendra',
    description: 'Serious Female voice.',
  },
  {
    value: 'Kimberly',
    label: 'Kimberly',
    description: 'Slower Female voice.',
  },
  {
    value: 'Salli',
    label: 'Salli',
    description: 'Lighter Female voice.',
  },
  {
    value: 'Joey',
    label: 'Joey',
    description: 'Slower Male voice.',
  },
  {
    value: 'Ruth',
    label: 'Ruth',
    description: 'Calm Female voice.',
  },
  {
    value: 'Stephen',
    label: 'Stephen',
    description: 'Lighter Male voice.',
  },
];

export const IMAGE_STYLES = [
  {
    value: 'default',
    label: 'Default',
    description: 'Default image style.',
  },
  {
    value: '3d-model',
    label: '3D Model',
    description: '3D model style.',
  },
  {
    value: 'analog-film',
    label: 'Analog Film',
    description: 'Analog film style.',
  },
  {
    value: 'anime',
    label: 'Anime',
    description: 'Anime style.',
  },
  {
    value: 'cinematic',
    label: 'Cinematic',
    description: 'Cinematic style.',
  },
  {
    value: 'comic-book',
    label: 'Comic Book',
    description: 'Comic book style.',
  },
  {
    value: 'digital-art',
    label: 'Digital Art',
    description: 'Digital art style.',
  },
  // {
  //   value: 'enhance',
  //   label: 'Enhance',
  //   description: 'Enhance style.',
  // },
  {
    value: 'fantasy-art',
    label: 'Fantasy Art',
    description: 'Fantasy art style.',
  },
  {
    value: 'isometric',
    label: 'Isometric',
    description: 'Isometric style.',
  },
  {
    value: 'line-art',
    label: 'Line Art',
    description: 'Line art style.',
  },
  {
    value: 'low-poly',
    label: 'Low Poly',
    description: 'Low poly style.',
  },
  // {
  //   value: "modeling-compound",
  //   label: "Modeling Compound",
  //   description: "Modeling compound style.",
  // },
  {
    value: 'neon-punk',
    label: 'Neon Punk',
    description: 'Neon punk style.',
  },
  {
    value: 'origami',
    label: 'Origami',
    description: 'Origami style.',
  },
  {
    value: 'photographic',
    label: 'Photographic',
    description: 'Photographic style.',
  },
  {
    value: 'pixel-art',
    label: 'Pixel Art',
    description: 'Pixel art style.',
  },
  // {
  //   value: "tile-texture",
  //   label: "Tiled Texture",
  //   description: "Tiled Texture style.",
  // },
];

export const EXAMPLE_TEXT =
  "You find yourself in a cold, dimly lit stone dungeon. The room is square, with walls covered in moss and creeping vines. There's a chilling wind seeping through the cracks in the walls, causing the torches mounted on the walls to flicker, casting long, dancing shadows. On the north wall, you see a large wooden door with an ornate lock mechanism. There's a sturdy wooden table in the center of the room, on top of which lies a dusty old book and a candle, unlit. To the east, there's a rack of various weapons, their blades gleaming in the torchlight. To the west, there's a mysterious stone statue of a hooded figure holding a staff. In the south, you see a large tapestry depicting a dragon fighting a knight. Underneath the tapestry, there's a chest with an unusual symbol engraved on it.";

export const DIFFICULTY_OPTIONS = [
  {
    value: 'easy',
    label: 'Easy',
    description: 'This room difficulty is easy. It is recommended for beginners.',
  },
  {
    value: 'medium',
    label: 'Medium',
    description: 'This room difficulty is medium. It is recommended for intermediate players.',
  },
  {
    value: 'hard',
    label: 'Hard',
    description: 'This room difficulty is hard. It is recommended for advanced players.',
  },
];

export const ROOM_IDEAS = [
  {
    name: 'Submarine Escape',
    theme: 'Submarine Adventure',
  },
  {
    name: 'Haunted House',
    theme: 'Escape an old Victorian mansion haunted by ghosts.',
  },
  {
    name: 'Space Station',
    theme: 'Escape a malfunctioning space station.',
  },
  {
    name: 'Pirate Ship',
    theme: 'Escape a pirate ship before it sinks.',
  },
  {
    name: 'Jungle Temple',
    theme: 'Escape a booby-trapped jungle temple.',
  },
  {
    name: 'Zombie Outbreak',
    theme: 'Escape a city overrun by zombies.',
  },
  {
    name: 'Time Travel',
    theme: 'Escape from different historical eras to return to the present.',
  },
  {
    name: 'Prison Break',
    theme: 'Escape from a high-security prison.',
  },
  {
    name: 'Murder Mystery',
    theme: 'Solve a murder mystery to escape the crime scene.',
  },
  {
    name: 'Alien Abduction',
    theme: 'Escape from an alien spaceship.',
  },
  {
    name: 'Castle Siege',
    theme: 'Escape a castle under siege.',
  },
  {
    name: 'Vampire Lair',
    theme: "Escape from a vampire's lair before sunrise.",
  },
  {
    name: 'Secret Laboratory',
    theme: 'Escape a lab where dangerous experiments are conducted.',
  },
  {
    name: 'Bank Heist',
    theme: 'Escape from a bank vault after a heist goes wrong.',
  },
  {
    name: 'Cursed Pyramid',
    theme: 'Escape from an ancient cursed pyramid.',
  },
  {
    name: "Magician's Workshop",
    theme: "Escape from a magician's workshop filled with illusions and traps.",
  },
  {
    name: "Sherlock's Study",
    theme: "Solve mysteries in Sherlock Holmes' study to escape.",
  },
  {
    name: 'Underwater Cave',
    theme: 'Escape from an underwater cave before your oxygen runs out.',
  },
  {
    name: 'Dinosaur Island',
    theme: 'Escape from an island inhabited by dinosaurs.',
  },
  {
    name: 'Medieval Dungeon',
    theme: 'Escape from a dark medieval dungeon.',
  },
  {
    name: 'Space Colony',
    theme: 'Escape a malfunctioning space colony on a distant planet.',
  },
  {
    name: 'Wild West',
    theme: 'Escape from a Wild West town after a bank robbery goes wrong.',
  },
  {
    name: 'Steampunk City',
    theme: 'Escape from a steampunk city before a catastrophic event.',
  },
  {
    name: 'Cursed Carnival',
    theme: 'Escape from a haunted carnival.',
  },
  {
    name: 'Enchanted Forest',
    theme: 'Escape from an enchanted forest filled with magical creatures.',
  },
  {
    name: 'Cyberpunk Heist',
    theme: 'Escape a high-tech city after a cyber heist goes wrong.',
  },
  {
    name: 'Art Heist',
    theme: 'Escape an art museum after a heist goes wrong.',
  },
  {
    name: 'Secret Agent',
    theme: "Escape from a secret agent's headquarters before you're caught.",
  },
  {
    name: 'Pirate Treasure',
    theme: 'Escape an island with buried pirate treasure.',
  },
  {
    name: 'Royal Palace',
    theme: 'Escape a royal palace under attack.',
  },
  {
    name: 'Haunted Hospital',
    theme: 'Escape from a haunted abandoned hospital.',
  },
  {
    name: 'Monster Lab',
    theme: 'Escape from a lab where monsters are created.',
  },
  {
    name: "Wizard's Tower",
    theme: "Escape from a wizard's tower filled with magic and mystery.",
  },
  {
    name: 'Mafia Hideout',
    theme: 'Escape from a mafia hideout before the boss returns.',
  },
  {
    name: 'Lost City',
    theme: "Escape from an ancient lost city before it's too late.",
  },
  {
    name: 'Frozen Tundra',
    theme: 'Escape from a frozen tundra before a blizzard strikes.',
  },
  {
    name: 'Abandoned Asylum',
    theme: 'Escape from an abandoned asylum filled with eerie secrets.',
  },
  {
    name: 'Deserted Island',
    theme: 'Escape from a deserted island before a storm hits.',
  },
  {
    name: 'Supervillain Lair',
    theme: "Escape from a supervillain's lair before their evil plan unfolds.",
  },
  {
    name: 'Mystic Library',
    theme: 'Escape from a library filled with ancient and magical books.',
  },
  {
    name: 'Robot Uprising',
    theme: 'Escape a city during a robot uprising.',
  },
  {
    name: 'Atlantis',
    theme: 'Escape the lost city of Atlantis before it sinks again.',
  },
  {
    name: 'Ghost Ship',
    theme: 'Escape from a ghost ship lost at sea.',
  },
  {
    name: 'Time Bomb',
    theme: 'Escape a building before a time bomb goes off.',
  },
  {
    name: 'Jungle Expedition',
    theme: 'Escape the jungle after a failed expedition.',
  },
  {
    name: 'Ancient Rome',
    theme: 'Escape from the ancient city of Rome during a crisis.',
  },
];
