import { Box, Container, Header, Button, LineChart } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import { ImageModel, Model } from '../../common/room';
import { useApiNoBody } from '../../common/api';
import { EscapeTurn } from '../../rooms/room/types';

export function SpeedGraph() {
  const navigate = useNavigate();
  const [messages, setMessages] = useState<EscapeTurnFromRoom[]>([]);

  const [roomsApi, error, loading] = useApiNoBody<EscapeTurnFromRoom[]>(
    '/session/messages',
    'GET',
    setMessages,
  );

  const fetchRooms = async () => {
    await roomsApi();
  };

  useEffect(() => {
    fetchRooms();
  }, []);

  const [plusModel, plusImage, freeModel] = useMemo(() => {
    const minDate = new Date(
      Math.min(...messages.map((message) => new Date(message.timestamp + 'Z').getTime())),
    );
    const minMonth = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
    const maxDate = new Date(
      Math.max(...messages.map((message) => new Date(message.timestamp + 'Z').getTime())),
    );
    const maxMonth = new Date(maxDate.getFullYear(), maxDate.getMonth(), 1);
    const months = [];

    for (let date = minMonth; date <= maxMonth; date.setMonth(date.getMonth() + 1)) {
      months.push(date.toISOString());
    }

    const plusModel = months
      .map((month) => {
        const matching = messages.filter((message) => {
          if (message.model !== 'gpt-4') {
            return false;
          }

          const date = new Date(message.timestamp + 'Z');
          const monthDate = new Date(date.getFullYear(), date.getMonth(), 1);

          return monthDate.toISOString() === month;
        });
        const total = matching.reduce((acc, message) => acc + (message.model_duration ?? 0), 0);
        const average = total / matching.length;

        return {
          x: new Date(month),
          y: average,
        };
      })
      .filter((e) => !isNaN(e.y));

    const freeModel = months
      .map((month) => {
        const matching = messages.filter((message) => {
          if (message.model === 'gpt-4') {
            return false;
          }

          const date = new Date(message.timestamp + 'Z');
          const monthDate = new Date(date.getFullYear(), date.getMonth(), 1);

          return monthDate.toISOString() === month;
        });
        const total = matching.reduce((acc, message) => acc + (message.model_duration ?? 0), 0);
        const average = total / matching.length;

        return {
          x: new Date(month),
          y: average,
        };
      })
      .filter((e) => !isNaN(e.y));

    const plusImage = months
      .map((month) => {
        const matching = messages.filter((message) => {
          if (message.image_model === 'stable-diffusion-xl-1024-v1-0' || !message.image_duration) {
            return false;
          }

          const date = new Date(message.timestamp + 'Z');
          const monthDate = new Date(date.getFullYear(), date.getMonth(), 1);

          return monthDate.toISOString() === month;
        });
        const total = matching.reduce((acc, message) => acc + (message.image_duration ?? 0), 0);
        const average = total / matching.length;

        return {
          x: new Date(month),
          y: average,
        };
      })
      .filter((e) => !isNaN(e.y));

    // const freeImage = months
    //   .map((month) => {
    //     const matching = messages.filter((message) => {
    //       if (message.image_model !== 'stable-diffusion-v1-5' || !message.image_duration) {
    //         return false;
    //       }

    //       const date = new Date(message.timestamp + 'Z');
    //       const monthDate = new Date(date.getFullYear(), date.getMonth(), 1);

    //       return monthDate.toISOString() === month;
    //     });
    //     const total = matching.reduce((acc, message) => acc + (message.image_duration ?? 0), 0);
    //     const average = total / matching.length;

    //     return {
    //       x: new Date(month),
    //       y: average,
    //     };
    //   })
    //   .filter((e) => !isNaN(e.y));

    return [plusModel, plusImage, freeModel];
  }, [messages]);

  return (
    <Container
      header={
        <Header
          // actions={
          //   <Button variant="normal" onClick={() => navigate('/manage/metrics')}>
          //     View Details
          //   </Button>
          // }
          description={<Box variant="span">Chat and Image AI model speed.</Box>}
          variant="h3"
        >
          AI Speed
        </Header>
      }
    >
      <LineChart
        empty={
          <Box color="inherit" textAlign="center">
            <b>No data available</b>
            <Box color="inherit" variant="p">
              There is no data available
            </Box>
          </Box>
        }
        height={200}
        i18nStrings={{
          xTickFormatter: (e) =>
            e
              .toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
              })
              .split(',')
              .join('\n'),
          yTickFormatter: (e) => e.toLocaleString(),
        }}
        series={[
          {
            title: 'Plus Chat',
            type: 'line',
            data: plusModel,
            valueFormatter: (e) => e.toFixed(1).toLocaleString() + 's',
          },
          {
            title: 'Image',
            type: 'line',
            data: plusImage,
            valueFormatter: (e) => e.toFixed(1).toLocaleString() + 's',
          },
          {
            title: 'Free Chat',
            type: 'line',
            data: freeModel,
            valueFormatter: (e) => e.toFixed(1).toLocaleString() + 's',
          },
          // {
          //   title: 'Free Image',
          //   type: 'line',
          //   data: freeImage,
          //   valueFormatter: (e) => e.toFixed(1).toLocaleString() + 's',
          // },
        ]}
        statusType={error ? 'error' : loading ? 'loading' : 'finished'}
        xScaleType="time"
        xTitle="Month"
        yTitle="Duration (seconds)"
        emphasizeBaselineAxis
        hideFilter
        hideLegend
      />
    </Container>
  );
}

export type EscapeTurnFromRoom = EscapeTurn & {
  model: Model;
  image_model: ImageModel;
};
