import { Header, HeaderProps } from '@cloudscape-design/components';

import { InfoLink } from '../../../common/info-link';

import { FeedbackForm } from './types';

export function getHeaderCounterText(
  items: ReadonlyArray<unknown>,
  selectedItems: ReadonlyArray<unknown> | undefined,
) {
  return selectedItems && selectedItems?.length > 0
    ? `(${selectedItems.length}/${items.length})`
    : `(${items.length})`;
}

export type FullPageHeaderProps = {
  title?: string;
  createButtonText?: string;
  extraActions?: React.ReactNode;
  selectedItems: readonly FeedbackForm[];
  onInfoLinkClick?: () => void;
  onCopyClick?: () => void;
  onDeleteClick?: () => void;
} & HeaderProps;

export function FullPageHeader({
  title = 'Direct Feedback',
  onInfoLinkClick,
  ...props
}: FullPageHeaderProps) {
  return (
    <Header
      info={
        onInfoLinkClick && (
          <InfoLink ariaLabel={`Information about ${title}.`} onFollow={onInfoLinkClick} />
        )
      }
      variant="awsui-h1-sticky"
      {...props}
    >
      {title}
    </Header>
  );
}
