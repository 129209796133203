import { useContext, useEffect, useState } from 'react';
import { Box, Container, Grid, Header, SpaceBetween, Wizard } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { useApi, useRefreshApi } from '../../common/api';
import {
  Difficulty,
  ImageStyle,
  Model,
  RoomInfo,
  RoomInfoRequest,
  RoomInfoResponse,
  TTSModel,
} from '../../common/room';
import UserContext from '../../common/user';
import { getPermissionsPrefix } from '../../common/auth';
import { EscapeChatTurn } from '../room/turn';

import BasicInfoStep from './components/step1';
import DifficultyInfoStep from './components/step2';
import TTSInfoStep from './components/step4';
import ImageModelInfoStep from './components/step5';
import ReviewInfoStep from './components/step6';
import { EXAMPLE_TEXT, ROOM_IDEAS } from './steps-config';

const i18nStrings = {
  stepNumberLabel: (stepNumber: number) => `Step ${stepNumber}`,
  collapsedStepsLabel: (stepNumber: number, stepsCount: number) =>
    `Step ${stepNumber} of ${stepsCount}`,
  errorIconAriaLabel: 'Error',
  navigationAriaLabel: 'Steps',
  cancelButton: 'Cancel',
  previousButton: 'Previous',
  nextButton: 'Next',
  submitButton: 'Create escape room',
  optional: 'optional',
};

export function CreateRoomWizard() {
  const { user, setCredits } = useContext(UserContext);
  const navigate = useNavigate();

  // TODO show error
  const [refreshApi, refreshError, refreshLoading] = useRefreshApi();

  const difficulties = getPermissionsPrefix('difficulty.', user);

  const [difficulty, setDifficulty] = useState<Difficulty>(
    difficulties.includes('hard') ? 'hard' : 'medium',
  );
  const [tts_voice, setTTSVoice] = useState<string | undefined>();
  const [image_style, setImageStyle] = useState<ImageStyle>('default');
  const [timestamp, setTimestamp] = useState(
    new Date().toISOString().slice(0, 19).replace('T', ' '),
  );

  // random choice in ROOM_IDEAS
  const [initialIdeaIndex, _] = useState(Math.floor(Math.random() * ROOM_IDEAS.length));

  // TODO randomly select a few themes and room names
  const DEFAULT_STEP_INFO: RoomInfo = {
    basic: {
      name: ROOM_IDEAS[initialIdeaIndex].name,
      theme: ROOM_IDEAS[initialIdeaIndex].theme,
      public: true,
      notes: '',
    },
    difficulty: {
      difficulty: difficulties.includes('hard') ? 'hard' : 'medium',
    },
    model: {
      tts_voice: undefined,
    },
    image: {
      image_style: 'default',
    },
  };

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [stepsInfo, setStepsInfo] = useState(DEFAULT_STEP_INFO);

  const [createApi, error, loading] = useApi<RoomInfoRequest, RoomInfoResponse>(
    '/session',
    'POST',
    (response) => {
      setCredits(response.credits);
      navigate(`/rooms/${response.uuid}`);
    },
  );

  const createRoom = async () => {
    const info = {
      name: stepsInfo.basic.name,
      theme: stepsInfo.basic.theme.toLowerCase() === 'default' ? null : stepsInfo.basic.theme,
      public: stepsInfo.basic.public,
      notes: stepsInfo.basic.notes,
      ...stepsInfo.difficulty,
      ...stepsInfo.model,
      ...stepsInfo.image,
    };
    await createApi(info);
  };

  const refresh = async () => {
    await refreshApi();
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <Grid
      gridDefinition={[
        {
          colspan: {
            default: 12,
            l: 7,
            s: 7,
          },
        },
        {
          colspan: {
            default: 12,
            l: 5,
            s: 5,
          },
        },
      ]}
    >
      <Wizard
        activeStepIndex={activeStepIndex}
        i18nStrings={i18nStrings}
        isLoadingNextStep={loading}
        steps={[
          {
            title: 'Information',
            description: 'Basic information about the room',
            content: (
              <BasicInfoStep
                info={stepsInfo.basic}
                onChange={(info) => setStepsInfo({ ...stepsInfo, basic: info })}
              />
            ),
          },
          {
            title: 'Difficulty',
            description: 'The difficulty of the room',
            content: (
              <DifficultyInfoStep
                difficulty={difficulty}
                info={stepsInfo.difficulty}
                setDifficulty={setDifficulty}
                onChange={(info) => setStepsInfo({ ...stepsInfo, difficulty: info })}
              />
            ),
          },
          {
            title: 'Speech',
            description: 'The AI that will be used to translate text to speech',
            content: (
              <TTSInfoStep
                info={stepsInfo.model}
                setTTSVoice={setTTSVoice}
                tts_voice={tts_voice}
                onChange={(info) => setStepsInfo({ ...stepsInfo, model: info })}
              />
            ),
          },
          {
            title: 'Image AI',
            description: 'The AI that will be used to visualize the room with generated images',
            content: (
              <ImageModelInfoStep
                image_style={image_style}
                info={stepsInfo.image}
                setImageStyle={setImageStyle}
                onChange={(info) => setStepsInfo({ ...stepsInfo, image: info })}
              />
            ),
          },
          {
            title: 'Create',
            description: 'Review the information and create the room',
            content: <ReviewInfoStep error={error} info={stepsInfo} loading={loading} />,
          },
        ]}
        onCancel={() => navigate('/rooms')}
        onNavigate={(e) => {
          setDifficulty(stepsInfo.difficulty.difficulty);
          setTTSVoice(stepsInfo.model.tts_voice);
          setImageStyle(stepsInfo.image.image_style);
          setActiveStepIndex(e.detail.requestedStepIndex);
        }}
        onSubmit={createRoom}
      />
      <Container
        header={
          <SpaceBetween direction="vertical" size="xs">
            <Header
              description="This is a preview of what your escape room will look like when you play it. This room uses the default theme and default difficulty, but your room will be customized!"
              variant="h3"
            >
              <Box color="text-status-info" fontSize="heading-l" fontWeight="heavy" variant="span">
                Room Preview
              </Box>
            </Header>
            <Header variant="h3">{stepsInfo.basic.name}</Header>
          </SpaceBetween>
        }
      >
        <EscapeChatTurn
          index={0}
          message={{
            role: 'assistant',
            content: EXAMPLE_TEXT ?? '...',
            image_url: `https://cdn.aiescape.io/${image_style}.png`,
            timestamp,
            tts_url:
              tts_voice && tts_voice !== 'none'
                ? `https://cdn.aiescape.io/gpt-4-polly-neural-${tts_voice}.mp3`
                : undefined,
          }}
          compact
          insideContainer
        />
      </Container>
    </Grid>
  );
}
