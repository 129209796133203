import { useContext } from 'react';
import {
  Box,
  AppLayout,
  Button,
  Grid,
  Container,
  Header,
  ColumnLayout,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../common/navigation';

export default function Feedback() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const navigate = useNavigate();

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Manage', href: '' },
            { text: 'Feedback', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <Grid
          gridDefinition={[
            {
              colspan: {
                default: 12,
                l: 8,
                s: 6,
              },
            },
          ]}
        >
          <Container header={<Header>User Feedback</Header>}>
            <ColumnLayout columns={2} variant="text-grid">
              <div>
                <Box variant="awsui-key-label">Direct</Box>
                <div>
                  <Button variant="link" onClick={() => navigate('/manage/feedback/direct')}>
                    See Feedback
                  </Button>
                </div>
              </div>
              <div>
                <Box variant="awsui-key-label">Rooms</Box>
                <div>
                  <Button variant="link" onClick={() => navigate('/manage/feedback/rooms')}>
                    See Feedback
                  </Button>
                </div>
              </div>
              {/* <div>
                <Box variant="awsui-key-label">Messages</Box>
                <div>
                  <Button
                    variant='link'
                    onClick={() => navigate('/manage/feedback/messages')}
                  >
                    See Feedback
                  </Button>
                </div>
              </div> */}
            </ColumnLayout>
          </Container>
        </Grid>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
