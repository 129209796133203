import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';

import { ROLE_INFO, UserListResponse } from '../../common/types';

function filterOperator(a: boolean, b: boolean, operator: 'and' | 'or') {
  return operator === 'and' ? a && b : a || b;
}

export function filterUsers(user: UserListResponse, query: PropertyFilterQuery) {
  let matches = query.operation === 'and';

  for (const token of query.tokens) {
    if (token.propertyKey === 'email') {
      if (token.operator === ':') {
        matches = filterOperator(
          matches,
          user.email.toLowerCase().includes(token.value.toLowerCase()),
          query.operation,
        );
      } else if (token.operator === '!:') {
        matches = filterOperator(
          matches,
          !user.email.toLowerCase().includes(token.value.toLowerCase()),
          query.operation,
        );
      }
    } else if (token.propertyKey === 'roles') {
      if (token.operator === ':') {
        matches = filterOperator(
          matches,
          ROLE_INFO.filter((role) => user.roles.includes(role.name) && role.name !== 'basic')
            .map((role) => role.display)
            .includes(token.value),
          query.operation,
        );
      } else if (token.operator === '!:') {
        matches = filterOperator(
          matches,
          !ROLE_INFO.filter((role) => user.roles.includes(role.name) && role.name !== 'basic')
            .map((role) => role.display)
            .includes(token.value),
          query.operation,
        );
      }
    }
  }

  return matches;
}
