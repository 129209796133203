import { Box, ColumnLayout, Header, Link } from '@cloudscape-design/components';

import { RoomDisplay } from '../../rooms/display';
import FreeSubscriptionInfo from '../../subscription/free';
import PlusSubscriptionInfo from '../../subscription/plus';
import { JoinWaitlist } from '../../waitlist/join';
import { BlogPostProps } from '../blog';

export function EventsPost(): BlogPostProps {
  const post: BlogPostProps = {
    name: 'events',
    title: 'Announcing Escape Events: AI-Powered Corporate Team-Building!',
    date: 'October 17th, 2024',
    user: 'Max Weinzierl',
    body: [
      {
        header: (
          <Header description="Escape with your team!" variant="h3">
            New Escape Product!
          </Header>
        ),
        content: [
          <Box variant="p">
            We hope you’ve enjoyed your time escaping from our AI-powered escape rooms! Today, we're
            excited to announce our latest innovation: Escape Events – an AI-powered escape room
            platform designed specifically for corporate events.
          </Box>,
        ],
      },
      {
        header: (
          <Header description="Teamwork makes the dream work" variant="h3">
            What is Escape Events?
          </Header>
        ),
        content: [
          <Box variant="p">
            With Escape Events, your colleagues can experience fully customized escape room
            challenges tailored to your business needs, whether you're focusing on corporate
            sustainability, cybersecurity, or just having fun! Our AI-generated puzzles, real-time
            progress tracking, and fully immersive experiences make Escape Events the perfect way to
            foster collaboration, problem-solving, and a bit of healthy competition.
          </Box>,
        ],
      },
      {
        header: (
          <Header description="Keys to fun team-building" variant="h3">
            Features?
          </Header>
        ),
        content: [
          <Box variant="p">Escape Events has the following features:</Box>,
          <Box variant="div">
            <ul>
              <li>
                <Box variant="p">
                  <strong>Customizable scenarios:</strong> Tailored to your industry or goals
                </Box>
              </li>
              <li>
                <Box variant="p">
                  <strong>Collaborative experience:</strong> Teams work together to escape
                </Box>
              </li>
              <li>
                <Box variant="p">
                  <strong>Scalable for any event size:</strong> Perfect for small or large
                </Box>
              </li>
              <li>
                <Box variant="p">
                  <strong>Real-time tracking:</strong> Monitor live progress and engagement
                </Box>
              </li>
            </ul>
          </Box>,
        ],
      },
      {
        header: (
          <Header description="Book a demo today!" variant="h3">
            Learn More!
          </Header>
        ),
        content: [
          <Box variant="p">
            Visit our website If you’re interested in learning more or scheduling a demo:
          </Box>,
          <Link href="https://events.aiescape.io" target="_blank" external>
            https://events.aiescape.io
          </Link>,
        ],
      },
    ],
  };

  return post;
}
