import { useContext, useState } from 'react';
import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  Form,
  SpaceBetween,
  Button,
  FormField,
  Input,
  Grid,
  Link,
  StatusIndicator,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';
import { UserPasswordReset } from '../common/types';
import { useApiNoResponse } from '../common/api';

export default function Reset() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const [email, setEmail] = useState<string>('');

  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const [sent, setSent] = useState<boolean>(false);

  const navigate = useNavigate();

  const [resetApi, error, loading] = useApiNoResponse<UserPasswordReset>('/auth/reset', 'POST');

  const reset = async () => {
    let formError = false;

    if (!email) {
      setEmailErrorText('Email is required');
      formError = true;
    } else {
      setEmailErrorText('');
    }

    if (formError) {
      return;
    }

    const info: UserPasswordReset = {
      email,
    };
    const response = await resetApi(info);

    if (!response.error) {
      // TODO - show success message
      setSent(true);
    }
  };

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Reset Password', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Reset Password</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 6,
                  s: 4,
                },
              },
            ]}
          >
            <Container header={<Header variant="h2">Reset your Password</Header>}>
              <Box variant="p">
                Enter your email address below and we will send you a link to reset your password.
              </Box>
              <form onSubmit={(event) => event.preventDefault()}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button disabled={sent} loading={loading} variant="primary" onClick={reset}>
                        Send Email
                      </Button>
                    </SpaceBetween>
                  }
                  errorText={error}
                >
                  <SpaceBetween size="l">
                    <FormField errorText={emailErrorText} label="Email">
                      <Input
                        disabled={loading || sent}
                        placeholder="Enter email..."
                        type="email"
                        value={email}
                        onChange={({ detail: { value } }) => setEmail(value)}
                      />
                    </FormField>

                    <SpaceBetween size="xxxs">
                      <Box variant="p">
                        <Link
                          href="/signup"
                          onFollow={(event) => {
                            event.preventDefault();
                            navigate('/signup');
                          }}
                        >
                          Don&apos;t have an account? Sign up here.
                        </Link>
                      </Box>
                      <Box variant="p">
                        <Link
                          href="/login"
                          onFollow={(event) => {
                            event.preventDefault();
                            navigate('/login');
                          }}
                        >
                          Remember your password? Login here.
                        </Link>
                      </Box>
                    </SpaceBetween>
                    {loading ? (
                      <StatusIndicator type="loading">Sending email...</StatusIndicator>
                    ) : sent ? (
                      <StatusIndicator type="success">Sent! Check your email</StatusIndicator>
                    ) : (
                      <div />
                    )}
                  </SpaceBetween>
                </Form>
              </form>
            </Container>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
