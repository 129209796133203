import { createContext, useContext, useEffect } from 'react';
import SideNavigation, { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import BreadcrumbGroup, {
  BreadcrumbGroupProps,
} from '@cloudscape-design/components/breadcrumb-group';
import TopNavigation from '@cloudscape-design/components/top-navigation';
import { useLocation, useNavigate } from 'react-router-dom';

import UserContext, { hasPermissions } from '../user';
import { useApiNoBody } from '../api';
import { CreditsResponse } from '../types';

export type NavigationContextType = {
  navigationOpen: boolean;
  setNavigationOpen: (value: boolean) => void;
  navigationSize: number;
};

export const NavigationContext = createContext<NavigationContextType>({
  navigationOpen: true,
  navigationSize: 200,
  setNavigationOpen: (value: boolean) => {
    return;
  },
});

const HOME = { text: 'AI Escape', href: '/' };

export function Breadcrumbs({ items }: { items: BreadcrumbGroupProps.Item[] }) {
  const navigate = useNavigate();

  return (
    <BreadcrumbGroup
      items={[HOME, ...items]}
      onClick={(event) => {
        if (event.detail.external) {
          return;
        }
        event.preventDefault();
        navigate(event.detail.href);
      }}
    />
  );
}

export function LocalNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);

  const sideItems: SideNavigationProps.Item[] = [
    {
      type: 'section',
      text: 'Info',
      items: [
        { type: 'link', text: 'Home', href: '/' },
        { type: 'link', text: 'About Us', href: '/about' },
        { type: 'link', text: 'Sign Up', href: '/signup' },
      ],
    },
    { type: 'divider' },
    {
      type: 'section',
      text: 'Community',
      items: [
        { type: 'link', text: 'Feed', href: '/feed' },
        { type: 'link', text: 'Blog', href: '/blog' },
      ],
    },
  ];
  sideItems.push({ type: 'divider' });
  const escapeItems: SideNavigationProps.Item[] = [
    { type: 'link', text: 'Plan', href: '/subscription' },
  ];

  if (user) {
    escapeItems.push({ type: 'link', text: 'Rooms', href: '/rooms' });
    escapeItems.push({ type: 'link', text: 'Create', href: '/rooms/create' });
    escapeItems.push({ type: 'link', text: 'Feedback', href: '/feedback' });
  }
  sideItems.push({
    type: 'section',
    text: 'Escape',
    items: escapeItems,
  });

  const manageItems: SideNavigationProps.Item[] = [];

  if (hasPermissions(user, ['users.get'])) {
    manageItems.push({ type: 'link', text: 'Metrics', href: '/manage/metrics' });
  }

  if (
    hasPermissions(user, ['users.get', 'users.roles.add', 'users.roles.get', 'users.roles.delete'])
  ) {
    manageItems.push({ type: 'link', text: 'Users', href: '/manage/users' });
  }

  if (hasPermissions(user, ['invites.create', 'invites.get', 'invites.delete'])) {
    manageItems.push({ type: 'link', text: 'Invites', href: '/manage/invites' });
  }

  // if (hasPermissions(user, ['waitlist.manage.get', 'waitlist.manage.update'])) {
  //   manageItems.push({ type: 'link', text: 'Waitlist', href: '/manage/waitlist' });
  // }

  if (hasPermissions(user, ['roles.create', 'permissions.create'])) {
    manageItems.push({ type: 'link', text: 'Auth', href: '/manage/auth' });
  }

  if (hasPermissions(user, ['users.get'])) {
    manageItems.push({ type: 'link', text: 'Feedback', href: '/manage/feedback' });
  }

  if (manageItems.length > 0) {
    sideItems.push({ type: 'divider' });
    sideItems.push({
      type: 'section',
      text: 'Manage',
      items: manageItems,
    });
  }

  return (
    <SideNavigation
      activeHref={location.pathname}
      className="side-navigation"
      items={sideItems}
      onFollow={(event) => {
        if (event.detail.external) {
          return;
        }
        event.preventDefault();
        navigate(event.detail.href);
      }}
    />
  );
}

export function LocalTopNavigation() {
  const { user, credits, setCredits } = useContext(UserContext);
  const navigate = useNavigate();

  const [creditsApi, creditsError, creditsLoading] = useApiNoBody<CreditsResponse>(
    '/user/credits',
    'GET',
    (data) => {
      setCredits(data.credits);
    },
  );

  useEffect(() => {
    if (user) {
      creditsApi();
    }
  }, [user]);

  return (
    <TopNavigation
      className="top-navigation"
      identity={{
        title: 'AI Escape',
        href: '/',
        logo: {
          src: 'https://cdn.aiescape.io/header-logo.png',
          alt: 'AI Escape',
        },
        onFollow: (event) => {
          event.preventDefault();
          navigate('/');
        },
      }}
      utilities={
        user
          ? [
              {
                type: 'menu-dropdown',
                text: user.given_name,
                description: user.email,
                iconName: 'user-profile',
                onItemClick: (event) => {
                  if (event.detail.external) {
                    return;
                  }
                  event.preventDefault();
                  const href = event.detail.href;

                  if (href) {
                    navigate(href);
                  }
                },
                items: [
                  {
                    id: 'profile',
                    text: 'Profile',
                    href: `/profile/${user.uuid}`,
                    iconName: 'user-profile-active',
                  },
                  {
                    id: 'credits',
                    text:
                      creditsLoading || creditsError
                        ? '? Credits'
                        : credits === 1
                        ? `${credits.toLocaleString()} Credit`
                        : `${credits.toLocaleString()} Credits`,
                    href: '/credits',
                    iconName: 'key',
                  },
                  {
                    id: 'plan',
                    text:
                      'Plan: ' +
                      (user.subscription
                        ? user.subscription.role === 'advanced'
                          ? 'Plus+'
                          : 'Free'
                        : 'Free'),
                    href: '/subscription',
                  },
                  { id: 'account', text: 'Account', href: '/account' },
                  { id: 'signout', text: 'Sign out', href: '/logout' },
                ],
              },
            ]
          : [
              {
                type: 'button',
                text: 'Login',
                href: '/login',
                onClick: (event) => {
                  event.preventDefault();
                  navigate('/login');
                },
              },
              {
                type: 'button',
                text: 'Sign up',
                href: '/signup',
                onClick: (event) => {
                  event.preventDefault();
                  navigate('/signup');
                },
              },
            ]
      }
    />
  );
}
