import { useContext, useEffect, useState } from 'react';
import {
  Box,
  AppLayout,
  Cards,
  Button,
  SpaceBetween,
  Pagination,
  CollectionPreferences,
  PropertyFilter,
} from '@cloudscape-design/components';
import { useLocalStorage } from 'react-use';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { trackWindowScroll } from 'react-lazy-load-image-component';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../../common/navigation';
import { getTextFilterCounterText } from '../../../common/i18n-strings';
import { useApiNoBody } from '../../../common/api';
import { ProfileName } from '../../../profile/name';

import { FeedbackForm } from './types';
import { FullPageHeader, getHeaderCounterText } from './full-header';
// import { filterFeedbackForms } from './filter';

const DEFAULT_PREFERENCES = {
  pageSize: 30,
};

const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Feedback' },
  { value: 30, label: '30 Feedback' },
  { value: 50, label: '50 Feedback' },
];

export function FeedbackForms() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const pageSize = 12;
  const [preferences, setPreferences] = useLocalStorage(
    'React-Cards-Preferences',
    DEFAULT_PREFERENCES,
  );
  const [forms, setForms] = useState<FeedbackForm[]>([]);

  const [feedbackApi, error, loading] = useApiNoBody<FeedbackForm[]>('/feedback', 'GET', setForms);

  const resourceName = 'Feedback';
  const fetchFeedback = async () => {
    await feedbackApi();
  };

  useEffect(() => {
    fetchFeedback();
  }, []);

  const {
    items,
    actions,
    collectionProps,
    propertyFilterProps,
    paginationProps,
    filteredItemsCount,
  } = useCollection(forms, {
    propertyFiltering: {
      filteringProperties: [
        // {
        //   key: 'difficulty',
        //   // TODO enable >, <, <=, >=
        //   operators: ['=', '!='],
        //   propertyLabel: 'Difficulty',
        //   groupValuesLabel: 'Difficulty values',
        // },
        // {
        //   key: 'status',
        //   operators: ['=', '!='],
        //   propertyLabel: 'Status',
        //   groupValuesLabel: 'Status values',
        // },
        // {
        //   key: "state",
        //   operators: ["=", "!=", ":", "!:"],
        //   propertyLabel: "State",
        //   groupValuesLabel: "State values"
        // },
        // {
        //   key: "instancetype",
        //   operators: ["=", "!=", ":", "!:"],
        //   propertyLabel: "Instance type",
        //   groupValuesLabel: "Instance type values"
        // },
        // {
        //   key: "averagelatency",
        //   operators: ["=", "!=", ">", "<", "<=", ">="],
        //   propertyLabel: "Average latency",
        //   groupValuesLabel: "Average latency values"
        // }
      ],
      // filteringFunction: filterFeedbackForms,
      empty: (
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {error ? (
            <SpaceBetween size="xxs">
              <div>
                <b>Error retrieving {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  {error}
                </Box>
              </div>
              <Button onClick={fetchFeedback}>Retry</Button>
            </SpaceBetween>
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()}s found.
                </Box>
              </div>
            </SpaceBetween>
          )}
        </Box>
      ),
      noMatch: (
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          <SpaceBetween size="xxs">
            <div>
              <b>No matches</b>
              <Box color="inherit" variant="p">
                We can&apos;t find a match.
              </Box>
            </div>
            <Button onClick={() => actions.setPropertyFiltering({ operation: 'and', tokens: [] })}>
              Clear filter
            </Button>
          </SpaceBetween>
        </Box>
      ),
    },
    pagination: { pageSize },
  });

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Manage', href: '' },
            { text: 'Feedback', href: '/manage/feedback' },
            { text: 'Direct', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <Cards
          {...collectionProps}
          cardDefinition={{
            header: (item) => (
              <SpaceBetween direction="vertical" size="xs">
                <Box
                  color="text-status-info"
                  fontSize="heading-m"
                  fontWeight="heavy"
                  variant="span"
                >
                  {item.title}
                </Box>
              </SpaceBetween>
            ),
            sections: [
              {
                id: 'user',
                content: (item) => (
                  <Box variant="span">
                    <ProfileName user={item.user} hideLink manage /> at{' '}
                    {new Date(item.created_at + 'Z').toLocaleString()}
                  </Box>
                ),
              },
              {
                id: 'body',
                content: (item) => (
                  <SpaceBetween direction="vertical" size="xxxs">
                    {item.body.split('\n').map((line, i) => (
                      <Box key={`line-${i}`} variant="p">
                        {line}
                      </Box>
                    ))}
                  </SpaceBetween>
                ),
              },
            ],
          }}
          filter={
            <PropertyFilter
              {...propertyFilterProps}
              countText={getTextFilterCounterText(filteredItemsCount || 0)}
              disabled={loading}
              filteringOptions={
                [
                  // {
                  //   propertyKey: 'difficulty',
                  //   value: 'Easy',
                  // },
                  // {
                  //   propertyKey: 'difficulty',
                  //   value: 'Medium',
                  // },
                  // {
                  //   propertyKey: 'difficulty',
                  //   value: 'Hard',
                  // },
                  // {
                  //   propertyKey: 'status',
                  //   value: 'In-Progress',
                  // },
                  // {
                  //   propertyKey: 'status',
                  //   value: 'Escaped',
                  // },
                ]
              }
            />
            // <TextFilter
            //   {...filterProps}
            //   filteringAriaLabel="Filter rooms"
            //   filteringPlaceholder="Find rooms"
            //   filteringClearAriaLabel="Clear"
            //   countText={getTextFilterCounterText(filteredItemsCount || 0)}
            //   disabled={status.loading}
            // />
          }
          header={
            <FullPageHeader
              counter={getHeaderCounterText(forms, collectionProps.selectedItems)}
              selectedItems={[]}
            />
          }
          items={items}
          loading={loading}
          loadingText="Loading feedback"
          pagination={<Pagination {...paginationProps} disabled={loading} />}
          preferences={
            <CollectionPreferences
              disabled={loading}
              pageSizePreference={{
                options: PAGE_SIZE_OPTIONS,
              }}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail.custom)}
            />
          }
          // TODO use PropertyFilter
          // https://cloudscape.design/components/property-filter/?tabId=playground
          // selectionType="multi"
          variant="full-page"
          stickyHeader
        />
      }
      contentType="cards"
      headerSelector=".top-navigation"
      maxContentWidth={Number.MAX_VALUE}
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}

export default trackWindowScroll(FeedbackForms);
