import { useState } from 'react';
import {
  Container,
  Box,
  Header,
  Form,
  SpaceBetween,
  Button,
  FormField,
  Input,
  Link,
  StatusIndicator,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { WaitlistStatus } from '../common/types';

function statusToMessage(status: WaitlistStatus) {
  switch (status) {
    case 'approved':
      return 'You are approved!';
    case 'pending':
      return 'You have joined the waitlist!';
    case 'rejected':
      return 'You have been rejected from the waitlist.';
    case 'unknown':
    default:
      return null;
  }
}

function statusToStatusIndicator(status: WaitlistStatus) {
  switch (status) {
    case 'approved':
      return 'success';
    case 'pending':
      return 'info';
    case 'rejected':
      return 'warning';
    default:
      return 'loading';
  }
}

export function JoinWaitlist() {
  const status = 'approved';
  const message = statusToMessage(status);
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');

  return (
    <Container header={<Header variant="h2">Join Waitlist</Header>}>
      <form onSubmit={(event) => event.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="primary" disabled>
                Join
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween size="l">
            <FormField label="Email">
              <Input
                placeholder="Enter email..."
                type="email"
                value={email}
                disabled
                onChange={({ detail: { value } }) => setEmail(value)}
              />
            </FormField>

            <StatusIndicator type={`${statusToStatusIndicator(status)}`}>{message}</StatusIndicator>
            <Box variant="p">
              <Link
                href="/signup"
                onFollow={(event) => {
                  event.preventDefault();
                  navigate('/signup');
                }}
              >
                AI Escape is no longer in closed beta! Sign up here.
              </Link>
            </Box>
            <Box variant="p">
              <Link
                href="/login"
                onFollow={(event) => {
                  event.preventDefault();
                  navigate('/login');
                }}
              >
                Have an account? Login here.
              </Link>
            </Box>
          </SpaceBetween>
        </Form>
      </form>
    </Container>
  );
}
