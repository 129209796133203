import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';

import { UserInviteResponse } from '../../common/types';

function filterOperator(a: boolean, b: boolean, operator: 'and' | 'or') {
  return operator === 'and' ? a && b : a || b;
}

export function filterInvites(invite: UserInviteResponse, query: PropertyFilterQuery) {
  let matches = query.operation === 'and';

  for (const token of query.tokens) {
    if (token.propertyKey === 'status') {
      const status = invite.redeemed ? 'Redeemed' : 'Pending';

      if (token.operator === '=') {
        matches = filterOperator(
          matches,
          status.toLowerCase() === token.value.toLowerCase(),
          query.operation,
        );
      } else if (token.operator === '!=') {
        matches = filterOperator(
          matches,
          status.toLowerCase() !== token.value.toLowerCase(),
          query.operation,
        );
      }
    } else if (token.propertyKey === 'email') {
      if (token.operator === ':') {
        matches = filterOperator(
          matches,
          invite.email.toLowerCase().includes(token.value.toLowerCase()),
          query.operation,
        );
      } else if (token.operator === '!:') {
        matches = filterOperator(
          matches,
          !invite.email.toLowerCase().includes(token.value.toLowerCase()),
          query.operation,
        );
      }
    }
    // else if (token.propertyKey === 'roles') {
    //   if (token.operator === ':') {
    //     matches = filterOperator(
    //       matches,
    //       invite.roles.some((r) => r.toLowerCase() === token.value.toLowerCase()),
    //       query.operation,
    //     );
    //   } else if (token.operator === '!:') {
    //     matches = filterOperator(
    //       matches,
    //       !invite.roles.some((r) => r.toLowerCase() === token.value.toLowerCase()),
    //       query.operation,
    //     );
    // }
    // }
  }

  return matches;
}
