import {
  Box,
  Button,
  Form,
  FormField,
  Modal,
  RadioGroup,
  SpaceBetween,
  Textarea,
} from '@cloudscape-design/components';
import { useState } from 'react';

import { useApiNoResponse } from '../../common/api';

import { EscapeRoomFeedback, EscapeRoomStatus } from './types';

export type RoomFeedbackProps = {
  room: EscapeRoomStatus;
  feedbackOpen: boolean;
  setFeedbackOpen: (feedbackOpen: boolean) => void;
};

export function RoomFeedback(props: RoomFeedbackProps) {
  const { room, feedbackOpen, setFeedbackOpen } = props;

  const [feedbackApi, feedbackApiError, feedbackLoading] = useApiNoResponse<EscapeRoomFeedback>(
    `/session/${room.id}/feedback`,
    'POST',
  );
  const [feedback, setFeedback] = useState<string>();
  const [rating, setRating] = useState<number | null>(null);
  const [feedbackError, setFeedbackError] = useState<string>('');

  const sendFeedback = async () => {
    if (feedbackLoading) return;
    setFeedbackError('');

    if (rating === null) {
      setFeedbackError('Please select a rating');

      return;
    }

    const response = await feedbackApi({
      feedback,
      rating,
    });

    if (!response.error) {
      setFeedbackOpen(false);
      setFeedbackError('');
    } else {
      setFeedbackError(response.error);
    }
  };

  return (
    <Modal
      closeAriaLabel="Skip"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setFeedbackOpen(false)}>
              Skip
            </Button>
            <Button loading={feedbackLoading} variant="primary" onClick={sendFeedback}>
              Send
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Room Feedback"
      visible={feedbackOpen}
      onDismiss={() => setFeedbackOpen(false)}
    >
      <Box variant="p">Congratulations on completing the escape room!</Box>
      <Box variant="p">Please provide feedback on your experience with this escape room.</Box>
      <form onSubmit={(event) => event.preventDefault()}>
        <Form errorIconAriaLabel="Error" errorText={feedbackError}>
          <SpaceBetween size="l">
            <FormField
              description="Select a rating from 1 (Terrible) to 5 (Amazing)"
              label="Rating"
            >
              <RadioGroup
                items={[
                  {
                    value: '5',
                    label: '5 - Amazing',
                  },
                  {
                    value: '4',
                    label: '4 - Cool',
                  },
                  {
                    value: '3',
                    label: '3 - Ok',
                  },
                  {
                    value: '2',
                    label: '2 - Bad',
                  },
                  {
                    value: '1',
                    label: '1 - Terrible',
                  },
                ]}
                value={rating === null ? null : `${rating}`}
                onChange={({ detail }) => setRating(parseInt(detail.value))}
              />
            </FormField>
            <FormField
              description="Provide any additional feedback you have about this room."
              label="Additional Feedback"
            >
              <Textarea
                disabled={feedbackLoading}
                placeholder="Enter feedback..."
                value={feedback ?? ''}
                onChange={({ detail: { value } }) => setFeedback(value)}
              />
            </FormField>
          </SpaceBetween>
        </Form>
      </form>
    </Modal>
  );
}
