import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  Link,
} from '@cloudscape-design/components';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';

export default function Error() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const navigate = useNavigate();

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Error', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">404 Not Found</Header>}>
          <Container header={<Header variant="h2">Oops! We've Hit a Dead End</Header>}>
            <Box variant="p">
              It appears you've stumbled upon a locked door within the virtual corridors of AI
              Escape. Fear not, for this is merely a temporary setback on our ever-evolving
              adventure.
            </Box>
            <Box variant="p">
              While our AI experts work tirelessly to provide you with the most dynamic escape room
              experiences, it seems we've encountered a little hiccup along the way. Rest assured,
              our team is already on the case to get things back on track.
            </Box>
            <Box variant="p">
              In the meantime, why not return to the starting point and continue exploring our world
              of artificial intelligence and immersive escape rooms?
            </Box>
            <Link
              href="/"
              variant="secondary"
              onFollow={(event) => {
                event.preventDefault();
                navigate('/');
              }}
            >
              Back to Home
            </Link>
            <Box variant="p">
              Don't let a minor detour deter you. The excitement and challenges of AI Escape await
              you just around the corner. Good luck, and happy escaping!
            </Box>
          </Container>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
