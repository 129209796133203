import { Header, AppLayout, ContentLayout, Grid } from '@cloudscape-design/components';
import { useContext } from 'react';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../common/navigation';

import { RolesTable } from './roles';
import { PermissionsTable } from './perms';

export default function ManageAuth() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Manage', href: '' },
            { text: 'Authorization', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Manage Authorization</Header>}>
          <Grid
            gridDefinition={[
              { colspan: { default: 12, s: 4 } },
              { colspan: { default: 12, s: 8 } },
            ]}
          >
            <RolesTable />
            <PermissionsTable />
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
