import { useContext, useState } from 'react';
import {
  Container,
  Header,
  AppLayout,
  ContentLayout,
  Form,
  SpaceBetween,
  Button,
  FormField,
  Input,
  DatePicker,
  Grid,
  StatusIndicator,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../common/navigation';
import { UserUpdate } from '../../common/types';
import UserContext from '../../common/user';
import { useApiNoResponse, useRefreshApi } from '../../common/api';

function calculateAge(dob: Date) {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

export default function UpdateAccount() {
  const navigate = useNavigate();

  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const { user } = useContext(UserContext);

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [givenName, setGivenName] = useState<string>(user?.given_name || '');
  const [familyName, setFamilyName] = useState<string>(user?.family_name || '');
  const [birthdate, setBirthdate] = useState<string>(user?.birthdate || '');

  const [passwordErrorText, setPasswordErrorText] = useState<string>('');
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState<string>('');
  const [givenNameErrorText, setGivenNameErrorText] = useState<string>('');
  const [familyNameErrorText, setFamilyNameErrorText] = useState<string>('');
  const [birthdateErrorText, setBirthdateErrorText] = useState<string>('');

  const [updateApi, error, loading] = useApiNoResponse<UserUpdate>('/user', 'POST');

  // TODO show error
  const [refreshApi, refreshError, refreshLoading] = useRefreshApi();

  const update = async () => {
    let formError = false;

    if (password) {
      if (password.length < 12) {
        setPasswordErrorText('Password must be at least 12 characters');
        formError = true;
      } else if (password.toUpperCase() === password) {
        setPasswordErrorText('Password must contain at least one lowercase letter');
        formError = true;
      } else if (password.toLowerCase() === password) {
        setPasswordErrorText('Password must contain at least one uppercase letter');
        formError = true;
      } else if (password.search(/[0-9]/) < 0) {
        setPasswordErrorText('Password must contain at least one number');
        formError = true;
      } else if (password.search(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/) < 0) {
        setPasswordErrorText('Password must contain at least one special character');
        formError = true;
      } else {
        setPasswordErrorText('');
      }

      if (!confirmPassword) {
        setConfirmPasswordErrorText('Confirm password is required');
        formError = true;
      } else if (password !== confirmPassword) {
        setConfirmPasswordErrorText('Passwords do not match');
        formError = true;
      } else {
        setConfirmPasswordErrorText('');
      }
    } else {
      setPasswordErrorText('');
      setConfirmPasswordErrorText('');
    }

    if (!givenName) {
      setGivenNameErrorText('Given name is required');
      formError = true;
    } else {
      setGivenNameErrorText('');
    }

    if (!familyName) {
      setFamilyNameErrorText('Family name is required');
      formError = true;
    } else {
      setFamilyNameErrorText('');
    }

    if (!birthdate) {
      setBirthdateErrorText('Birth date is required');
      formError = true;
    } else {
      const age = calculateAge(new Date(birthdate));

      if (age < 16) {
        setBirthdateErrorText('You must be at least 16 years old to sign up');
        formError = true;
      } else {
        setBirthdateErrorText('');
      }
    }

    if (formError) {
      return;
    }

    const info: UserUpdate = {
      password: password ? password : undefined,
      given_name: givenName,
      family_name: familyName,
      birthdate,
    };

    const response = await updateApi(info);

    if (!response.error) {
      const result = await refreshApi();

      if (!result.error) {
        navigate(`/account`);
      }
    }
  };

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Account', href: '/account' },
            { text: 'Update', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Update Your Account</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 6,
                  s: 4,
                },
              },
            ]}
          >
            <form onSubmit={(event) => event.preventDefault()}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="link"
                      onClick={() => {
                        navigate(`/account`);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button loading={loading} variant="primary" onClick={update}>
                      Update
                    </Button>
                  </SpaceBetween>
                }
                errorText={error}
              >
                <SpaceBetween size="l">
                  <Container header={<Header variant="h2">Update Account Details</Header>}>
                    <SpaceBetween size="l">
                      <FormField
                        description="First name / given name."
                        errorText={givenNameErrorText}
                        label="Given Name"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Enter given name..."
                          type="text"
                          value={givenName}
                          onChange={({ detail: { value } }) => setGivenName(value)}
                        />
                      </FormField>
                      <FormField
                        description="Last name / family name."
                        errorText={familyNameErrorText}
                        label="Family Name"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Enter family name..."
                          type="text"
                          value={familyName}
                          onChange={({ detail: { value } }) => setFamilyName(value)}
                        />
                      </FormField>
                      <FormField
                        description="Enter a new password for your account. Your password must be at least 12 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                        errorText={passwordErrorText}
                        label="Password"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Enter password..."
                          type="password"
                          value={password}
                          onChange={({ detail: { value } }) => setPassword(value)}
                        />
                      </FormField>
                      <FormField
                        description="Re-enter your new password to confirm."
                        errorText={confirmPasswordErrorText}
                        label="Confirm Password"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Re-enter new password..."
                          type="password"
                          value={confirmPassword}
                          onChange={({ detail: { value } }) => setConfirmPassword(value)}
                        />
                      </FormField>
                      <FormField
                        className="date-time-container"
                        constraintText="Use YYYY/MM/DD format."
                        description="Provide your birth date."
                        errorText={birthdateErrorText}
                      >
                        <DatePicker
                          disabled={loading}
                          nextMonthAriaLabel="Next month"
                          openCalendarAriaLabel={(selectedDate) =>
                            'Choose birth date' +
                            (selectedDate ? `, selected date is ${selectedDate}` : '')
                          }
                          placeholder="YYYY/MM/DD"
                          previousMonthAriaLabel="Previous month"
                          todayAriaLabel="Today"
                          value={birthdate}
                          onChange={({ detail: { value } }) => setBirthdate(value)}
                        />
                      </FormField>
                      {loading ? (
                        <StatusIndicator type="loading">Updating account...</StatusIndicator>
                      ) : (
                        <div />
                      )}
                    </SpaceBetween>
                  </Container>
                </SpaceBetween>
              </Form>
            </form>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
