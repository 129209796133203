import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';

import { UserWaitlistResponse } from '../../common/types';

function filterOperator(a: boolean, b: boolean, operator: 'and' | 'or') {
  return operator === 'and' ? a && b : a || b;
}

export function filterWaitlists(waitlist: UserWaitlistResponse, query: PropertyFilterQuery) {
  let matches = query.operation === 'and';

  for (const token of query.tokens) {
    if (token.propertyKey === 'status') {
      if (token.operator === '=') {
        matches = filterOperator(
          matches,
          waitlist.status === token.value.toLowerCase(),
          query.operation,
        );
      } else if (token.operator === '!=') {
        matches = filterOperator(
          matches,
          waitlist.status !== token.value.toLowerCase(),
          query.operation,
        );
      }
    } else if (token.propertyKey === 'email') {
      if (token.operator === ':') {
        matches = filterOperator(
          matches,
          waitlist.email.toLowerCase().includes(token.value.toLowerCase()),
          query.operation,
        );
      } else if (token.operator === '!:') {
        matches = filterOperator(
          matches,
          !waitlist.email.toLowerCase().includes(token.value.toLowerCase()),
          query.operation,
        );
      }
    }
  }

  return matches;
}
