import { Header } from '@cloudscape-design/components';
import { ScrollPosition } from 'react-lazy-load-image-component';

import { EscapeRoomSessionEvent } from '../rooms/room/types';
import { EscapeChatTurn } from '../rooms/room/turn';
import { RoomActions } from '../rooms/room/actions';
import { RoomLink } from '../rooms/room/link';
import { parseCaption } from '../common/caption';

import { EscapeEvent } from './event';

export type RoomCreatedProps = {
  event: EscapeRoomSessionEvent;
  hideUsers?: boolean;
  insideContainer?: boolean;
  scrollPosition?: ScrollPosition;
  size?: 's' | 'm' | 'l';
};

export function RoomCreated(props: RoomCreatedProps) {
  const event = props.event;
  const roomId = event.session_uuid;
  const roomContent = (
    <EscapeChatTurn
      header={
        <Header
          actions={
            <RoomActions event={event} roomId={roomId} scrollPosition={props.scrollPosition} />
          }
          variant="h3"
        >
          <RoomLink roomId={roomId} title={event.title} />
        </Header>
      }
      index={0}
      insideContainer={props.insideContainer}
      message={{
        role: 'assistant',
        content: event.text,
        image_url: event.image_url,
        caption: parseCaption(event.caption),
        timestamp: event.text_timestamp,
      }}
      roomId={roomId}
      scrollPosition={props.scrollPosition}
      size={props.size ?? 'm'}
      compact
    />
  );

  if (props.hideUsers) {
    return roomContent;
  }

  return (
    <EscapeEvent event={event} title="Created a Room!">
      {roomContent}
    </EscapeEvent>
  );
}
