import { useContext, useState } from 'react';
import {
  Container,
  Header,
  AppLayout,
  ContentLayout,
  Form,
  SpaceBetween,
  Button,
  FormField,
  Input,
  Grid,
  StatusIndicator,
} from '@cloudscape-design/components';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../common/navigation';
import { UserPasswordResetChange } from '../../common/types';
import { useApiNoResponse } from '../../common/api';

export default function ChangePassword() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const [queryParameters] = useSearchParams();

  const email = queryParameters.get('email') ?? '';
  const code = queryParameters.get('code') ?? '';

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [passwordErrorText, setPasswordErrorText] = useState<string>('');
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState<string>('');

  const [changeApi, error, loading] = useApiNoResponse<UserPasswordResetChange>(
    '/auth/reset/change',
    'POST',
  );

  const navigate = useNavigate();

  const change = async () => {
    let formError = false;

    if (!password) {
      setPasswordErrorText('Password is required');
      formError = true;
    } else if (password.length < 12) {
      setPasswordErrorText('Password must be at least 12 characters');
      formError = true;
    } else if (password.toUpperCase() === password) {
      setPasswordErrorText('Password must contain at least one lowercase letter');
      formError = true;
    } else if (password.toLowerCase() === password) {
      setPasswordErrorText('Password must contain at least one uppercase letter');
      formError = true;
    } else if (password.search(/[0-9]/) < 0) {
      setPasswordErrorText('Password must contain at least one number');
      formError = true;
    } else if (password.search(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/) < 0) {
      setPasswordErrorText('Password must contain at least one special character');
      formError = true;
    } else {
      setPasswordErrorText('');
    }

    if (!confirmPassword) {
      setConfirmPasswordErrorText('Confirm password is required');
      formError = true;
    } else if (password !== confirmPassword) {
      setConfirmPasswordErrorText('Passwords do not match');
      formError = true;
    } else {
      setConfirmPasswordErrorText('');
    }

    if (formError) {
      return;
    }

    const info: UserPasswordResetChange = {
      email,
      code,
      password,
    };

    const response = await changeApi(info);

    if (!response.error) {
      navigate(`/login`);
    }
  };

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Reset', href: '/reset' },
            { text: 'Change Password', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Change Password</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  xs: 6,
                },
              },
            ]}
          >
            <form onSubmit={(event) => event.preventDefault()}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="link"
                      onClick={() => {
                        navigate(`/login`);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button disabled={loading} variant="primary" onClick={change}>
                      Change
                    </Button>
                  </SpaceBetween>
                }
                errorText={error}
              >
                <SpaceBetween size="l">
                  <Container header={<Header variant="h2">Change your Password</Header>}>
                    <SpaceBetween size="l">
                      <FormField
                        description="Enter a new password for your account. Your password must be at least 12 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                        errorText={passwordErrorText}
                        label="Password"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Enter new password..."
                          type="password"
                          value={password}
                          onChange={({ detail: { value } }) => setPassword(value)}
                        />
                      </FormField>
                      <FormField
                        description="Re-enter your new password to confirm."
                        errorText={confirmPasswordErrorText}
                        label="Confirm Password"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Re-enter new password..."
                          type="password"
                          value={confirmPassword}
                          onChange={({ detail: { value } }) => setConfirmPassword(value)}
                        />
                      </FormField>
                      {loading ? (
                        <StatusIndicator type="loading">Changing password...</StatusIndicator>
                      ) : (
                        <div />
                      )}
                    </SpaceBetween>
                  </Container>
                </SpaceBetween>
              </Form>
            </form>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
