import { Container, Header, TutorialPanel } from '@cloudscape-design/components';
import { useContext } from 'react';

import TutorialContext from '../common/tutorials';
import UserContext from '../common/user';

export function UserTutorials() {
  const { user } = useContext(UserContext);
  const { tutorials, loadingTutorials } = useContext(TutorialContext);

  return (
    <Container header={<Header variant="h2">Your Tutorials</Header>}>
      <TutorialPanel
        i18nStrings={{
          labelsTaskStatus: {
            pending: 'Pending',
            success: 'Completed',
            'in-progress': 'In progress',
          },
          loadingText: 'Loading',
          tutorialListTitle: 'Choose a tutorial',
          tutorialListDescription:
            'Use our walk-through tutorials to learn how to create and enjoy AI-powered escape rooms.',
          tutorialListDownloadLinkText: 'Download PDF version',
          tutorialCompletedText: 'Tutorial completed',
          labelExitTutorial: 'dismiss tutorial',
          learnMoreLinkText: 'Learn more',
          startTutorialButtonText: 'Start tutorial',
          restartTutorialButtonText: 'Restart tutorial',
          completionScreenTitle: 'Congratulations! You completed the tutorial.',
          feedbackLinkText: 'Feedback',
          dismissTutorialButtonText: 'Dismiss tutorial',
          taskTitle: (taskIndex, taskTitle) => `Task ${taskIndex + 1}: ${taskTitle}`,
          stepTitle: (stepIndex, stepTitle) => `Step ${stepIndex + 1}: ${stepTitle}`,
          labelTotalSteps: (totalStepCount) => `Total steps: ${totalStepCount}`,
          labelLearnMoreExternalIcon: 'Opens in a new tab',
          labelTutorialListDownloadLink: 'Download PDF version of this tutorial',
          labelLearnMoreLink: 'Learn more about AI Escape (opens new tab)',
        }}
        loading={loadingTutorials}
        tutorials={tutorials}
      />
    </Container>
  );
}
