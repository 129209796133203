import { Box, ColumnLayout, Header } from '@cloudscape-design/components';

import { RoomDisplay } from '../../rooms/display';
import FreeSubscriptionInfo from '../../subscription/free';
import PlusSubscriptionInfo from '../../subscription/plus';
import { JoinWaitlist } from '../../waitlist/join';
import { BlogPostProps } from '../blog';

export function BetaPost(): BlogPostProps {
  const exampleRooms = [
    '272c0a1d-d5d2-4ffd-b315-5cfec10061f7',
    '7ca694a3-d791-4a92-a590-9fefb96efe46',
    'f77a467e-a627-4cd5-a289-eb7319995ac3',
    '47c2418c-e1a1-4887-af97-8dc68fd9374b',
    'a6a8cc92-d134-42a5-bb06-8e13dc4da593',
    'd9d4199e-ba51-420c-ad9c-0e78e25de4d9',
    'd87c27fa-b0e3-494a-9cdc-9f2c7d1290f0',
  ];

  const post: BlogPostProps = {
    name: 'beta',
    title:
      'Announcing the Beta Launch of AI Escape: A Revolution in Escape Room Experiences Begins!',
    date: 'May 1st, 2023',
    user: 'Max Weinzierl',
    body: [
      {
        header: (
          <Header description="Ready, Set... Escape!" variant="h3">
            Beta Announced!
          </Header>
        ),
        content: [
          <Box variant="p">
            We are thrilled to announce the long-awaited Beta launch of AI Escape! After countless
            hours of development, experimentation, and fine-tuning, we are finally ready to share
            our groundbreaking creation with the world. As pioneers in the fusion of artificial
            intelligence and escape room entertainment, we can't wait for you to embark on this
            revolutionary journey with us.
          </Box>,
        ],
      },
      {
        header: (
          <Header description="Get hyped!" variant="h3">
            Beta Launch Features
          </Header>
        ),
        content: [
          <Box variant="p">
            AI Escape offers a truly unique and immersive experience, with features that set it
            apart from traditional escape rooms. In the Beta, you'll be able to enjoy the incredible
            power of AI-generated challenges, captivating storylines, and visually stunning images.
            You'll also be able to customize your escape room experience with various image styles,
            themes, and difficulty levels, ensuring a fresh and engaging adventure every time.
          </Box>,
          <Box variant="p"> </Box>,
          <RoomDisplay roomIds={exampleRooms} compact />,
        ],
      },
      {
        header: (
          <Header description="One at a time!" variant="h3">
            Beta Access
          </Header>
        ),
        content: [
          <Box variant="p">
            To ensure a smooth and enjoyable experience for all, we will be rolling out access to
            the Beta in daily batches. Users who sign up for our waitlist will be approved on a
            first-come, first-served basis, so be sure to secure your spot today!
          </Box>,
          <Box variant="p"> </Box>,
          <JoinWaitlist />,
        ],
      },
      {
        header: (
          <Header description="Subscriptions are here!" variant="h3">
            Free and Subscription Plans
          </Header>
        ),
        content: [
          <Box variant="p">
            We're also excited to announce that AI Escape will be available in two versions: a free
            version with a simpler AI and less impressive image generation, and a subscription-based
            version that unlocks the full potential of our state-of-the-art AI and breathtaking
            image generation capabilities.
          </Box>,
          <Box variant="p">
            The free version offers a fantastic way to explore the world of AI-generated escape
            rooms, while the subscription version provides an unparalleled experience with the most
            advanced AI and stunning visuals, taking your escape room adventures to new heights.
          </Box>,
          <Box variant="p"> </Box>,
          <ColumnLayout columns={2}>
            <FreeSubscriptionInfo />
            <PlusSubscriptionInfo />
          </ColumnLayout>,
        ],
      },
      {
        header: (
          <Header description="Stick around!" variant="h3">
            Going Forward
          </Header>
        ),
        content: [
          <Box variant="p">
            By launching the Beta of AI Escape, we are taking the first step in revolutionizing the
            escape room industry. Our innovative approach to combining cutting-edge AI technology
            with the timeless appeal of escape rooms promises to redefine the way you experience
            this beloved form of entertainment.
          </Box>,
          <Box variant="p">
            So, what are you waiting for? Be a part of the revolution and join the AI Escape Beta
            waitlist today! Stay tuned for more blog posts in this series, where we'll share
            exciting updates, behind-the-scenes insights, and more.
          </Box>,
          <Box variant="p">
            The clock is ticking, and the AI is waiting. Are you ready to experience the future of
            escape rooms? Join AI Escape and embark on the adventure of a lifetime!
          </Box>,
        ],
      },
    ],
  };

  return post;
}
