import { Box, Header } from '@cloudscape-design/components';

import { RoomDisplay } from '../../rooms/display';
import { BlogPostProps } from '../blog';

export function StylePost(): BlogPostProps {
  const styleRooms = [
    'bbdaf128-dce6-4fb0-a39a-69d4ae43fd08',
    'd42e3864-fcfb-4adc-b655-d01c2fbdeaf9',
    '7f815b98-ac27-4ca1-911d-f1b5233620ba',
    '03972ea4-8531-4705-9f87-6ff91f6bf6ca',
  ];

  const styleRooms2 = [
    'a5cd5e13-1181-4257-b592-28fbef47c082',
    '263e5f74-e9e1-4c87-8a54-589f3e3097f2',
    'e5d494a1-6ab9-4e2e-9bf8-0b94956cfd33',
    'afad3294-9bc5-4d9e-bd8b-c0936ba61934',
  ];

  const post: BlogPostProps = {
    name: 'style',
    title: 'Introducing "Image Style": A Game-Changer for AI Escape\'s Visual Experience',
    date: 'April 26th, 2023',
    user: 'Max Weinzierl',
    body: [
      {
        header: (
          <Header description="Oozing Style" variant="h3">
            AI Escape got Style!
          </Header>
        ),
        content: [
          <Box variant="p">
            Welcome back to our AI Escape blog series! In our previous post, we explored the
            integration of Stable Diffusion XL (SDXL), one of the most advanced text-to-image
            models, into our AI-generated escape rooms. Today, we're thrilled to announce a brand
            new feature that takes the visual experience of AI Escape to a whole new level – the
            "Image Style" feature!
          </Box>,
        ],
      },
      {
        header: (
          <Header description="Style for Days!" variant="h3">
            Visually Consistent Storytelling
          </Header>
        ),
        content: [
          <Box variant="p">
            The Image Style feature allows users to customize the look and feel of the images
            generated in their escape rooms by choosing from a variety of artistic styles such as
            "Photographic", "Fantasy Art", "Neon Punk", and many more. This groundbreaking addition
            not only elevates the visual appeal of our AI-generated escape rooms but also enhances
            the overall immersion and cohesion of the experience.
          </Box>,
          <Box variant="p">
            Imagine stepping into a room where every detail, from the textures on the walls to the
            clues hidden within the artwork, follows a specific theme that captivates your senses
            and transports you to another world. With the Image Style feature, players can now
            tailor their AI Escape experience to their personal preferences, ensuring a unique and
            unforgettable journey every time.
          </Box>,
          <Box variant="p"> </Box>,
          <RoomDisplay roomIds={styleRooms} compact />,
        ],
      },
      {
        header: (
          <Header description="How we did it" variant="h3">
            Stylized Escape Rooms
          </Header>
        ),
        content: [
          <Box variant="p">
            Our team at AI Escape has worked tirelessly to ensure that the Image Style feature works
            seamlessly with SDXL. The combination of SDXL's stunning visual capabilities and the
            Image Style feature's customizable options results in a beautiful synergy that truly
            brings the escape rooms to life. Whether you prefer the vibrant colors of Neon Punk, the
            enchanting allure of Fantasy Art, or the lifelike detail of Photographic, AI Escape has
            got you covered.
          </Box>,
          <Box variant="p">
            The addition of the Image Style feature to our AI-generated escape rooms showcases our
            commitment to providing players with a truly immersive and personalized experience. By
            offering a unifying theme across the escape room, we aim to create a cohesive and
            engaging atmosphere that keeps players captivated from start to finish.
          </Box>,
          <Box variant="p"> </Box>,
          <RoomDisplay roomIds={styleRooms2} compact />,
        ],
      },
      {
        header: (
          <Header description="And where we go from here" variant="h3">
            Looking Forward
          </Header>
        ),
        content: [
          <Box variant="p">
            We can't wait for you to explore the endless possibilities that the Image Style feature
            brings to AI Escape. Stay tuned for more blog posts in this series, where we'll continue
            to share exciting updates, behind-the-scenes insights, and more. Don't forget to join
            the AI Escape waitlist and be among the first to experience the magic of the Image Style
            feature in action.
          </Box>,
          <Box variant="p">
            The clock is ticking, and the AI is waiting. Are you ready to dive into a world of
            personalized visual wonders and escape like never before?
          </Box>,
        ],
      },
    ],
  };

  return post;
}
