import { useContext, useEffect, useState } from 'react';
import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  Link,
  Spinner,
  SpaceBetween,
  Alert,
  Button,
} from '@cloudscape-design/components';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../common/navigation';
import {
  SubscriptionPurchaseStatusRequest,
  SubscriptionPurchaseStatusResponse,
} from '../../common/types';
import { useApi, useRefreshApi } from '../../common/api';

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function SubscriptionSuccess() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const session_id = params.get('session_id');

  // TODO show error
  const [purchaseApi, purchaseError, purchaseLoading] = useApi<
    SubscriptionPurchaseStatusRequest,
    SubscriptionPurchaseStatusResponse
  >('/user/subscription/purchase', 'GET');

  // TODO show error
  const [refreshApi, refreshError, refreshLoading] = useRefreshApi();
  const [active, setActive] = useState<boolean>(false);

  const error = purchaseError || refreshError;

  // polling until subscription is active
  const purchase = async () => {
    if (!session_id) {
      return;
    }

    while (!active) {
      await sleep(1000);

      const result = await purchaseApi(undefined, undefined, {
        session_id,
      });

      if (result.error) {
        break;
      }

      if (result.data && result.data.active) {
        await refreshApi();
        setActive(true);
        break;
      }
    }
  };

  useEffect(() => {
    purchase();
  }, [session_id]);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Plans', href: '/subscription' },
            { text: 'Purchased', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Subscription Purchased</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 6,
                  s: 4,
                },
              },
            ]}
          >
            {active ? (
              <Container header={<Header variant="h2">Successfully Purchased Subscription</Header>}>
                <Box variant="p">You have purchased a subscription for AI Escape Plus+!</Box>
                <Box variant="p">
                  <Link
                    href="/rooms/create"
                    variant="secondary"
                    onFollow={(event) => {
                      event.preventDefault();
                      navigate('/rooms/create');
                    }}
                  >
                    Create
                  </Link>{' '}
                  a room to get started solving puzzles!
                </Box>
              </Container>
            ) : (
              <SpaceBetween size="l">
                <Container header={<Header variant="h2">Purchasing Subscription</Header>}>
                  <Box variant="p">Your subscription is being purchased. Please wait...</Box>
                  <Spinner />
                </Container>
                {error ? (
                  <Alert
                    action={
                      <Button
                        loading={purchaseLoading || refreshLoading}
                        variant="normal"
                        onClick={purchase}
                      >
                        Retry
                      </Button>
                    }
                    header="Subscription Status Error"
                    statusIconAriaLabel="Error"
                    type="error"
                  >
                    {error}
                  </Alert>
                ) : null}
              </SpaceBetween>
            )}
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
