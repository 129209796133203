import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  ColumnLayout,
  SpaceBetween,
  Grid,
  Button,
  Link,
  TutorialPanel,
} from '@cloudscape-design/components';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';
import UserContext from '../common/user';

import { SubscriptionItem } from './subscription';
import { UserTutorials } from './tutorials';

export default function Account() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const subscription = user?.subscription;

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Account', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Your Account</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  xs: 6,
                  m: 4,
                },
              },
              {
                colspan: {
                  default: 12,
                  xs: 6,
                  m: 4,
                },
              },
            ]}
          >
            <Container
              header={
                <Header
                  actions={<Button onClick={() => navigate('/account/update')}>Update</Button>}
                  variant="h2"
                >
                  Account Details
                </Header>
              }
            >
              <ColumnLayout columns={1} variant="text-grid">
                <div>
                  <Box variant="awsui-key-label">Email</Box>
                  <div>{user?.email}</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Given Name</Box>
                  <div>{user?.given_name}</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Family Name</Box>
                  <div>{user?.family_name}</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Birthdate</Box>
                  <div>
                    {user?.birthdate
                      ? new Date(user!.birthdate + 'T12:00:00.000Z').toLocaleDateString()
                      : null}
                  </div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Password</Box>
                  <div>************</div>
                </div>
                <div>
                  <Box variant="awsui-key-label">Email Preferences</Box>
                  <Link
                    onFollow={(e) => {
                      e.preventDefault();
                      navigate('/account/email');
                    }}
                  >
                    Manage Email Preferences
                  </Link>
                </div>
              </ColumnLayout>
            </Container>
            <SpaceBetween direction="vertical" size="m">
              <SubscriptionItem sub={subscription} />
              <UserTutorials />
            </SpaceBetween>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
