import { Header, AppLayout, ContentLayout, Grid } from '@cloudscape-design/components';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../../common/navigation';

import { RolePermissionsTable } from './table';

export default function ManageAuth() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const params = useParams();
  // Room UUID
  const roleId = params.roleId ?? '';

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Manage', href: '' },
            { text: 'Authorization', href: '/manage/auth' },
            { text: 'Roles', href: '' },
            { text: roleId, href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Manage {roleId} Permissions</Header>}>
          <Grid gridDefinition={[{ colspan: { default: 12, s: 8 } }]}>
            <RolePermissionsTable role={roleId} />
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
