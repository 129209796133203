import {
  Box,
  Container,
  Header,
  ColumnLayout,
  SpaceBetween,
  Spinner,
  Toggle,
  Hotspot,
} from '@cloudscape-design/components';
import { useContext, useEffect } from 'react';

import { useApiNoBody } from '../../../common/api';
import EscapeProgressBar from '../../../common/progress-bar';
import { RoomInfo } from '../../../common/room';
import { CreditsResponse } from '../../../common/types';
import UserContext from '../../../common/user';
import { CreditsLink } from '../../../credits/link';
import { SubscriptionLink } from '../../../subscription/link';
import { IMAGE_STYLES, TTS_VOICE_OPTIONS } from '../steps-config';
import { plusCredits } from '../../../common/settings';

export type CreateStepProps = {
  info: RoomInfo;
  error?: string;
  loading: boolean;
};

export default function CreateStep(props: CreateStepProps) {
  const info = props.info;
  const { user, credits, setCredits } = useContext(UserContext);
  const [creditsApi, creditsError, creditsLoading] = useApiNoBody<CreditsResponse>(
    '/user/credits',
    'GET',
    (data) => {
      setCredits(data.credits);
    },
  );

  useEffect(() => {
    creditsApi();
  }, [user]);

  return (
    <Container
      footer={
        <SpaceBetween direction="vertical" size="xxs">
          <Box variant="p">
            This room will cost 1 credit to create, and you have{' '}
            {creditsLoading || creditsError ? (
              <Spinner />
            ) : credits === 1 ? (
              '1 credit'
            ) : (
              `${credits.toLocaleString()} credits`
            )}{' '}
            remaining. Consider <CreditsLink />
            {user?.subscription ? null : (
              <Box variant="span">
                , or consider subscribing to <SubscriptionLink /> for {plusCredits} credits per
                month and much more!
              </Box>
            )}
          </Box>

          <Hotspot hotspotId="room-create" side="right">
            <EscapeProgressBar
              error={props.error}
              errorTitle="Error creating room"
              imageTitle="Creating image..."
              loading={props.loading}
              modelTitle="Creating room..."
              readyTitle="Ready to create room"
            />
          </Hotspot>
        </SpaceBetween>
      }
      header={<Header variant="h2">{info.basic.name}</Header>}
    >
      <Hotspot hotspotId="room-review" side="right">
        <ColumnLayout columns={2} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Theme</Box>
            <div>{info.basic.theme.trim() ? info.basic.theme.trim() : 'Default'}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Difficulty</Box>
            <div>
              {info.difficulty.difficulty[0].toUpperCase() + info.difficulty.difficulty.slice(1)}
            </div>
          </div>
          <div>
            <Box variant="awsui-key-label">Style</Box>
            <div>
              {info.image.image_style
                ? IMAGE_STYLES.filter((s) => s.value === info.image.image_style)[0]['label']
                : 'Default'}
            </div>
          </div>
          <div>
            <Box variant="awsui-key-label">Voice</Box>
            <div>
              {info.model.tts_voice
                ? TTS_VOICE_OPTIONS.filter((o) => o.value === info.model.tts_voice)[0]['label'] ??
                  'None'
                : 'None'}
            </div>
          </div>
          <div>
            <Box variant="awsui-key-label">Visibility</Box>
            <Toggle checked={info.basic.public} disabled>
              {info.basic.public ? 'Public' : 'Private'}
            </Toggle>
          </div>
        </ColumnLayout>
      </Hotspot>
    </Container>
  );
}
