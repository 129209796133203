import {
  Container,
  Box,
  Header,
  Button,
  SpaceBetween,
  ColumnLayout,
  Link,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { creditCost, freeCredits } from '../common/settings';

export type FreeSubscriptionInfoProps = {
  hideExamples?: boolean;
  moreInfo?: boolean;
};

export default function FreeSubscriptionInfo(props: FreeSubscriptionInfoProps) {
  const navigate = useNavigate();

  return (
    <Container
      header={
        <Header
          actions={
            props.moreInfo ? (
              <Button
                variant="link"
                onClick={() => {
                  navigate('/subscription');
                }}
              >
                More Info
              </Button>
            ) : undefined
          }
          variant="h2"
        >
          AI Escape Free
        </Header>
      }
    >
      <SpaceBetween size="s">
        <Box variant="p">
          AI Escape Free is the free version of AI Escape. It gives you access to a few rooms per
          month through free monthly credits, custom rooms, and many other features. Give AI Escape
          a try!
        </Box>
        <ColumnLayout columns={2} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Monthly Cost</Box>
            <div>Free!</div>
            {/* Add space here to match lines */}
            <br />
          </div>
          <div>
            <Box variant="awsui-key-label">Credits per Month</Box>
            <div>{freeCredits}*</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Image Customization</Box>
            <div>Standard</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Puzzle Difficulty</Box>
            <div>Easy, Medium</div>
          </div>
          {props.hideExamples ? undefined : (
            <div>
              <Box variant="awsui-key-label">Example Rooms</Box>
              <Button
                variant="link"
                onClick={() => {
                  navigate('/subscription/free');
                }}
              >
                See Examples
              </Button>
            </div>
          )}
        </ColumnLayout>
        <Box variant="small">
          * Free credits are refilled monthly, and do not carry over every month. You can{' '}
          <Link
            variant="info"
            onFollow={(event) => {
              event.preventDefault();
              navigate('/credits');
            }}
          >
            purchase more credits
          </Link>{' '}
          for {creditCost} per credit, up to a maximum of 30 credits, which will carry over every
          month.
        </Box>
      </SpaceBetween>
    </Container>
  );
}
