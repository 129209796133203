import {
  Container,
  SpaceBetween,
  Button,
  Alert,
  Box,
  Header,
  ColumnLayout,
  Toggle,
  Spinner,
  Link,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApiNoBody } from '../../../common/api';
import { UserListResponse } from '../../../common/types';

export type UserSubscription = {
  role: string;
  id?: string;
  created_at?: string;
  expires_at?: string;
  renew?: boolean;
};

export type UserDetailsProps = {
  user: UserListResponse;
};

export function UserSubInfo(props: UserDetailsProps) {
  const { user } = props;
  const navigate = useNavigate();
  const [sub, setSub] = useState<UserSubscription | undefined>();
  const [subApi, error, loading] = useApiNoBody<UserSubscription | undefined>(
    `/users/${user.id}/subscription`,
    'GET',
    (data) => setSub(data),
  );
  const fetchSub = async () => {
    if (loading) return;
    await subApi();
  };

  useEffect(() => {
    fetchSub();
  }, [user.id]);

  return (
    <SpaceBetween size="l">
      {loading ? (
        <Container header={<Header variant="h2">Subscription</Header>}>
          <Spinner />
        </Container>
      ) : sub ? (
        <Container header={<Header variant="h2">Subscription</Header>}>
          <ColumnLayout columns={1} variant="text-grid">
            <div>
              <Box variant="awsui-key-label">Plan</Box>
              <div>
                <Link
                  href="/subscription"
                  onFollow={(e) => {
                    e.preventDefault();
                    navigate('/subscription');
                  }}
                >
                  {sub.role === 'advanced' ? 'AI Escape Plus+' : 'unknown'}
                </Link>
              </div>
            </div>
            {sub.renew ? (
              <div>
                <Box variant="awsui-key-label">{sub.renew ? 'Renews at' : 'Expires at'}</Box>
                <div>{new Date(sub.expires_at + 'Z').toLocaleString()}</div>
              </div>
            ) : null}
            {sub.renew ? (
              <div>
                <Box variant="awsui-key-label">Renew</Box>
                <div>
                  <Toggle checked={sub.renew} disabled>
                    {sub.renew ? 'Will Renew' : 'Will Not Renew'}
                  </Toggle>
                </div>
              </div>
            ) : null}
          </ColumnLayout>
        </Container>
      ) : (
        <Container header={<Header variant="h2">Subscription</Header>}>
          <ColumnLayout columns={1} variant="text-grid">
            <div>
              <Box variant="awsui-key-label">Plan</Box>
              <div>
                <Link
                  href="/subscription"
                  onFollow={(e) => {
                    e.preventDefault();
                    navigate('/subscription');
                  }}
                >
                  AI Escape Free
                </Link>
              </div>
            </div>
          </ColumnLayout>
        </Container>
      )}
      {error ? (
        <Alert
          action={
            <Button
              loading={loading}
              variant="normal"
              onClick={() => {
                fetchSub();
              }}
            >
              Retry
            </Button>
          }
          header="Subscription Error"
          statusIconAriaLabel="Error"
          type="error"
        >
          {error}
        </Alert>
      ) : null}
    </SpaceBetween>
  );
}
