import {
  Container,
  Box,
  Header,
  SpaceBetween,
  Form,
  StatusIndicator,
  FormField,
  Input,
  Button,
  RadioGroup,
} from '@cloudscape-design/components';
import { useState } from 'react';

import { UserInvite } from '../../common/types';
import { useApiNoResponse } from '../../common/api';
import { ROLE_OPTIONS } from '../../common/roles';

export function InviteUser() {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  const [role, setRole] = useState<string>('basic');

  const [inviteApi, error, loading] = useApiNoResponse<UserInvite[]>('/invite', 'POST');

  const invite = async () => {
    if (loading) {
      return;
    }

    let formError = false;

    if (!email) {
      setEmailError('Email is required');
      formError = true;
    } else {
      setEmailError('');
    }

    if (formError) {
      return;
    }

    const info: UserInvite = {
      email,
      roles: [role],
    };
    const result = await inviteApi([info]);

    if (result.error) {
      setStatus('error');
    } else {
      setEmail('');
      setStatus('success');
    }
  };

  return (
    <Container header={<Header variant="h2">Invite a User</Header>}>
      <Box variant="p">This will send an email with a personalized link to join.</Box>
      <form onSubmit={(event) => event.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button loading={loading} variant="primary" onClick={invite}>
                Invite
              </Button>
            </SpaceBetween>
          }
          errorText={error}
        >
          <SpaceBetween size="l">
            <FormField errorText={emailError} label="Email">
              <Input
                disabled={loading}
                placeholder="Enter email..."
                type="email"
                value={email}
                onChange={({ detail: { value } }) => setEmail(value)}
              />
            </FormField>
            <FormField description="Select role for user." label="Role">
              <RadioGroup
                items={ROLE_OPTIONS}
                value={role}
                onChange={({ detail: { value } }) => setRole(value)}
              />
            </FormField>
            {status ? (
              status === 'error' ? (
                <StatusIndicator type="error">Error</StatusIndicator>
              ) : (
                <StatusIndicator type="success">User invited!</StatusIndicator>
              )
            ) : loading ? (
              <StatusIndicator type="loading">Inviting...</StatusIndicator>
            ) : (
              <div />
            )}
          </SpaceBetween>
        </Form>
      </form>
    </Container>
  );
}
