// metrics dashboard for admins
import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  Link,
  SpaceBetween,
  ColumnLayout,
} from '@cloudscape-design/components';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../common/navigation';

import { ActivityGraph } from './activity';
import { CostGraph } from './costs';
import { SpeedGraph } from './speed';
import { UsersGraph } from './users';

export default function MetricsDashboard() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const navigate = useNavigate();

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Manage', href: '' },
            { text: 'Metrics', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Metrics</Header>}>
          <Grid
            gridDefinition={[
              { colspan: { default: 12, s: 6 } },
              { colspan: { default: 12, s: 6 } },
            ]}
          >
            <SpaceBetween size="l">
              <CostGraph />
              <SpeedGraph />
            </SpaceBetween>
            <SpaceBetween size="l">
              <UsersGraph />
              <ActivityGraph />
            </SpaceBetween>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
