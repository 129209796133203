import { Box, Header } from '@cloudscape-design/components';

import { RoomDisplay } from '../../rooms/display';
import { BlogPostProps } from '../blog';

export function ImagePost(): BlogPostProps {
  const otherRooms = [
    'd87c27fa-b0e3-494a-9cdc-9f2c7d1290f0',
    '6a522d64-d848-471f-9d6c-1e74baddb658',
    '56366c8c-73c3-4618-a0c8-08473d7a43c5',
    'd14d523d-c0e6-4ae1-b96b-27aa911c0cdd',
  ];

  const sdxlRooms = [
    '272c0a1d-d5d2-4ffd-b315-5cfec10061f7',
    '7ca694a3-d791-4a92-a590-9fefb96efe46',
    'f77a467e-a627-4cd5-a289-eb7319995ac3',
    '47c2418c-e1a1-4887-af97-8dc68fd9374b',
    'a6a8cc92-d134-42a5-bb06-8e13dc4da593',
    'd9d4199e-ba51-420c-ad9c-0e78e25de4d9',
  ];

  const post: BlogPostProps = {
    name: 'image',
    title: 'AI Escape Meets Text-to-Image Generation: A Visual Revolution',
    date: 'April 20th, 2023',
    user: 'Max Weinzierl',
    body: [
      {
        header: (
          <Header description="Escape never looked so good" variant="h3">
            Introducing: Vivid Visuals
          </Header>
        ),
        content: [
          <Box variant="p">
            In our previous blog post, we introduced you to AI Escape, the groundbreaking concept
            that combines the thrill of escape rooms with the power of artificial intelligence.
            Today, we're taking you on a journey into the visual world of AI Escape, where
            captivating narratives and intricate puzzles come to life through AI-generated images.
          </Box>,
          <Box variant="p">
            As we embarked on the development of AI Escape, we knew our state-of-the-art
            AI-generated escape rooms would benefit immensely from vivid visuals to complement the
            engaging storylines and challenges. Our goal was to create an immersive experience that
            would transport players into the heart of the action, making them feel as if they were
            truly a part of the world we've crafted.
          </Box>,
        ],
      },
      {
        header: (
          <Header description="How we got good" variant="h3">
            The Journey
          </Header>
        ),
        content: [
          <Box variant="p">
            In our quest for the perfect text-to-image generator, we experimented with several
            cutting-edge models like DALLE-2 and Stable Diffusion. While both models produced
            impressive results, we were on the lookout for something even more spectacular to bring
            our AI-generated escape rooms to life.
          </Box>,
          <Box variant="p"> </Box>,
          <RoomDisplay roomIds={otherRooms} compact />,
          <Box variant="p"> </Box>,
          <Box variant="p">
            As luck would have it, Stable Diffusion XL (SDXL) was released just as AI Escape was
            taking shape. SDXL is one of the most advanced text-to-image models ever developed, and
            its impeccable ability to create stunning visuals from textual descriptions left us in
            awe. We knew we had found the perfect companion for our AI-generated escape rooms.
          </Box>,
        ],
      },
      {
        header: (
          <Header description="Like never before" variant="h3">
            Extra Large Escape
          </Header>
        ),
        content: [
          <Box variant="p">
            By integrating SDXL into AI Escape, we were able to achieve a seamless fusion of
            narrative, puzzles, and visuals. The AI-generated images not only enhance the overall
            experience but also provide valuable context and clues for players as they navigate the
            escape rooms.
          </Box>,
          <Box variant="p">
            Imagine walking into a room filled with vivid paintings, each one a clue waiting to be
            deciphered, or finding a hidden message within an intricate tapestry that holds the key
            to your escape. With SDXL, we can now create such mesmerizing scenes, adding a whole new
            dimension to the AI Escape experience.
          </Box>,
          <Box variant="p"> </Box>,
          <RoomDisplay roomIds={sdxlRooms} compact />,
        ],
      },
      {
        header: (
          <Header description="Future directions" variant="h3">
            Going Forward
          </Header>
        ),
        content: [
          <Box variant="p">
            As AI Escape continues to evolve, we're committed to pushing the boundaries of both
            narrative and visual storytelling. The fusion of AI-generated escape rooms with advanced
            text-to-image models like SDXL has unlocked an exciting realm of possibilities, and we
            can't wait to see where this journey takes us.
          </Box>,
          <Box variant="p">
            Stay tuned for more blog posts in this series, where we'll delve deeper into the
            fascinating world of AI Escape, share behind-the-scenes insights, updates, and more.
            Don't forget to join the AI Escape waitlist and be among the first to experience this
            visual revolution in escape room entertainment. The clock is ticking, and the AI is
            waiting. Are you ready to escape?
          </Box>,
        ],
      },
    ],
  };

  return post;
}
