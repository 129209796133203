import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  ColumnLayout,
  SpaceBetween,
  Link,
} from '@cloudscape-design/components';
import { useContext } from 'react';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';

import './about.css';

export default function About() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'About Us', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">About Us @ AI Escape</Header>}>
          <ColumnLayout columns={2}>
            <SpaceBetween size="xl">
              <Container header={<Header variant="h2">Who we are</Header>}>
                <Box variant="p">
                  Welcome to AI Escape, where cutting-edge artificial intelligence meets the thrill
                  of escape room adventures! We are a dedicated team of AI enthusiasts and escape
                  room fanatics, united by our passion for creating immersive, challenging, and
                  unique experiences tailored to every player.
                </Box>
                <Box variant="p">
                  Our mission is to revolutionize the escape room industry by leveraging the power
                  of AI to generate dynamic escape room experiences that adapt to each player's
                  skills, preferences, and choices. We believe that technology should not only make
                  our lives easier but also more enjoyable, and that's why we've brought AI Escape
                  to life.
                </Box>
              </Container>
              <Container header={<Header variant="h2">Our Team</Header>}>
                <Box variant="p">
                  At AI Escape, we pride ourselves on the exceptional quality and experience of our
                  team. Our diverse group of professionals brings together a wealth of knowledge in
                  artificial intelligence, game design, storytelling, and customer experience,
                  making us uniquely qualified to deliver the ultimate AI-driven escape room
                  adventure.
                </Box>
                <Box variant="p">Our team consists of:</Box>
                <ul>
                  <li>
                    <Box variant="p">
                      AI Experts: Our artificial intelligence specialists have extensive experience
                      in developing and implementing state-of-the-art AI algorithms and
                      technologies. With backgrounds in machine learning, natural language
                      processing, and computer vision, they ensure that our escape rooms are powered
                      by the most advanced AI solutions available.
                    </Box>
                  </li>
                  <li>
                    <Box variant="p">
                      Escape Room Hobbyists: Our hobbyists have years of experience with immersive
                      and challenging escape rooms. They collaborate closely with our AI experts to
                      seamlessly integrate AI-driven elements into the escape room experience,
                      crafting puzzles and challenges that push the boundaries of what's possible in
                      traditional escape rooms.
                    </Box>
                  </li>
                  <li>
                    <Box variant="p">
                      Customer Experience Champions: Our customer experience team is dedicated to
                      ensuring that every aspect of your AI Escape adventure is seamless and
                      enjoyable. From the moment you start your experience to the time you escape,
                      they are committed to providing top-notch customer service and support.
                    </Box>
                  </li>
                </ul>
                <Box variant="p">
                  By bringing together such a diverse and experienced team, AI Escape is uniquely
                  positioned to deliver an unparalleled escape room experience. We are committed to
                  pushing the boundaries of what's possible in the world of entertainment and are
                  constantly exploring new ways to enhance our AI-driven escape rooms.
                </Box>
                <Box variant="p">
                  At AI Escape, our team's collective expertise and passion fuel our innovative
                  spirit and drive our commitment to delivering unforgettable experiences for our
                  players. We invite you to join us on this exciting journey and discover the true
                  potential of AI-powered escape rooms.
                </Box>
              </Container>
            </SpaceBetween>
            <SpaceBetween size="xl">
              <Container>
                <Grid
                  gridDefinition={[
                    { colspan: { default: 12, xxs: 4 } },
                    { colspan: { default: 12, xxs: 8 } },
                  ]}
                >
                  <Link href="https://personal.utdallas.edu/~maxwell.weinzierl/" target="_blank">
                    <img
                      alt="Profile"
                      className="profile-picture"
                      src="https://cdn.aiescape.io/max.png"
                    />
                  </Link>

                  <div>
                    <Header
                      description="B.S, M.S, Ph.D (Candidate) in Computer Science"
                      variant="h3"
                    >
                      <Link
                        fontSize="heading-m"
                        href="https://personal.utdallas.edu/~maxwell.weinzierl/"
                        target="_blank"
                        variant="secondary"
                      >
                        Maxwell Weinzierl
                      </Link>{' '}
                      - Founder & CEO @ AI Escape LLC
                    </Header>
                    <Box variant="p">
                      Max is the founder and CEO of AI Escape. He has a background in Computer
                      Science and has been working in the field of AI for over 10 years. He is
                      currently pursuing a Ph.D. in Computer Science at the University of Texas at
                      Dallas, where his research focuses on Natural Language Processing on Social
                      Media for Public Health. Max is also a passionate escape room & puzzle game
                      enthusiast, and he is excited to bring his expertise in AI and escape rooms
                      together to create a unique and immersive experience for players.
                    </Box>
                  </div>
                </Grid>
              </Container>
              <Container header={<Header variant="h2">Our Services</Header>}>
                <Box variant="p">
                  We offer a variety of services for businesses that are interested in leveraging AI
                  technologies to enhance their operations and customer experiences. Check out our
                  offerings at{' '}
                  <Link href="https://products.aiescape.io/" target="_blank">
                    AI Escape Products
                  </Link>
                  .
                </Box>
                <Box variant="p">
                  We are also are open to discussing potential partnerships with brands/companies
                  that are interested in sponsoring custom AI Escape rooms and would like to explore
                  interactive marketing opportunities. Reach out to us at{' '}
                  <Link href="mailto:rooms@aiescape.io" target="_blank">
                    rooms@aiescape.io
                  </Link>{' '}
                  for any virtual escape room business inquiries.
                </Box>
              </Container>
            </SpaceBetween>
          </ColumnLayout>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
