import {
  Box,
  Button,
  ColumnLayout,
  Container,
  ExpandableSection,
  Popover,
  ProgressBar,
  SpaceBetween,
  StatusIndicator,
  Toggle,
} from '@cloudscape-design/components';
import { useState } from 'react';

import { useApiNoResponse } from '../../common/api';
import { IMAGE_STYLES, TTS_VOICE_OPTIONS } from '../create/steps-config';

import { EscapeRoomStatus, EscapeRoomUpdate, EscapeTurn } from './types';

export type RoomSettingsProps = {
  room: EscapeRoomStatus;
  messages: EscapeTurn[];
  setMessages: (messages: EscapeTurn[]) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setError: (error: string) => void;
  autoPlay: boolean;
  setAutoPlay: (autoPlay: boolean) => void;
  userRoom: boolean;
};

export function RoomSettings(props: RoomSettingsProps) {
  const { room, messages, loading, setLoading, setError, autoPlay, setAutoPlay, userRoom } = props;
  const [roomPublic, setRoomPublic] = useState(room.public);

  const usage = messages[messages.length - 1]?.token_usage ?? 0;
  const escaped = messages[messages.length - 1]?.escaped;
  const hints = messages.filter((m) => m.hint).length;

  const [updateApi] = useApiNoResponse<EscapeRoomUpdate>('/session', 'POST');

  const publicRequest = async (newRoomPublic: boolean) => {
    if (loading) return;
    setLoading(true);
    setError('');
    const oldRoomPublic = roomPublic;
    setRoomPublic(newRoomPublic);
    const result = await updateApi(
      {
        public: newRoomPublic,
      },
      `${room.id}`,
    );

    if (!result.error) {
      setError('');
    } else {
      setRoomPublic(oldRoomPublic);
      setError(result.error);
    }
    setLoading(false);
  };

  const autoPlayRequest = async (newAutoPlay: boolean) => {
    if (loading) return;
    setLoading(true);
    setError('');
    const oldAutoPlay = autoPlay;
    setAutoPlay(newAutoPlay);
    const result = await updateApi(
      {
        auto_play: newAutoPlay,
      },
      `${room.id}`,
    );

    if (!result.error) {
      setError('');
    } else {
      setAutoPlay(oldAutoPlay);
      setError(result.error);
    }
    setLoading(false);
  };

  return (
    <Container>
      <ColumnLayout columns={4} variant="text-grid">
        <div>
          <Box variant="awsui-key-label">Status</Box>
          <ProgressBar
            additionalInfo="Percent of max escape room size"
            description={
              <StatusIndicator type={escaped ? 'success' : 'in-progress'}>
                {escaped ? 'Escaped' : 'In-Progress'}
              </StatusIndicator>
            }
            value={100 * usage}
            variant="key-value"
          />
        </div>
        <div>
          <Box variant="awsui-key-label">Visibility</Box>
          <SpaceBetween direction="horizontal" size="xxs">
            <Toggle
              checked={roomPublic}
              disabled={loading || !userRoom}
              onChange={({ detail }) => publicRequest(detail.checked)}
            >
              {roomPublic ? 'Public' : 'Private'}
            </Toggle>
            {roomPublic ? (
              <Popover
                content={<StatusIndicator type="success">Copied room link</StatusIndicator>}
                dismissButton={false}
                position="top"
                size="small"
                triggerType="custom"
              >
                <Button
                  iconName="copy"
                  variant="inline-icon"
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                  }}
                />
              </Popover>
            ) : null}
          </SpaceBetween>
        </div>
        <div>
          <Box variant="awsui-key-label">Speech AutoPlay</Box>
          <Toggle
            checked={autoPlay}
            disabled={loading || !userRoom || !room.tts_model}
            onChange={({ detail }) => autoPlayRequest(detail.checked)}
          >
            {!room.tts_model ? 'No TTS Model' : autoPlay ? 'AutoPlay' : 'No AutoPlay'}
          </Toggle>
        </div>
        <div>
          <Box variant="awsui-key-label">Hints</Box>
          <div>{hints}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Theme</Box>
          <div>{room.theme ? room.theme : 'Default'}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Difficulty</Box>
          <div>{room.difficulty[0].toUpperCase() + room.difficulty.slice(1)}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Style</Box>
          <div>
            {room.image_style
              ? IMAGE_STYLES.filter((s) => s.value === room.image_style)[0]['label']
              : 'Default'}
          </div>
        </div>
        <div>
          <Box variant="awsui-key-label">Voice</Box>
          <div>
            {room.tts_model
              ? TTS_VOICE_OPTIONS.filter((o) => o.value === room.tts_voice)[0]?.label ?? 'Default'
              : 'None'}
          </div>
        </div>
      </ColumnLayout>
    </Container>
  );
}
