import { useContext, useState } from 'react';
import {
  Container,
  Box,
  Header,
  Form,
  SpaceBetween,
  Button,
  FormField,
  Input,
  Link,
  StatusIndicator,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { EmailRequest, SignupResponse, SignupStatus } from '../common/types';
import UserContext from '../common/user';
import { useApi } from '../common/api';

function statusToMessage(status: SignupStatus) {
  switch (status) {
    case 'approved':
      return 'You have already signed up! Go to the login page to login.';
    case 'pending':
      return 'An email has been sent to you. Please check your email to confirm your account!';
    case 'unknown':
    default:
      return null;
  }
}

function statusToStatusIndicator(status: SignupStatus) {
  switch (status) {
    case 'approved':
      return 'success';
    case 'pending':
      return 'info';
    default:
      return 'loading';
  }
}

export function JoinSignup() {
  const { user } = useContext(UserContext);

  const [status, setStatus] = useState<SignupStatus>(user ? 'approved' : 'unknown');
  const message = statusToMessage(status);
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [emailErrorText, setEmailErrorText] = useState<string>('');

  const [joinApi, error, loading] = useApi<EmailRequest, SignupResponse>(
    '/auth/signup/confirmation',
    'POST',
    (response) => setStatus(response.status),
  );

  const disabled = status !== 'unknown' || loading;

  const join = async () => {
    let formError = false;

    if (!email) {
      setEmailErrorText('Email is required');
      formError = true;
    } else {
      setEmailErrorText('');
    }

    if (formError) {
      return;
    }

    const info: EmailRequest = {
      email,
    };

    await joinApi(info);
  };

  return (
    <Container header={<Header variant="h2">Sign Up</Header>}>
      <form onSubmit={(event) => event.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                disabled={status !== 'unknown'}
                loading={loading}
                variant="primary"
                onClick={join}
              >
                Sign Up
              </Button>
            </SpaceBetween>
          }
          errorText={error}
        >
          <SpaceBetween size="l">
            <FormField errorText={emailErrorText} label="Email">
              <Input
                disabled={disabled}
                placeholder="Enter email..."
                type="email"
                value={email}
                onChange={({ detail: { value } }) => setEmail(value)}
              />
            </FormField>

            <Box variant="p">
              <Link
                href="/login"
                onFollow={(event) => {
                  event.preventDefault();
                  navigate('/login');
                }}
              >
                Have an account? Login here.
              </Link>
            </Box>
            {status !== 'unknown' ? (
              <StatusIndicator type={`${statusToStatusIndicator(status)}`}>
                {message}
              </StatusIndicator>
            ) : null}
          </SpaceBetween>
        </Form>
      </form>
    </Container>
  );
}
