import {
  Container,
  Header,
  FormField,
  RadioGroup,
  SpaceBetween,
  Select,
  Box,
  Hotspot,
} from '@cloudscape-design/components';
import { useContext } from 'react';

import { getPermissionsPrefix } from '../../../common/auth';
import { ImageModel, ImageSize, ImageStyle, RoomImageInfo } from '../../../common/room';
import UserContext from '../../../common/user';
import { SubscriptionLink } from '../../../subscription/link';
import { IMAGE_STYLES } from '../steps-config';

export type ImageModelInfoStepProps = {
  info: RoomImageInfo;
  onChange: (info: RoomImageInfo) => void;
  image_style: ImageStyle;
  setImageStyle: (image_style: ImageStyle) => void;
};

export default function ImageModelInfoStep(props: ImageModelInfoStepProps) {
  const info = props.info;
  const onChange = props.onChange;
  const { user } = useContext(UserContext);
  const customStyles = getPermissionsPrefix('image.style.', user).includes('custom');

  return (
    <Container header={<Header variant="h2">Escape Room Image AI</Header>}>
      <SpaceBetween size="l">
        <FormField
          description="Select the image style to use for your room. Image styles help guide the image AI to produce images of a desired style."
          errorText={
            customStyles || props.image_style === 'default'
              ? undefined
              : 'Upgrade to AI Escape Plus+ to use custom image styles'
          }
          label="Image Style"
        >
          <Hotspot hotspotId="room-image-style" side="right">
            <Select
              filteringType="auto"
              options={IMAGE_STYLES}
              selectedAriaLabel="Selected"
              selectedOption={IMAGE_STYLES.filter((s) => s.value === props.image_style)[0]}
              onChange={({ detail }) => {
                props.setImageStyle(detail.selectedOption.value as ImageStyle);

                if (customStyles) {
                  onChange({
                    ...info,
                    image_style: detail.selectedOption.value as ImageStyle,
                  });
                }
              }}
            />
          </Hotspot>
        </FormField>
        {user?.subscription ? null : (
          <Box variant="p">
            Consider subscribing to <SubscriptionLink /> for access to customizable image generating
            AI, image styles, and much more!
          </Box>
        )}
      </SpaceBetween>
    </Container>
  );
}
