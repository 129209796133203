import { useContext, useState } from 'react';
import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  Form,
  SpaceBetween,
  Button,
  FormField,
  Input,
  Grid,
  StatusIndicator,
  Textarea,
} from '@cloudscape-design/components';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';
import { UserFeedback } from '../common/types';
import { useApiNoResponse } from '../common/api';

export default function FeedbackPage() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const [title, setTitle] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [titleError, setTitleError] = useState<string>('');
  const [bodyError, setBodyError] = useState<string>('');
  const [sent, setSent] = useState<boolean>(false);

  const [feedbackApi, error, loading] = useApiNoResponse<UserFeedback>('/feedback', 'POST');

  const send = async () => {
    let formError = false;

    if (!title) {
      setTitleError('Feedback title is required');
      formError = true;
    } else {
      setTitleError('');
    }

    if (!body) {
      setBodyError('Feedback details are required');
      formError = true;
    } else {
      setBodyError('');
    }

    if (formError) {
      return;
    }

    const info: UserFeedback = {
      title,
      body,
    };

    const response = await feedbackApi(info);

    if (!response.error) {
      setSent(true);
    }
  };

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Feedback', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Feedback</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  s: 6,
                },
              },
              {
                colspan: {
                  default: 12,
                  s: 6,
                },
              },
            ]}
          >
            <Container header={<Header variant="h2">Share Your Thoughts with AI Escape</Header>}>
              <Box variant="p">
                At AI Escape, we deeply value the feedback of our community, as it helps us
                continually improve and evolve our AI-driven escape room experiences. Your insights
                and opinions matter to us, and we would be grateful if you could take a few moments
                to share your thoughts on our website and overall user experience.
              </Box>
              <Box variant="p">
                By providing your feedback, you play a crucial role in helping us enhance our
                platform and ensure that we continue to deliver the most immersive, dynamic, and
                enjoyable escape room adventures possible.
              </Box>
            </Container>
            <Container header={<Header variant="h2">Provide Feedback</Header>}>
              <form onSubmit={(event) => event.preventDefault()}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button disabled={sent} loading={loading} variant="primary" onClick={send}>
                        Send
                      </Button>
                    </SpaceBetween>
                  }
                  errorText={error}
                >
                  <SpaceBetween size="l">
                    <FormField errorText={titleError} label="Title">
                      <Input
                        disabled={sent}
                        placeholder="Enter title..."
                        value={title}
                        onChange={({ detail: { value } }) => setTitle(value)}
                      />
                    </FormField>
                    <FormField errorText={bodyError} label="Details">
                      <Textarea
                        disabled={sent}
                        placeholder="Enter details..."
                        value={body}
                        onChange={({ detail: { value } }) => setBody(value)}
                      />
                    </FormField>

                    {sent ? (
                      <StatusIndicator type="success">Feedback Received, Thanks!</StatusIndicator>
                    ) : (
                      <div />
                    )}
                  </SpaceBetween>
                </Form>
              </form>
            </Container>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
