import {
  Container,
  Header,
  Spinner,
  Button,
  StatusIndicator,
  Alert,
  SpaceBetween,
  Pagination,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { useApiNoBody } from '../common/api';

import { EscapeRoomStatus } from './room/types';
import { EscapeChatTurn } from './room/turn';

export type RoomDisplayProps = {
  roomIds: string[];
  compact?: boolean;
};

export function RoomDisplay(props: RoomDisplayProps) {
  const roomIds = props.roomIds;
  const [rooms, setRooms] = useState<(EscapeRoomStatus | null)[]>(
    Array.from(Array(roomIds.length).keys()).map(() => null),
  );
  const [room, setRoom] = useState<EscapeRoomStatus | null>(null);
  const navigate = useNavigate();
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [lastInteraction, setLastInteraction] = useState(new Date());
  const exitingElement = useRef<HTMLElement | null>(null);
  const enteringElement = useRef<HTMLElement | null>(null);
  const [lastEnter, setLastEnter] = useState<Date>();

  const [sessionApi, error, loading] = useApiNoBody<EscapeRoomStatus>(undefined, 'GET');

  const fetchRooms = async () => {
    let roomIdx = 0;
    const currentRooms: (EscapeRoomStatus | null)[] = Array.from(Array(roomIds.length).keys()).map(
      () => null,
    );

    for (const roomId of roomIds) {
      const result = await sessionApi(
        undefined,
        undefined,
        { message_limit: 1 },
        undefined,
        `/session/${roomId}/lookup`,
      );

      if (result.error) {
        return;
      } else if (result.data) {
        currentRooms[roomIdx] = result.data;
        setRooms(currentRooms);
        roomIdx++;
      }
    }
  };

  useEffect(() => {
    fetchRooms();
  }, [roomIds]);

  useEffect(() => {
    const nextInterval = setInterval(() => {
      setCurrentPageIndex((currentPageIndex) => {
        // otherwise, if we're not on the last page, go to the next page
        // otherwise, go to the first page
        if (currentPageIndex < roomIds.length) {
          return currentPageIndex + 1;
        }

        return 1;
      });
    }, 10 * 1200);

    return () => {
      clearInterval(nextInterval);
    };
  }, [lastInteraction]);

  useEffect(() => {
    setRoom(rooms[currentPageIndex - 1]);
  }, [currentPageIndex, rooms]);

  const pagination = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Pagination
        currentPageIndex={currentPageIndex}
        pagesCount={roomIds.length}
        onChange={({ detail }) => {
          setCurrentPageIndex(detail.currentPageIndex);
          setLastInteraction(new Date());
        }}
      />
    </div>
  );

  return (
    <SpaceBetween size="l">
      <Container
        header={
          room ? (
            <Header
              actions={
                <Button
                  variant="normal"
                  onClick={() => {
                    navigate(`/rooms/${room.uuid}`);
                  }}
                >
                  View
                </Button>
              }
              variant="h2"
            >
              {room.name}
            </Header>
          ) : undefined
        }
      >
        <div className="turn-container">
          {error ? (
            <StatusIndicator type="error" />
          ) : room ? (
            <TransitionGroup>
              <CSSTransition
                key={room.uuid}
                classNames="turn"
                timeout={2000}
                mountOnEnter
                unmountOnExit
                onEntered={(e: HTMLElement) => {
                  e.classList.remove('turn-enter-overlay');
                }}
                onEntering={(e: HTMLElement) => {
                  if (
                    lastEnter
                      ? new Date().getTime() - lastEnter.getTime() < 2000
                      : false && e !== enteringElement.current
                  ) {
                    enteringElement.current?.classList.add('turn-cancel');
                    exitingElement.current?.classList.add('turn-cancel');
                  } else {
                    e.classList.add('turn-enter-overlay');
                  }
                  setLastEnter(new Date());
                  enteringElement.current = e;
                }}
                onExiting={(e) => {
                  if (
                    lastEnter
                      ? new Date().getTime() - lastEnter.getTime() < 1000
                      : false && e !== exitingElement.current
                  ) {
                    e.classList.remove('turn-exit', 'turn-exit-active');
                  }
                  exitingElement.current = e;
                }}
              >
                <div className="turn">
                  <EscapeChatTurn
                    key={room.uuid}
                    compact={props.compact}
                    index={0}
                    message={room.messages[0]}
                    roomId={room.uuid}
                    insideContainer
                  />
                </div>
              </CSSTransition>
            </TransitionGroup>
          ) : (
            <Spinner size="large" />
          )}
        </div>
        {pagination}
      </Container>
      {error ? (
        <Alert
          action={
            <Button
              loading={loading}
              variant="normal"
              onClick={() => {
                fetchRooms();
              }}
            >
              Retry
            </Button>
          }
          header="Room Error"
          statusIconAriaLabel="Error"
          type="error"
        >
          {error}
        </Alert>
      ) : null}
    </SpaceBetween>
  );
}
