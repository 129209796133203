import {
  Alert,
  Box,
  Button,
  Form,
  Header,
  Modal,
  SpaceBetween,
  Spinner,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { ScrollPosition } from 'react-lazy-load-image-component';

import UserContext from '../../common/user';
import { useApi, useApiNoBody } from '../../common/api';
import { CreditsResponse } from '../../common/types';
import { RoomCopyRequest, RoomInfoResponse } from '../../common/room';
import { RoomCreated } from '../../feed/created';
import { SubscriptionLink } from '../../subscription/link';
import { plusCredits } from '../../common/settings';

import { EscapeRoomSessionEvent, EscapeRoomStatus } from './types';
import { RoomCost } from './cost';
import { RoomLink } from './link';

export type RoomActionsProps = {
  roomId?: string;
  event: EscapeRoomSessionEvent;
  scrollPosition?: ScrollPosition;
  setLastInteraction?: (timestamp: Date) => void;
  insideRoom?: boolean;
};

export function RoomActions(props: RoomActionsProps) {
  const navigate = useNavigate();
  const { user, setCredits } = useContext(UserContext);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [room, setRoom] = useState<EscapeRoomStatus>();

  const [lookupApi, lookupError, lookupLoading] = useApiNoBody<EscapeRoomStatus>(
    `/session/${props.roomId}/lookup`,
    'GET',
    (data) => {
      setRoom(data);
    },
  );

  const [creditsApi, creditsError, creditsLoading] = useApiNoBody<CreditsResponse>(
    '/user/credits',
    'GET',
    (data) => {
      setCredits(data.credits);
    },
  );

  const [copyApi, copyError, copyLoading] = useApi<RoomCopyRequest, RoomInfoResponse>(
    `/session/${props.roomId}/copy`,
    'POST',
    (response) => {
      setCredits(response.credits);
      navigate(`/rooms/${response.uuid}`);
      props.setLastInteraction?.(new Date());
      setModalOpen(false);
    },
  );

  const loading = creditsLoading || copyLoading;

  useEffect(() => {
    if (!user || !props.roomId || !modalOpen) {
      return;
    }
    lookupApi(undefined, undefined, { message_limit: 1 });
    creditsApi();
  }, [modalOpen]);

  const copyRoom = async () => {
    if (loading) {
      return;
    }

    if (!user) {
      const redirect = encodeURIComponent(window.location.pathname + window.location.search);
      navigate('/login?redirect=' + redirect);

      return;
    }

    if (!props.roomId) {
      return;
    }

    await copyApi();
  };

  if (!props.roomId) {
    return null;
  }

  return (
    <SpaceBetween direction="horizontal" size="s">
      {props.insideRoom ? null : (
        <Button
          variant="normal"
          onClick={() => {
            navigate(`/rooms/${props.roomId}`);
          }}
        >
          View
        </Button>
      )}
      <Button
        variant="primary"
        onClick={() => {
          if (!user) {
            const redirect = encodeURIComponent(window.location.pathname + window.location.search);
            navigate('/login?redirect=' + redirect);
          }
          setModalOpen(true);
          props.setLastInteraction?.(new Date(Date.UTC(9999, 12, 31)));
        }}
      >
        Play
      </Button>
      <Modal
        closeAriaLabel="Cancel"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => {
                  setModalOpen(false);
                  props.setLastInteraction?.(new Date());
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={creditsLoading || !!creditsError}
                loading={copyLoading}
                variant="primary"
                onClick={copyRoom}
              >
                Play
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header variant="h2">
            Play <RoomLink roomId={props.roomId} title={props.event.title} />
          </Header>
        }
        size="large"
        visible={modalOpen}
        onDismiss={() => {
          setModalOpen(false);
          props.setLastInteraction?.(new Date());
        }}
      >
        <form onSubmit={(event) => event.preventDefault()}>
          <Form errorText={copyError}>
            {lookupLoading ? (
              <Spinner size="large" />
            ) : (
              <SpaceBetween size="l">
                <RoomCreated
                  event={props.event}
                  scrollPosition={props.scrollPosition}
                  size="s"
                  hideUsers
                  insideContainer
                />
                <RoomCost action="play" error={creditsError} loading={creditsLoading} />
                {/* {!user?.subscription ? (
                  <Alert
                    action={
                      <Button
                        variant="normal"
                        onClick={() => {
                          navigate('/subscription');
                        }}
                      >
                        Subscribe
                      </Button>
                    }
                    header="No Escape Plan"
                    type="warning"
                  >
                    <Box variant="p">
                      This escape room utilizes <SubscriptionLink /> features. You can still play
                      this room, but you will not be able to use the best AI, and the images will be
                      lower quality. Subscribe to <SubscriptionLink /> for improved AI, better
                      images, {plusCredits} credits per month, and much more!
                    </Box>
                  </Alert>
                ) : null} */}
                <Alert
                  action={
                    <Button
                      variant="normal"
                      onClick={() => {
                        navigate('/rooms/create');
                      }}
                    >
                      Create
                    </Button>
                  }
                  header="Create Your Own"
                  type="info"
                >
                  <Box variant="p">
                    Want to create your own escape room? Create your own custom escape room for
                    free!
                  </Box>
                </Alert>
              </SpaceBetween>
            )}
          </Form>
        </form>
      </Modal>
    </SpaceBetween>
  );
}
