import { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import jwt from 'jwt-decode';
import { Mode, applyMode } from '@cloudscape-design/global-styles';

import { LocalTopNavigation, NavigationContext } from './common/navigation';
import { TutorialWithDetails, UserTokenInfo } from './common/types';
import UserContext from './common/user';
import Homepage from './home';
import PrivacyPolicy from './privacy';
import TermsOfService from './terms';
import AboutUs from './about';
import Rooms from './rooms';
import CreateRoom from './rooms/create';
import Room from './rooms/room';
import Login from './login';
import Signup from './signup';
import SignupConfirmation from './signup/confirmation';
import Logout from './logout';
import Invite from './manage/invite';
import WaitlistManage from './manage/waitlist';
import UsersManage from './manage/users';
import Error from './error';
import Reset from './reset';
import ResetChange from './reset/change';
import FeedbackPage from './feedback';
import AccountPage from './account';
import UpdateAccountPage from './account/update';
import EmailAccountPage from './account/email';
import CreditsPage from './credits';
import CreditsSuccessPage from './credits/success';
import SubscriptionPage from './subscription';
import SubscriptionSuccessPage from './subscription/success';
import FreeExamples from './subscription/free/index';
import PlusExamples from './subscription/plus/index';
import { RequireAuth, RequireNoAuth, RequirePermissions } from './common/auth';
import UserManage from './manage/users/user';
import BlogHome from './blog';
import IntroBlog from './blog/intro';
import ImageBlog from './blog/image';
import StyleBlog from './blog/style';
import BetaBlog from './blog/beta';
import EventsBlog from './blog/events';
import PublicBlog from './blog/public';
import Feed from './feed';
import ProfilePage from './profile';
import MetricsDashboard from './manage/metrics';
import AuthDashboard from './manage/auth';
import Waitlist from './waitlist';
import RoleManage from './manage/auth/role';
import ManageFeedback from './manage/feedback';
import ManageFeedbackForms from './manage/feedback/direct';
import ManageFeedbackRooms from './manage/feedback/rooms';

import '@cloudscape-design/global-styles/index.css';
import './global.css';

import TutorialContext from './common/tutorials';

import { TutorialPanelProps } from '@cloudscape-design/components';

import { TutorialAnnotationContext } from './common/annotation';

function App() {
  const { innerWidth: width } = window;
  const [navigationOpen, setNavigationOpen] = useState(width > 688);
  const navigationSize = 200;
  const { pathname, hash, key, search } = useLocation();
  const token = localStorage.getItem('token');
  let jwtToken: UserTokenInfo | null = null;

  try {
    if (token) {
      const jwtRaw = jwt(token);
      // jwtToken = token ? ((jwt(token) instanceof UserTokenInfo) ? jwt(token) as UserTokenInfo : null) : null;
      jwtToken = jwtRaw as UserTokenInfo;

      if (jwtToken) {
        if (jwtToken.type !== 'access') {
          localStorage.removeItem('token');
          jwtToken = null;
        }
      }
    }
  } catch (e) {
    console.error(e);
  }

  if (!jwtToken) {
    localStorage.removeItem('token');
  }

  const [user, setUser] = useState<UserTokenInfo | null>(jwtToken);
  const [credits, setCredits] = useState(0);

  const [currentTutorial, setCurrentTutorial] = useState<TutorialWithDetails | null>(null);
  const [tutorials, setTutorials] = useState<ReadonlyArray<TutorialWithDetails>>([]);
  const [loadingTutorials, setLoadingTutorials] = useState(true);

  useEffect(() => {
    applyMode(Mode.Dark);
  }, []);

  useEffect(() => {
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    } else if (!search) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <UserContext.Provider value={{ user, setUser, credits, setCredits }}>
      <TutorialContext.Provider
        value={{
          currentTutorial,
          setCurrentTutorial,
          tutorials,
          setTutorials,
          loadingTutorials,
          setLoadingTutorials,
        }}
      >
        <TutorialAnnotationContext>
          <LocalTopNavigation />
          <NavigationContext.Provider value={{ navigationOpen, setNavigationOpen, navigationSize }}>
            <Routes>
              <Route element={<Error />} path="*" />
              <Route element={<Homepage />} path="/" />
              <Route path="/blog">
                <Route element={<BlogHome />} index />
                <Route element={<IntroBlog />} path="intro" />
                <Route element={<ImageBlog />} path="image" />
                <Route element={<StyleBlog />} path="style" />
                <Route element={<BetaBlog />} path="beta" />
                <Route element={<PublicBlog />} path="open" />
                <Route element={<EventsBlog />} path="events" />
              </Route>
              <Route path="/feed">
                <Route element={<Feed />} index />
              </Route>
              <Route path="/about">
                <Route element={<AboutUs />} index />
              </Route>
              <Route path="/privacy">
                <Route element={<PrivacyPolicy />} index />
              </Route>
              <Route path="/terms">
                <Route element={<TermsOfService />} index />
              </Route>
              <Route element={<ProfilePage />} path="/profile/:userId" />
              <Route path="/account">
                <Route
                  element={
                    <RequireAuth>
                      <AccountPage />
                    </RequireAuth>
                  }
                  index
                />
                <Route
                  element={
                    <RequireAuth>
                      <UpdateAccountPage />
                    </RequireAuth>
                  }
                  path="update"
                />
                <Route
                  element={
                    <RequireAuth>
                      <EmailAccountPage />
                    </RequireAuth>
                  }
                  path="email"
                />
              </Route>
              <Route path="/credits">
                <Route
                  element={
                    <RequireAuth>
                      <CreditsPage />
                    </RequireAuth>
                  }
                  index
                />
                <Route
                  element={
                    <RequireAuth>
                      <CreditsSuccessPage />
                    </RequireAuth>
                  }
                  path="success"
                />
              </Route>
              <Route path="/subscription">
                <Route element={<SubscriptionPage />} index />
                <Route element={<FreeExamples />} path="free" />
                <Route element={<PlusExamples />} path="plus" />
                <Route
                  element={
                    <RequireAuth>
                      <SubscriptionSuccessPage />
                    </RequireAuth>
                  }
                  path="success"
                />
              </Route>
              <Route path="/rooms">
                <Route
                  element={
                    <RequireAuth>
                      <Rooms />
                    </RequireAuth>
                  }
                  index
                />
                <Route
                  element={
                    <RequireAuth>
                      <CreateRoom />
                    </RequireAuth>
                  }
                  path="create"
                />
                <Route element={<Room />} path=":roomId" />
              </Route>
              <Route
                element={
                  <RequireNoAuth>
                    <Login />
                  </RequireNoAuth>
                }
                path="/login"
              />
              <Route path="/signup">
                <Route
                  element={
                    <RequireNoAuth>
                      <Signup />
                    </RequireNoAuth>
                  }
                  index
                />
                <Route
                  element={
                    <RequireNoAuth>
                      <SignupConfirmation />
                    </RequireNoAuth>
                  }
                  path="confirmation"
                />
              </Route>
              <Route element={<Logout />} path="/logout" />
              <Route path="/reset">
                <Route element={<Reset />} index />
                <Route element={<ResetChange />} path="change" />
              </Route>
              <Route element={<Waitlist />} path="/waitlist" />
              <Route
                element={
                  <RequireAuth>
                    <FeedbackPage />
                  </RequireAuth>
                }
                path="/feedback"
              />
              <Route path="manage">
                <Route
                  element={
                    <RequirePermissions
                      permissions={['invites.create', 'invites.get', 'invites.delete']}
                    >
                      <Invite />
                    </RequirePermissions>
                  }
                  path="invites"
                />
                <Route
                  element={
                    <RequirePermissions
                      permissions={['waitlist.manage.get', 'waitlist.manage.update']}
                    >
                      <WaitlistManage />
                    </RequirePermissions>
                  }
                  path="waitlist"
                />
                <Route path="users">
                  <Route
                    element={
                      <RequirePermissions permissions={['users.get', 'users.roles.get']}>
                        <UsersManage />
                      </RequirePermissions>
                    }
                    index
                  />
                  <Route
                    element={
                      <RequirePermissions
                        permissions={[
                          'users.get',
                          'users.roles.add',
                          'users.roles.get',
                          'users.roles.delete',
                        ]}
                      >
                        <UserManage />
                      </RequirePermissions>
                    }
                    path=":userId"
                  />
                </Route>

                <Route path="feedback">
                  <Route
                    element={
                      // TODO add permissions
                      <RequirePermissions permissions={['users.get', 'users.rooms.get']}>
                        <ManageFeedback />
                      </RequirePermissions>
                    }
                    index
                  />
                  <Route
                    element={
                      <RequirePermissions permissions={['users.get', 'users.rooms.get']}>
                        <ManageFeedbackForms />
                      </RequirePermissions>
                    }
                    path="direct"
                  />
                  <Route
                    element={
                      <RequirePermissions permissions={['users.get', 'users.rooms.get']}>
                        <ManageFeedbackRooms />
                      </RequirePermissions>
                    }
                    path="rooms"
                  />
                </Route>
                <Route path="metrics">
                  <Route
                    element={
                      // TODO add permissions
                      <RequirePermissions permissions={['users.get', 'users.rooms.get']}>
                        <MetricsDashboard />
                      </RequirePermissions>
                    }
                    index
                  />
                  {/* TODO additional metric views here */}
                </Route>
                <Route path="auth">
                  <Route
                    element={
                      // TODO add permissions
                      <RequirePermissions permissions={['roles.create', 'permissions.create']}>
                        <AuthDashboard />
                      </RequirePermissions>
                    }
                    index
                  />
                  <Route
                    element={
                      <RequirePermissions permissions={['roles.create', 'permissions.create']}>
                        <RoleManage />
                      </RequirePermissions>
                    }
                    path="roles/:roleId"
                  />
                  {/* TODO additional auth views here */}
                </Route>
              </Route>
            </Routes>
          </NavigationContext.Provider>
        </TutorialAnnotationContext>
      </TutorialContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
