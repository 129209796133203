import { useContext, useState } from 'react';
import jwt from 'jwt-decode';

import { TokenRefreshResponse, UserTokenInfo } from './types';
import UserContext from './user';

// forces dev to use live api
const useLive = false;
export const apiUrl =
  (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && !useLive
    ? 'http://localhost:80'
    : 'https://rooms-api.aiescape.io';

export type ApiRequest<Tout> = {
  data?: Tout;
  error?: string;
};

export async function apiRequest<Tin, Tout>(
  route: string,
  body?: Tin,
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET',
  id?: string,
  params?: any,
  token?: string | null,
) {
  let response = null;

  try {
    const url =
      `${apiUrl}${route}` +
      (id ? `/${id}` : '') +
      (params ? `?${new URLSearchParams(params)}` : '');
    const authToken = token ? token : localStorage.getItem('token');
    response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: authToken ? `Bearer ${authToken}` : '',
      },
      body: body ? JSON.stringify(body) : undefined,
    });
  } catch (e) {
    console.error(e);

    return {
      error: 'Network error. If the error persists please try again later',
    } as ApiRequest<Tout>;
  }

  if (response.status !== 200) {
    try {
      const data = await response.json();

      console.error(data.detail);

      return {
        error: data.detail ? data.detail : data.error ? data.error : 'Unknown error',
      } as ApiRequest<Tout>;
    } catch (e) {
      console.error(e);

      return {
        error: response.statusText,
      } as ApiRequest<Tout>;
    }
  } else {
    const data = await response.json();

    return {
      data: data as Tout,
    } as ApiRequest<Tout>;
  }
}

export async function apiRequestDirectBody<Tout>(
  route: string,
  body?: Blob | null,
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET',
  id?: string,
  params?: any,
  token?: string | null,
) {
  let response = null;

  try {
    const url =
      `${apiUrl}${route}` +
      (id ? `/${id}` : '') +
      (params ? `?${new URLSearchParams(params)}` : '');
    const authToken = token ? token : localStorage.getItem('token');
    response = await fetch(url, {
      method,
      headers: {
        Authorization: authToken ? `Bearer ${authToken}` : '',
      },
      body,
    });
  } catch (e) {
    console.error(e);

    return {
      error: 'Network error. If the error persists please try again later',
    } as ApiRequest<Tout>;
  }

  if (response.status !== 200) {
    try {
      const data = await response.json();

      console.error(data.detail);

      return {
        error: data.detail ? data.detail : data.error ? data.error : 'Unknown error',
      } as ApiRequest<Tout>;
    } catch (e) {
      console.error(e);

      return {
        error: response.statusText,
      } as ApiRequest<Tout>;
    }
  } else {
    const data = await response.json();

    return {
      data: data as Tout,
    } as ApiRequest<Tout>;
  }
}

export type UseApiReturn<Tin, Tout> = [
  (
    body?: Tin,
    id?: string,
    params?: any,
    token?: string | null,
    altRoute?: string,
  ) => Promise<ApiRequest<Tout>>,
  string,
  boolean,
];

export function useApi<Tin, Tout>(
  route?: string,
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET',
  update?: (value: Tout) => void,
) {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshApi, refreshError, refreshLoading] = useRefreshApi();
  const { user, setUser } = useContext(UserContext);

  async function apiRequestWrapper(
    body?: Tin,
    id?: string,
    params?: any,
    token?: string | null,
    altRoute?: string,
  ) {
    setLoading(true);
    setError('');

    if (user && !token && user.exp < Date.now() / 1000) {
      const result = await refreshApi();

      if (result.error) {
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        setError(result.error);
        console.error(result.error);
        setLoading(false);

        return {
          error: result.error,
        } as ApiRequest<Tout>;
      } else if (result.data) {
        const newToken = result.data.token;
        localStorage.setItem('token', newToken);
        setUser(newToken ? (jwt(newToken) as UserTokenInfo) : null);
      }
    }

    if (!altRoute && !route) {
      throw new Error('No route provided');
    }

    const result = await apiRequest<Tin, Tout>(
      route ? route : altRoute!,
      body,
      method,
      id,
      params,
      token,
    );

    if (result.error) {
      setError(result.error);
      console.error(result.error);
    } else if (result.data) {
      if (update) {
        update(result.data);
      }
    }
    setLoading(false);

    return result;
  }

  return [apiRequestWrapper, error, loading] as UseApiReturn<Tin, Tout>;
}

export function useApiDirectBody<Tout>(
  route: string,
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET',
  update?: (value: Tout) => void,
) {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshApi, refreshError, refreshLoading] = useRefreshApi();
  const { user, setUser } = useContext(UserContext);

  async function apiRequestWrapper(
    body?: Blob | null,
    id?: string,
    params?: any,
    token?: string | null,
  ) {
    setLoading(true);
    setError('');

    if (user && !token && user.exp < Date.now() / 1000) {
      const result = await refreshApi();

      if (result.error) {
        setUser(null);
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        setError(result.error);
        console.error(result.error);
        setLoading(false);

        return {
          error: result.error,
        } as ApiRequest<Tout>;
      } else if (result.data) {
        const newToken = result.data.token;
        localStorage.setItem('token', newToken);
        setUser(newToken ? (jwt(newToken) as UserTokenInfo) : null);
      }
    }

    const result = await apiRequestDirectBody<Tout>(route, body, method, id, params, token);

    if (result.error) {
      setError(result.error);
      console.error(result.error);
    } else if (result.data) {
      if (update) {
        update(result.data);
      }
    }
    setLoading(false);

    return result;
  }

  return [apiRequestWrapper, error, loading] as UseApiReturn<Blob | null, Tout>;
}

export function useApiNoRefresh<Tin, Tout>(
  route: string,
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET',
  update?: (value: Tout) => void,
) {
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  async function apiRequestWrapper(body?: Tin, id?: string, params?: any, token?: string | null) {
    setLoading(true);
    setError('');
    const result = await apiRequest<Tin, Tout>(route, body, method, id, params, token);

    if (result.error) {
      setError(result.error);
      console.error(result.error);
    } else if (result.data) {
      if (update) {
        update(result.data);
      }
    }
    setLoading(false);

    return result;
  }

  return [apiRequestWrapper, error, loading] as UseApiReturn<Tin, Tout>;
}

export function useApiNoBody<Tout>(
  route?: string,
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET',
  update?: (value: Tout) => void,
) {
  return useApi<undefined, Tout>(route, method, update);
}

export function useApiNoResponse<Tin>(route: string, method?: 'POST' | 'PUT' | 'DELETE' | 'GET') {
  return useApi<Tin, undefined>(route, method, undefined);
}

export function useApiNoBodyNoResponse(route: string, method?: 'POST' | 'PUT' | 'DELETE' | 'GET') {
  return useApi<undefined, undefined>(route, method, undefined);
}

export type UseRefreshApiReturn<Tout> = [() => Promise<ApiRequest<Tout>>, string, boolean];

export function useRefreshApi() {
  const { setUser } = useContext(UserContext);
  const [refreshApiBase, refreshError, refreshLoading] = useApiNoRefresh<
    undefined,
    TokenRefreshResponse
  >('/auth/refresh', 'POST', (data) => {
    const token = data.token;
    localStorage.setItem('token', token);
    setUser(token ? (jwt(token) as UserTokenInfo) : null);
  });

  const refreshApi = async () => {
    const result = await refreshApiBase(
      undefined,
      undefined,
      undefined,
      localStorage.getItem('refresh_token'),
    );

    return result;
  };

  return [refreshApi, refreshError, refreshLoading] as UseRefreshApiReturn<TokenRefreshResponse>;
}
