import {
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  SpaceBetween,
  Container,
  Button,
} from '@cloudscape-design/components';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';

export type BlogPostContent = {
  header?: React.ReactNode;
  content: React.ReactNode[];
};

export type BlogPostProps = {
  name: string;
  title: string;
  date: string;
  user: string;
  body: BlogPostContent[];
};

export function BlogPost(props: BlogPostProps) {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Blog', href: '/blog' },
            { text: props.name, href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout
          header={
            <Header
              description={
                <SpaceBetween direction="horizontal" size="xs">
                  {props.date}|{props.user}
                </SpaceBetween>
              }
              variant="h1"
            >
              {props.title}
            </Header>
          }
        >
          <Grid gridDefinition={[{ colspan: { default: 12, m: 8 } }]}>
            <SpaceBetween size="l">
              {props.body.map((element, index) => (
                <Container key={`article-${props.name}-section-${index}`} header={element.header}>
                  {element.content}
                </Container>
              ))}
            </SpaceBetween>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}

export function BlogThumbnail(props: BlogPostProps) {
  const content = props.body[0].content[0];
  const navigate = useNavigate();

  // TODO add image thumbnail
  return (
    <Container
      header={
        <Header
          description={
            <SpaceBetween direction="horizontal" size="xs">
              {props.date}|{props.user}
            </SpaceBetween>
          }
          variant="h3"
        >
          {props.title}
        </Header>
      }
    >
      {content}
      <Button
        variant="link"
        onClick={(e) => {
          e.preventDefault();
          navigate(`/blog/${props.name}`);
        }}
      >
        Read More
      </Button>
    </Container>
  );
}
