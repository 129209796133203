import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  Link,
  SpaceBetween,
} from '@cloudscape-design/components';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';
import Feed from '../feed/feed';

export default function Home() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const navigate = useNavigate();

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Home', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout
          header={
            <Grid
              gridDefinition={[
                { colspan: { default: 4, xxs: 3, xs: 2, m: 1 } },
                { colspan: { default: 8, xxs: 9, xs: 10, m: 11 } },
              ]}
            >
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <img
                  alt="AI Escape Logo"
                  height="auto"
                  src="https://cdn.aiescape.io/logo.svg"
                  style={{
                    maxHeight: '80px',
                    maxWidth: '80px',
                    borderRadius: '0px',
                    borderWidth: '0px',
                    borderColor: 'unset',
                  }}
                  width="100%"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Header variant="h1">
                  <span
                    style={{
                      fontSize: '32px',
                    }}
                  >
                    AI Escape - Your Ultimate Escape Room Adventure!
                  </span>
                </Header>
              </div>
            </Grid>
          }
        >
          <Grid
            gridDefinition={[
              { colspan: { default: 12, s: 7 } },
              { colspan: { default: 12, s: 5 } },
            ]}
          >
            <Feed
              eventLimit={5}
              eventType="created"
              userId="2"
              disableScroll
              hideUsers
              horizontal
            />
            <SpaceBetween size="l">
              <Container header={<Header variant="h3">Welcome to AI Escape</Header>}>
                <Box variant="p">
                  Are you ready to put your problem-solving skills to the test? Do you have what it
                  takes to outsmart the most advanced AI? Welcome to AI Escape, the one-stop
                  destination for an exhilarating and mind-boggling experience that challenges your
                  wits like never before.
                </Box>
                <Box variant="p">
                  At AI Escape, we have created a unique and immersive world where you can escape
                  from AI-generated escape rooms. These rooms are designed to push your mental
                  boundaries and engage you in a thrilling battle of wits against our
                  state-of-the-art artificial intelligence.
                </Box>
                <Box variant="p">
                  <Link
                    href="/signup"
                    variant="secondary"
                    onFollow={(event) => {
                      event.preventDefault();
                      navigate('/signup');
                    }}
                  >
                    Sign up now
                  </Link>{' '}
                  and get ready to begin your ultimate escape room adventure! Check out the{' '}
                  <Link
                    href="/blog"
                    variant="secondary"
                    onFollow={(event) => {
                      event.preventDefault();
                      navigate('/blog');
                    }}
                  >
                    Blog
                  </Link>{' '}
                  for updates!
                </Box>
                <Box variant="p">
                  Our AI Escape Rooms are only the beginning! We offer a variety of services for
                  businesses that are interested in leveraging AI technologies to enhance their
                  operations and customer experiences. Check out all our offerings at{' '}
                  <Link href="https://products.aiescape.io/" target="_blank">
                    AI Escape Products
                  </Link>
                  .
                </Box>
              </Container>
              <Container header={<Header variant="h3">Why AI Escape?</Header>}>
                <Box variant="p">
                  In a world where AI is revolutionizing the way we live, work, and play, AI Escape
                  stands out as an innovative concept that combines cutting-edge technology with the
                  timeless allure of escape rooms. Our AI-generated escape rooms offer:
                </Box>
                <ol>
                  <li>
                    <Box variant="p">
                      Endless Possibilities: Each escape room is dynamically generated by our AI,
                      ensuring a fresh and exciting experience every time you play.
                    </Box>
                  </li>
                  <li>
                    <Box variant="p">
                      Customized Challenges: Whether you're a seasoned escape room enthusiast or a
                      curious newbie, our AI caters to all skill levels by adjusting the difficulty
                      of the challenges based on your preferences.
                    </Box>
                  </li>
                  <li>
                    <Box variant="p">
                      Immersive Storylines: Engage in captivating narratives that keep you on the
                      edge of your seat, as you unravel mysteries and decode cryptic messages to
                      escape the room.
                    </Box>
                  </li>
                  <li>
                    <Box variant="p">
                      Global Community: Connect with other players around the world, share your
                      experiences, and compete on our global leaderboards for ultimate bragging
                      rights.
                    </Box>
                  </li>
                </ol>
              </Container>
            </SpaceBetween>
          </Grid>
        </ContentLayout>
      }
      contentType="dashboard"
      headerSelector=".top-navigation"
      maxContentWidth={Number.MAX_VALUE}
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
