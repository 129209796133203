import { useEffect, useState, useRef } from 'react';
import {
  SpaceBetween,
  Spinner,
  Alert,
  Button,
  Container,
  Header,
  StatusIndicator,
  Pagination,
  Link,
} from '@cloudscape-design/components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import { ScrollPosition, trackWindowScroll } from 'react-lazy-load-image-component';

import { useApiNoBody } from '../common/api';
import { EscapeRoomEvent, EscapeRoomSessionEvent, EscapeRoomEventType } from '../rooms/room/types';
import { RoomActions } from '../rooms/room/actions';

import { RoomCreated } from './created';
import { RoomEscaped } from './escaped';

export type FeedProps = {
  userId?: string;
  eventType?: EscapeRoomEventType;
  hideUsers?: boolean;
  disableScroll?: boolean;
  eventLimit?: number;
  horizontal?: boolean;
  scrollPosition: ScrollPosition;
};

export type EventProps = {
  event: EscapeRoomEvent;
  hideUsers?: boolean;
  insideContainer?: boolean;
  scrollPosition?: ScrollPosition;
};

export function Event(props: EventProps) {
  if (props.event.type === 'created') {
    return (
      <RoomCreated
        key={props.event.uuid}
        event={props.event as EscapeRoomSessionEvent}
        hideUsers={props.hideUsers}
        insideContainer={props.insideContainer}
        scrollPosition={props.scrollPosition}
      />
    );
  } else if (props.event.type === 'escaped') {
    return (
      <RoomEscaped
        key={props.event.uuid}
        event={props.event as EscapeRoomSessionEvent}
        hideUsers={props.hideUsers}
        insideContainer={props.insideContainer}
        scrollPosition={props.scrollPosition}
      />
    );
  } else if (props.event.type === 'blogged') {
    return <></>;
  }

  return <></>;
}
// TODO refactor this into two components, one for horizontal and one for vertical by creating a useFeed hook
export function Feed(props: FeedProps) {
  const [feed, setFeed] = useState<EscapeRoomEvent[]>([]);
  const [end, setEnd] = useState<Date>(new Date());
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number[]>([]);
  const [done, setDone] = useState<boolean>(false);
  const [currentEvent, setCurrentEvent] = useState<EscapeRoomEvent | null>(null);
  const navigate = useNavigate();

  const [feedApi, error] = useApiNoBody<EscapeRoomEvent[]>('/feed', 'GET');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [lastInteraction, setLastInteraction] = useState(new Date());
  const exitingElement = useRef<HTMLElement | null>(null);
  const enteringElement = useRef<HTMLElement | null>(null);
  const [lastEnter, setLastEnter] = useState<Date>();

  const fetchFeed = async () => {
    if (loading) {
      return;
    }

    if (done) {
      return;
    }

    if (pages.includes(page)) {
      return;
    }
    setLoading(true);
    const routeId = props.userId ? props.userId : undefined;
    const response = await feedApi(undefined, routeId, {
      end: end.toISOString(),
      page,
      type: props.eventType ?? 'all',
      limit: props.eventLimit ?? 10,
    });

    if (!response.error && response.data) {
      if (response.data.length === 0) {
        setDone(true);
      } else {
        setFeed([...feed, ...response.data]);
        setPage((page) => page + 1);
        setPages([...pages, page]);

        if (response.data.length < (props.eventLimit ?? 10)) {
          setDone(true);
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchFeed();
  }, []);

  useEffect(() => {
    if (done) return;

    if (props.disableScroll) return;

    const handleScroll = () => {
      const position = window.scrollY;
      const height = document.body.scrollHeight;
      const ratio = position / height;

      if (loading) return;

      if (error) return;

      if (done) return;

      if (ratio > 0.6) {
        fetchFeed();
      }
    };

    const timer = setInterval(() => {
      handleScroll();
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [end, page, pages, loading, error, done]);

  useEffect(() => {
    if (!props.horizontal) return;
    setCurrentEvent(feed[currentPageIndex - 1]);
  }, [currentPageIndex, feed]);

  useEffect(() => {
    if (!props.horizontal) return;
    const nextInterval = setInterval(() => {
      if (lastInteraction > new Date()) return;
      setCurrentPageIndex((currentPageIndex) => {
        // otherwise, if we're not on the last page, go to the next page
        // otherwise, go to the first page

        if (currentPageIndex < feed.length) {
          if (!loading && currentPageIndex + 1 === feed.length) {
            fetchFeed();
          }

          return currentPageIndex + 1;
        } else if (feed.length === 0) {
          return 1;
        } else if (done) {
          return 1;
        } else if (loading && currentPageIndex === feed.length) {
          return currentPageIndex;
        }

        return 1;
      });
    }, 10 * 1200);

    return () => {
      clearInterval(nextInterval);
    };
  }, [lastInteraction, feed]);

  if (props.horizontal) {
    const pagination = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Pagination
          currentPageIndex={currentPageIndex}
          openEnd={!done}
          pagesCount={feed.length}
          onChange={({ detail }) => {
            setCurrentPageIndex(detail.currentPageIndex);
            setLastInteraction(new Date());

            if (detail.currentPageIndex >= feed.length) {
              fetchFeed();
            }
          }}
        />
      </div>
    );

    return (
      <SpaceBetween size="l">
        <Container
          header={
            currentEvent ? (
              <Header
                actions={
                  <RoomActions
                    event={currentEvent as EscapeRoomSessionEvent}
                    roomId={currentEvent.session_uuid}
                    scrollPosition={props.scrollPosition}
                    setLastInteraction={setLastInteraction}
                  />
                }
                variant="h1"
              >
                <Link
                  fontSize="inherit"
                  href={`/rooms/${currentEvent.session_uuid}`}
                  variant="secondary"
                  onFollow={(e) => {
                    e.preventDefault();
                    navigate(`/rooms/${currentEvent.session_uuid}`);
                  }}
                >
                  {currentEvent.title}
                </Link>
              </Header>
            ) : undefined
          }
        >
          <div className="turn-container">
            {error ? (
              <StatusIndicator type="error" />
            ) : currentEvent ? (
              <TransitionGroup>
                <CSSTransition
                  key={currentEvent.uuid}
                  classNames="turn"
                  timeout={2000}
                  mountOnEnter
                  unmountOnExit
                  onEntered={(e: HTMLElement) => {
                    e.classList.remove('turn-enter-overlay');
                  }}
                  onEntering={(e: HTMLElement) => {
                    if (
                      lastEnter
                        ? new Date().getTime() - lastEnter.getTime() < 2000
                        : false && e !== enteringElement.current
                    ) {
                      enteringElement.current?.classList.add('turn-cancel');
                      exitingElement.current?.classList.add('turn-cancel');
                    } else {
                      e.classList.add('turn-enter-overlay');
                    }
                    setLastEnter(new Date());
                    enteringElement.current = e;
                  }}
                  onExiting={(e) => {
                    if (
                      lastEnter
                        ? new Date().getTime() - lastEnter.getTime() < 1000
                        : false && e !== exitingElement.current
                    ) {
                      e.classList.remove('turn-exit', 'turn-exit-active');
                    }
                    exitingElement.current = e;
                  }}
                >
                  <div className="turn">
                    <Event
                      event={currentEvent}
                      hideUsers={props.hideUsers}
                      scrollPosition={props.scrollPosition}
                      insideContainer
                    />
                  </div>
                </CSSTransition>
              </TransitionGroup>
            ) : loading ? (
              <Spinner size="large" />
            ) : feed.length === 0 ? (
              <Alert header="No Rooms" statusIconAriaLabel="No Rooms" type="info">
                No rooms to show!
              </Alert>
            ) : null}
          </div>
          {pagination}
        </Container>
      </SpaceBetween>
    );
  }

  return (
    <SpaceBetween size="l">
      {feed.map((event) => {
        return <Event key={event.uuid} event={event} hideUsers={props.hideUsers} />;
      })}
      {loading ? <Spinner size="large" /> : null}
      {error ? (
        <Alert
          action={
            <Button
              loading={loading}
              variant="normal"
              onClick={() => {
                fetchFeed();
              }}
            >
              Retry
            </Button>
          }
          header="Feed Error"
          statusIconAriaLabel="Error"
          type="error"
        >
          {error}
        </Alert>
      ) : null}
      {done ? (
        <Alert header="End of Feed" statusIconAriaLabel="End" type="info">
          No more to show!
        </Alert>
      ) : null}
    </SpaceBetween>
  );
}

export default trackWindowScroll(Feed);
