import { ImageModel, ImageSize, Model } from './room';

export type RoomRequestDurationEstimate = {
  model_duration: number;
  image_duration?: number;
  total_duration: number;
};

export function estimateRequestDuration() {
  // Temporary override
  const model_duration = 4;
  const image_duration = 4;

  return {
    model_duration,
    image_duration,
    total_duration: model_duration + (image_duration ? image_duration : 0),
  } as RoomRequestDurationEstimate;
}
