import { useContext } from 'react';
import {
  Container,
  Box,
  Header,
  Button,
  SpaceBetween,
  ColumnLayout,
  Link,
  Alert,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { SubscriptionPurchaseResponse } from '../common/types';
import UserContext from '../common/user';
import { useApiNoBody } from '../common/api';
import { creditCost, plusCost, plusCredits } from '../common/settings';

export type PlusSubscriptionInfoProps = {
  hideExamples?: boolean;
  moreInfo?: boolean;
};

export default function PlusSubscriptionInfo(props: PlusSubscriptionInfoProps) {
  const { user } = useContext(UserContext);
  const subscription = user?.subscription;
  const navigate = useNavigate();

  const [purchaseApi, purchaseError, purchaseLoading] = useApiNoBody<SubscriptionPurchaseResponse>(
    '/user/subscription/purchase',
    'POST',
    (data) => {
      window.location.replace(data.session_url);
    },
  );

  const purchase = async () => {
    if (!user) {
      navigate('/signup');

      return;
    }

    if (subscription) {
      return;
    }
    await purchaseApi();
  };

  return (
    <SpaceBetween size="s">
      <Container
        header={
          <Header
            actions={
              props.moreInfo ? (
                <Button
                  variant="link"
                  onClick={() => {
                    navigate('/subscription');
                  }}
                >
                  More Info
                </Button>
              ) : (
                <Button
                  disabled={!!subscription}
                  loading={purchaseLoading}
                  variant="primary"
                  onClick={purchase}
                >
                  {subscription ? 'Subscribed!' : 'Subscribe'}
                </Button>
              )
            }
            variant="h2"
          >
            AI Escape Plus+
          </Header>
        }
      >
        <SpaceBetween size="s">
          <Box variant="p">
            AI Escape Plus+ provides the premium AI Escape room experience. Includes double the
            rooms per month, high-quality image customization, text-to-speech, and many more
            features!
          </Box>
          <ColumnLayout columns={2} variant="text-grid">
            <div>
              <Box variant="awsui-key-label">Monthly Cost</Box>
              <Box color="text-status-info" fontWeight="heavy" variant="div">
                30 Day Free Trial
              </Box>
              <div>(then {plusCost})</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Credits per Month</Box>
              <div>{plusCredits}*</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Image Customization</Box>
              <div>Custom Styles</div>
            </div>
            <div>
              <Box variant="awsui-key-label">Puzzle Difficulty</Box>
              <div>Easy, Medium, Hard</div>
            </div>
            {props.hideExamples ? undefined : (
              <div>
                <Box variant="awsui-key-label">Example Rooms</Box>
                <Button
                  variant="link"
                  onClick={() => {
                    navigate('/subscription/plus');
                  }}
                >
                  See Examples
                </Button>
              </div>
            )}
          </ColumnLayout>
          <Box variant="small">
            * Plus+ credits are added monthly, upon subscription renewal, and carry over every month
            up to a maximum of 30 credits. You can{' '}
            <Link
              variant="info"
              onFollow={(event) => {
                event.preventDefault();
                navigate('/credits');
              }}
            >
              purchase more credits
            </Link>{' '}
            for {creditCost} per credit.
          </Box>
        </SpaceBetween>
      </Container>
      {purchaseError ? (
        <Alert header="Purchase Error" statusIconAriaLabel="Error" type="error">
          {purchaseError}
        </Alert>
      ) : (
        purchaseLoading
      )}
    </SpaceBetween>
  );
}
