import { useContext, useState } from 'react';
import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  Form,
  SpaceBetween,
  Button,
  FormField,
  Input,
  Grid,
  Link,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';
import { UserLogin, UserTokenInfo, UserResponse } from '../common/types';
import UserContext from '../common/user';
import { useApi } from '../common/api';

export default function Login() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const { setUser } = useContext(UserContext);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const [passwordErrorText, setPasswordErrorText] = useState<string>('');

  const navigate = useNavigate();

  const [loginApi, error, loading] = useApi<UserLogin, UserResponse>(
    '/auth/login',
    'POST',
    (response) => {
      const token = response.token;
      const refresh_token = response.refresh_token;
      localStorage.setItem('token', token);
      localStorage.setItem('refresh_token', refresh_token);
      setUser(token ? (jwt(token) as UserTokenInfo) : null);

      // get redirect from query string
      const params = new URLSearchParams(window.location.search);
      const redirect = params.get('redirect');
      // decode redirect from encodeURIComponent
      const decodedRedirect = redirect ? decodeURIComponent(redirect) : null;

      if (decodedRedirect) {
        navigate(decodedRedirect);
      } else {
        navigate(`/rooms`);
      }
    },
  );

  const login = async () => {
    let formError = false;

    if (!email) {
      setEmailErrorText('Email is required');
      formError = true;
    } else {
      setEmailErrorText('');
    }

    if (!password) {
      setPasswordErrorText('Password is required');
      formError = true;
    } else {
      setPasswordErrorText('');
    }

    if (formError) {
      return;
    }

    const info: UserLogin = {
      email,
      password,
    };
    await loginApi(info);
  };

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Login', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Login to AI Escape</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 6,
                  s: 4,
                },
              },
            ]}
          >
            <Container header={<Header variant="h2">Login to your Account</Header>}>
              <form onSubmit={(event) => event.preventDefault()}>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        disabled={loading}
                        loading={loading}
                        variant="primary"
                        onClick={login}
                      >
                        Login
                      </Button>
                    </SpaceBetween>
                  }
                  errorText={error}
                >
                  <SpaceBetween size="l">
                    <FormField errorText={emailErrorText} label="Email">
                      <Input
                        disabled={loading}
                        placeholder="Enter email..."
                        type="email"
                        value={email}
                        onChange={({ detail: { value } }) => setEmail(value)}
                      />
                    </FormField>

                    <FormField errorText={passwordErrorText} label="Password">
                      <Input
                        disabled={loading}
                        placeholder="Enter password..."
                        type="password"
                        value={password}
                        onChange={({ detail: { value } }) => setPassword(value)}
                      />
                    </FormField>
                    <SpaceBetween size="xxxs">
                      <Box variant="p">
                        <Link
                          href="/signup"
                          onFollow={(event) => {
                            event.preventDefault();
                            navigate('/signup');
                          }}
                        >
                          Don&apos;t have an account? Sign up here.
                        </Link>
                      </Box>
                      <Box variant="p">
                        <Link
                          href="/reset"
                          onFollow={(event) => {
                            event.preventDefault();
                            navigate('/reset');
                          }}
                        >
                          Forgot password?
                        </Link>
                      </Box>
                    </SpaceBetween>
                  </SpaceBetween>
                </Form>
              </form>
            </Container>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
