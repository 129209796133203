import { Box, Container, Header, MixedLineBarChart } from '@cloudscape-design/components';
import { useEffect, useMemo, useState } from 'react';

import { ImageModel, Model } from '../../common/room';
import { EscapeRoom } from '../../rooms/types';
import { useApiNoBody } from '../../common/api';
import { EscapeTurn } from '../../rooms/room/types';

import { getCompletionCost, getImageCost, getPromptCost } from './costs';

export function ActivityGraph() {
  const [messages, setMessages] = useState<EscapeTurnFromUser[]>([]);

  const [roomsApi, error, loading] = useApiNoBody<EscapeTurnFromUser[]>(
    '/session/messages',
    'GET',
    setMessages,
  );

  const fetchRooms = async () => {
    await roomsApi();
  };

  useEffect(() => {
    fetchRooms();
  }, []);

  const activity = useMemo(() => {
    const minDate = new Date(
      Math.min(...messages.map((message) => new Date(message.timestamp + 'Z').getTime())),
    );
    const minMonth = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
    const maxDate = new Date(
      Math.max(...messages.map((message) => new Date(message.timestamp + 'Z').getTime())),
    );
    const maxMonth = new Date(maxDate.getFullYear(), maxDate.getMonth(), 1);
    const months = [];

    for (let date = minMonth; date <= maxMonth; date.setMonth(date.getMonth() + 1)) {
      months.push(date.toISOString());
    }

    const counts = months.map((month) => {
      const users = new Set(
        messages
          .filter((message) => {
            const date = new Date(message.timestamp + 'Z');
            const monthDate = new Date(date.getFullYear(), date.getMonth(), 1);

            return monthDate.toISOString() === month;
          })
          .map((message) => message.user_id),
      );

      return {
        x: month,
        y: users.size,
      };
    });

    return counts;
  }, [messages]);

  return (
    <Container
      header={
        <Header
          // actions={
          //   <Button variant="normal" onClick={() => navigate('/manage/metrics')}>
          //     View Details
          //   </Button>
          // }
          description={<Box variant="span">Users who have interacted each month.</Box>}
          variant="h3"
        >
          Monthly Active Users
        </Header>
      }
    >
      <MixedLineBarChart
        empty={
          <Box color="inherit" textAlign="center">
            <b>No data available</b>
            <Box color="inherit" variant="p">
              There is no data available
            </Box>
          </Box>
        }
        height={200}
        i18nStrings={{
          xTickFormatter: (e) =>
            new Date(e)
              .toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
              })
              .split(',')
              .join('\n'),
          yTickFormatter: function o(e) {
            return Math.abs(e) >= 1e9
              ? (e / 1e9).toFixed(1).replace(/\.0$/, '') + 'G'
              : Math.abs(e) >= 1e6
              ? (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
              : Math.abs(e) >= 1e3
              ? (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
              : e.toFixed(0);
          },
        }}
        series={[
          {
            title: 'Active Users',
            type: 'bar',
            data: activity,
            valueFormatter: (e) => e.toLocaleString(),
          },
        ]}
        statusType={error ? 'error' : loading ? 'loading' : 'finished'}
        xScaleType="categorical"
        xTitle="Month"
        yTitle="Users"
        emphasizeBaselineAxis
        hideFilter
        hideLegend
        stackedBars
      />
    </Container>
  );
}

export function getCost(promptTokens: number, completionTokens: number) {
  return getPromptCost(promptTokens) + getCompletionCost(completionTokens);
}

export type RoomCost = {
  createdAt: Date;
  imageCost: number;
  promptCost: number;
  completionCost: number;
  totalCost: number;
};

export function getRoomCost(room: EscapeRoom) {
  const imageCost = getImageCost() * room.messages.filter((message) => message.image_url).length;
  const promptCost = room.messages.reduce(
    (acc, message) => acc + (message.prompt_tokens ? getPromptCost(message.prompt_tokens) : 0),
    0,
  );
  const completionCost = room.messages.reduce(
    (acc, message) =>
      acc + (message.completion_tokens ? getCompletionCost(message.completion_tokens) : 0),
    0,
  );
  const totalCost = imageCost + promptCost + completionCost;
  const cost: RoomCost = {
    createdAt: new Date(room.created_at + 'Z'),
    imageCost,
    promptCost,
    completionCost,
    totalCost,
  };

  return cost;
}

export type EscapeTurnFromUser = EscapeTurn & {
  user_id: string;
};
