import { ProgressBar } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

import { estimateRequestDuration } from './duration';
import { ImageModel, ImageSize, Model } from './room';

export type EscapeProgressBarProps = {
  error?: string;
  loading: boolean;
  readyTitle: string;
  errorTitle: string;
  imageTitle: string;
  modelTitle: string;
};

export default function EscapeProgressBar(props: EscapeProgressBarProps) {
  const loading = props.loading;

  const [progress, setProgress] = useState(0);
  const [progressDescription, setProgressDescription] = useState(props.readyTitle);
  const [elapsedTime, setElapsedTime] = useState(0);

  const estimatedDuration = estimateRequestDuration();

  const model_ratio = estimatedDuration.image_duration
    ? 100 * (estimatedDuration.model_duration / estimatedDuration.total_duration)
    : 100;
  // const image_ratio = estimatedDuration.image_duration ? 100 - model_ratio : undefined;

  useEffect(() => {
    if (loading) {
      setProgress(0);
      setElapsedTime(0);
      setProgressDescription(props.modelTitle);
      // every second, update progress
      const intervalId = setInterval(() => {
        if (loading && progress < 100) {
          setElapsedTime((t) => t + 1);
        } else {
          clearInterval(intervalId);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    } else if (!props.error) {
      setProgress(0);
      setProgressDescription(props.readyTitle);
    } else {
      setProgress(0);
      setProgressDescription(props.errorTitle);
    }
  }, [loading]);

  useEffect(() => {
    if (loading) {
      const newProgress = 100 * Math.min(elapsedTime / estimatedDuration.total_duration, 1);
      setProgress(newProgress);

      if (props.imageTitle && newProgress > model_ratio) {
        setProgressDescription(props.imageTitle);
      } else {
        setProgressDescription(props.modelTitle);
      }
    }
  }, [elapsedTime]);

  return (
    <ProgressBar
      description={progressDescription}
      resultText={props.error}
      status={props.error ? 'error' : 'in-progress'}
      value={progress}
    />
  );
}
