import { useContext, useEffect, useState } from 'react';
import {
  Box,
  AppLayout,
  Cards,
  Button,
  SpaceBetween,
  Pagination,
  CollectionPreferences,
  PropertyFilter,
} from '@cloudscape-design/components';
import { useLocalStorage } from 'react-use';
import Link from '@cloudscape-design/components/link';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage, ScrollPosition, trackWindowScroll } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../../common/navigation';
import { getTextFilterCounterText } from '../../../common/i18n-strings';
import { useApiNoBody } from '../../../common/api';
import { ProfileName } from '../../../profile/name';
import { parseCaption } from '../../../common/caption';

import { EscapeRoomFeedback } from './types';
import { FullPageHeader, getHeaderCounterText } from './full-header';

const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ['domainName', 'deliveryMethod', 'state'],
};

const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Rooms' },
  { value: 30, label: '30 Rooms' },
  { value: 50, label: '50 Rooms' },
];

export type RoomsProps = {
  scrollPosition: ScrollPosition;
};

export function Rooms(props: RoomsProps) {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const navigate = useNavigate();
  const pageSize = 12;
  const [preferences, setPreferences] = useLocalStorage(
    'React-Cards-Preferences',
    DEFAULT_PREFERENCES,
  );
  const [rooms, setRooms] = useState<EscapeRoomFeedback[]>([]);

  const [roomsApi, error, loading] = useApiNoBody<EscapeRoomFeedback[]>(
    '/session/feedback',
    'GET',
    setRooms,
  );

  const resourceName = 'Room';
  const fetchRooms = async () => {
    await roomsApi();
  };

  useEffect(() => {
    fetchRooms();
  }, []);

  const {
    items,
    actions,
    collectionProps,
    propertyFilterProps,
    paginationProps,
    filteredItemsCount,
  } = useCollection(rooms, {
    propertyFiltering: {
      filteringProperties: [
        {
          key: 'difficulty',
          // TODO enable >, <, <=, >=
          operators: ['=', '!='],
          propertyLabel: 'Difficulty',
          groupValuesLabel: 'Difficulty values',
        },
        // {
        //   key: 'status',
        //   operators: ['=', '!='],
        //   propertyLabel: 'Status',
        //   groupValuesLabel: 'Status values',
        // },
        // {
        //   key: "state",
        //   operators: ["=", "!=", ":", "!:"],
        //   propertyLabel: "State",
        //   groupValuesLabel: "State values"
        // },
        // {
        //   key: "instancetype",
        //   operators: ["=", "!=", ":", "!:"],
        //   propertyLabel: "Instance type",
        //   groupValuesLabel: "Instance type values"
        // },
        // {
        //   key: "averagelatency",
        //   operators: ["=", "!=", ">", "<", "<=", ">="],
        //   propertyLabel: "Average latency",
        //   groupValuesLabel: "Average latency values"
        // }
      ],
      // filteringFunction: filterRoom,
      empty: (
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {error ? (
            <SpaceBetween size="xxs">
              <div>
                <b>Error retrieving {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  {error}
                </Box>
              </div>
              <Button onClick={fetchRooms}>Retry</Button>
            </SpaceBetween>
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()}s found.
                </Box>
              </div>
              <Button
                onClick={() => {
                  navigate('/rooms/create');
                }}
              >
                Create {resourceName.toLowerCase()}
              </Button>
            </SpaceBetween>
          )}
        </Box>
      ),
      noMatch: (
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          <SpaceBetween size="xxs">
            <div>
              <b>No matches</b>
              <Box color="inherit" variant="p">
                We can&apos;t find a match.
              </Box>
            </div>
            <Button onClick={() => actions.setPropertyFiltering({ operation: 'and', tokens: [] })}>
              Clear filter
            </Button>
          </SpaceBetween>
        </Box>
      ),
    },
    pagination: { pageSize },
  });

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Manage', href: '' },
            { text: 'Feedback', href: '/manage/feedback' },
            { text: 'Rooms', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <Cards
          {...collectionProps}
          cardDefinition={{
            header: (item) => (
              <SpaceBetween direction="vertical" size="xs">
                <Link
                  fontSize="heading-m"
                  href={`/rooms/${item.uuid}`}
                  variant="secondary"
                  onFollow={(e) => {
                    e.preventDefault();
                    navigate(`/rooms/${item.uuid}`);
                  }}
                >
                  {item.name}
                </Link>
              </SpaceBetween>
            ),
            sections: [
              {
                content: (item) => {
                  const caption = parseCaption(item.messages[0].caption);

                  return (
                    <Link
                      href={`/rooms/${item.uuid}`}
                      variant="info"
                      onFollow={(e) => {
                        e.preventDefault();
                        navigate(`/rooms/${item.uuid}`);
                      }}
                    >
                      <LazyLoadImage
                        alt={caption}
                        effect="blur"
                        height="auto"
                        placeholder={
                          <img
                            alt={caption}
                            height="auto"
                            src="https://cdn.aiescape.io/loading.png"
                            width="100%"
                          />
                        }
                        scrollPosition={props.scrollPosition}
                        src={item.messages[0].image_url}
                        threshold={500}
                        width="100%"
                      />
                    </Link>
                  );
                },
                id: 'image',
              },
              {
                content: (item) => (
                  <Box variant="span">
                    <ProfileName user={item.user} hideLink manage /> at{' '}
                    {new Date(item.created_at + 'Z').toLocaleString()}
                  </Box>
                ),
                header: 'Author',
                id: 'user',
              },
              {
                content: (item) => <Box variant="span">{item.rating}/5</Box>,
                header: 'Rating',
                id: 'rating',
              },
              {
                content: (item) => (
                  <SpaceBetween direction="horizontal" size="xxxs">
                    <div className="room-item-first">
                      <Box variant="awsui-key-label">Steps</Box>
                      <div>{item.steps}</div>
                    </div>
                    <div className="room-item-info">
                      <Box variant="awsui-key-label">Difficulty</Box>
                      <div>{item.difficulty[0].toUpperCase() + item.difficulty.slice(1)}</div>
                    </div>
                    <div className="room-item-info">
                      <Box variant="awsui-key-label">Puzzles</Box>
                      <div>
                        {item.min_puzzles !== item.max_puzzles
                          ? `${item.min_puzzles}-${item.max_puzzles}`
                          : `${item.min_puzzles}`}
                      </div>
                    </div>
                  </SpaceBetween>
                ),
                id: 'info',
              },
              {
                content: (item) => item.theme || 'Default',
                header: 'Theme',
                id: 'theme',
              },
              {
                content: (item) => (
                  <SpaceBetween direction="vertical" size="xxxs">
                    {item.feedback?.split('\n').map((line, i) => (
                      <Box key={`line-${i}`} variant="p">
                        {line}
                      </Box>
                    ))}
                  </SpaceBetween>
                ),
                id: 'feedback',
              },
            ],
          }}
          filter={
            <PropertyFilter
              {...propertyFilterProps}
              countText={getTextFilterCounterText(filteredItemsCount || 0)}
              disabled={loading}
              filteringOptions={[
                {
                  propertyKey: 'difficulty',
                  value: 'Easy',
                },
                {
                  propertyKey: 'difficulty',
                  value: 'Medium',
                },
                {
                  propertyKey: 'difficulty',
                  value: 'Hard',
                },
                {
                  propertyKey: 'rating',
                  value: '1',
                },
                {
                  propertyKey: 'rating',
                  value: '2',
                },
                {
                  propertyKey: 'rating',
                  value: '3',
                },
                {
                  propertyKey: 'rating',
                  value: '4',
                },
                {
                  propertyKey: 'rating',
                  value: '5',
                },
              ]}
            />
            // <TextFilter
            //   {...filterProps}
            //   filteringAriaLabel="Filter rooms"
            //   filteringPlaceholder="Find rooms"
            //   filteringClearAriaLabel="Clear"
            //   countText={getTextFilterCounterText(filteredItemsCount || 0)}
            //   disabled={status.loading}
            // />
          }
          header={
            <FullPageHeader
              counter={getHeaderCounterText(rooms, collectionProps.selectedItems)}
              selectedItems={[]}
            />
          }
          items={items}
          loading={loading}
          loadingText="Loading rooms"
          pagination={<Pagination {...paginationProps} disabled={loading} />}
          preferences={
            <CollectionPreferences
              disabled={loading}
              pageSizePreference={{
                options: PAGE_SIZE_OPTIONS,
              }}
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail.custom)}
            />
          }
          // TODO use PropertyFilter
          // https://cloudscape.design/components/property-filter/?tabId=playground
          variant="full-page"
          stickyHeader
        />
      }
      contentType="cards"
      headerSelector=".top-navigation"
      maxContentWidth={Number.MAX_VALUE}
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}

export default trackWindowScroll(Rooms);
