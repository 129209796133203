import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Header,
  SpaceBetween,
  Button,
  Table,
  Pagination,
  PropertyFilter,
  StatusIndicator,
  Form,
  FormField,
  RadioGroup,
  Modal,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

import { UserInvite, UserWaitlistResponse } from '../../common/types';
import { getTextFilterCounterText } from '../../common/i18n-strings';
import { useApiNoBody, useApiNoResponse } from '../../common/api';
import { ROLE_OPTIONS } from '../../common/roles';
import { getHeaderCounterText } from '../../common/full-header';

import { filterWaitlists } from './filter';

export function WaitlistTable() {
  const pageSize = 10;
  const resourceName = 'Waitlist';
  const [selectedItems, setSelectedItems] = useState<UserWaitlistResponse[]>([]);
  const [waitlists, setWaitlists] = useState<UserWaitlistResponse[]>([]);
  const [role, setRole] = useState<string>('basic');
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [waitlistsApi, waitlistError, waitlistLoading] = useApiNoBody<UserWaitlistResponse[]>(
    '/waitlist/manage',
    'GET',
    setWaitlists,
  );
  const [inviteApi, inviteError, inviteLoading] = useApiNoResponse<UserInvite[]>('/invite', 'POST');
  const loading = waitlistLoading || inviteLoading;

  const fetchWaitlists = async () => {
    setSelectedItems([]);
    await waitlistsApi();
  };

  const inviteWaitlists = async () => {
    if (loading) {
      return;
    }

    const info: UserInvite[] = selectedItems
      .filter((w) => w.status === 'pending')
      .map((waitlist) => ({
        email: waitlist.email,
        roles: [role],
      }));

    if (info.length === 0) {
      setModalOpen(false);

      return;
    }

    const result = await inviteApi(info);

    if (!result.error) {
      setModalOpen(false);
      fetchWaitlists();
    }
  };

  useEffect(() => {
    fetchWaitlists();
  }, []);

  const {
    items,
    actions,
    collectionProps,
    propertyFilterProps,
    paginationProps,
    filteredItemsCount,
  } = useCollection(waitlists, {
    propertyFiltering: {
      filteringProperties: [
        {
          key: 'email',
          operators: [':', '!:'],
          propertyLabel: 'Email',
          groupValuesLabel: 'Email values',
          defaultOperator: ':',
        },
        {
          key: 'status',
          operators: ['=', '!='],
          propertyLabel: 'Status',
          groupValuesLabel: 'Status values',
        },
      ],
      filteringFunction: filterWaitlists,
      defaultQuery: {
        operation: 'and',
        tokens: [
          {
            propertyKey: 'status',
            operator: '=',
            value: 'Pending',
          },
        ],
      },
      empty: (
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {waitlistError ? (
            <SpaceBetween size="xxs">
              <div>
                <b>Error retrieving {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  {waitlistError}
                </Box>
              </div>
              <Button onClick={fetchWaitlists}>Retry</Button>
            </SpaceBetween>
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()}s found.
                </Box>
              </div>
            </SpaceBetween>
          )}
        </Box>
      ),
      noMatch: (
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          <SpaceBetween size="xxs">
            <div>
              <b>No matches</b>
              <Box color="inherit" variant="p">
                We can&apos;t find a match.
              </Box>
            </div>
            <Button onClick={() => actions.setPropertyFiltering({ operation: 'and', tokens: [] })}>
              Clear filter
            </Button>
          </SpaceBetween>
        </Box>
      ),
    },
    pagination: { pageSize },
    selection: {},
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'joined',
          sortingComparator(a, b) {
            const x = new Date(a.joined_at);
            const y = new Date(b.joined_at);

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        isDescending: true,
      },
    },
  });

  return (
    <Table
      {...collectionProps}
      {...propertyFilterProps}
      {...paginationProps}
      ariaLabels={{
        selectionGroupLabel: 'Items selection',
        allItemsSelectionLabel: ({ selectedItems }) =>
          `${selectedItems.length} ${selectedItems.length === 1 ? 'item' : 'items'} selected`,
        itemSelectionLabel: ({ selectedItems }, item) => {
          const isItemSelected = selectedItems.filter((i) => i.email === item.email).length;

          return `${item.email} is ${isItemSelected ? '' : 'not'} selected`;
        },
      }}
      columnDefinitions={[
        {
          id: 'email',
          header: 'Email',
          cell: (e) => e.email,
          sortingField: 'email',
          sortingComparator(a, b) {
            const x = a.email.toLowerCase();
            const y = b.email.toLowerCase();

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'status',
          header: 'Status',
          cell: (e) => (
            <StatusIndicator
              type={
                e.status === 'approved'
                  ? 'success'
                  : e.status === 'rejected'
                  ? 'error'
                  : 'in-progress'
              }
            >
              {e.status[0].toUpperCase() + e.status.slice(1)}
            </StatusIndicator>
          ),
          sortingField: 'status',
          sortingComparator(a, b) {
            const x = a.status.toLowerCase();
            const y = b.status.toLowerCase();

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'joined',
          header: 'Joined',
          // TODO format date & make it sortable
          cell: (e) => new Date(e.joined_at + 'Z').toLocaleString(),
          sortingField: 'joined',
          sortingComparator(a, b) {
            const x = new Date(a.joined_at);
            const y = new Date(b.joined_at);

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'reviewed',
          header: 'Reviewed',
          // TODO format date & make it sortable
          cell: (e) => (e.reviewed_at ? new Date(e.reviewed_at + 'Z').toLocaleString() : 'N/A'),
          sortingField: 'reviewed',
          sortingComparator(a, b) {
            const x = a.reviewed_at ? new Date(a.reviewed_at) : 0;
            const y = b.reviewed_at ? new Date(b.reviewed_at) : 0;

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
      ]}
      empty={
        <Box color="inherit" textAlign="center">
          <b>No waitlists</b>
          <Box color="inherit" padding={{ bottom: 's' }} variant="p">
            No waitlists to display.
          </Box>
        </Box>
      }
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          countText={getTextFilterCounterText(filteredItemsCount || 0)}
          disabled={waitlistLoading}
          filteringOptions={[
            {
              propertyKey: 'status',
              value: 'Approved',
            },
            {
              propertyKey: 'status',
              value: 'Rejected',
            },
            {
              propertyKey: 'status',
              value: 'Pending',
            },
          ]}
        />
      }
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button disabled={waitlistLoading} iconName="refresh" onClick={fetchWaitlists} />

              <Button
                disabled={loading || selectedItems.length === 0}
                variant="primary"
                onClick={() => setModalOpen(true)}
              >
                Invite
              </Button>
              <Modal
                closeAriaLabel="Cancel"
                footer={
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button variant="link" onClick={() => setModalOpen(false)}>
                        Cancel
                      </Button>
                      <Button loading={inviteLoading} variant="primary" onClick={inviteWaitlists}>
                        Ok
                      </Button>
                    </SpaceBetween>
                  </Box>
                }
                header="Invite users on waitlist"
                visible={modalOpen}
                onDismiss={() => setModalOpen(false)}
              >
                <form onSubmit={(event) => event.preventDefault()}>
                  <Form errorIconAriaLabel="Error" errorText={inviteError}>
                    <SpaceBetween size="l">
                      <FormField description="Select role for users." label="Role">
                        <RadioGroup
                          items={ROLE_OPTIONS}
                          value={role}
                          onChange={({ detail: { value } }) => setRole(value)}
                        />
                      </FormField>
                    </SpaceBetween>
                  </Form>
                </form>
              </Modal>
            </SpaceBetween>
          }
          counter={getHeaderCounterText(waitlists, collectionProps.selectedItems)}
        >
          User Waitlists
        </Header>
      }
      items={items}
      loading={waitlistLoading}
      loadingText="Loading waitlists"
      pagination={<Pagination {...paginationProps} disabled={waitlistLoading} />}
      selectedItems={selectedItems}
      selectionType="multi"
      trackBy="email"
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
    />
  );
}
