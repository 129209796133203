import { useContext } from 'react';
import { Header, AppLayout, ContentLayout, Grid } from '@cloudscape-design/components';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';

import Feed from './feed';

export default function FeedPage() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Feed', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout
          header={
            <Header
              description="See what's happening in the AI Escape community. Explore rooms, find new friends, and more!"
              variant="h1"
            >
              Community Feed
            </Header>
          }
        >
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 8,
                  s: 8,
                },
              },
            ]}
          >
            <Feed />
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
