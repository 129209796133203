import { Box, ColumnLayout, Header, Link } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { RoomDisplay } from '../../rooms/display';
import FreeSubscriptionInfo from '../../subscription/free';
import PlusSubscriptionInfo from '../../subscription/plus';
import { JoinSignup } from '../../signup/join';
import { BlogPostProps } from '../blog';

export function PublicPost(): BlogPostProps {
  const navigate = useNavigate();
  const exampleRooms = [
    '43a28564-08da-49b5-93cd-f72237d84acd',
    'c8707560-b4bc-4b61-96ec-09cfed65aaed',
    '0267b3a7-893a-43d7-9324-8d77fff844c5',
    'a3216c6c-35cd-4cee-b896-e0d6ec7efff1',
    '20da7f0b-e40e-4fbb-a55b-95193dcf5b67',
    '9a261b79-36dc-4cee-983d-8b65e333ccfa',
    '3ab30870-0eec-4bcd-bcb4-a7babec93e18',
  ];

  const post: BlogPostProps = {
    name: 'open',
    title: 'Open Beta Launch: Your AI Escape Adventure Begins Now',
    date: 'August 1st, 2023',
    user: 'Max Weinzierl',
    body: [
      {
        header: (
          <Header description="One for all and all for escape!" variant="h3">
            Welcome to AI Escape’s Ultimate Virtual Escape Room Experience
          </Header>
        ),
        content: [
          <Box variant="p">
            AI Escape is proud to open up our brand-new, cutting-edge escape room platform,
            utilizing the best of artificial intelligence to bring players immersive, dynamic, and
            customizable experiences. By harnessing AI's power, AI Escape has crafted an
            unparalleled experience, filled with endless storylines, countless puzzles required a
            wide array of skills, and beautifully illustrated AI-generated imagery. Additionally,
            our AI-generated speech and voice recognition enhance the players' interaction with the
            virtual environment, making each journey even more captivating.
          </Box>,
          <Box variant="p">
            With the launch of our open beta, the wait for your turn at unraveling mysteries is
            over!
          </Box>,
        ],
      },
      {
        header: (
          <Header description="We did good" variant="h3">
            Celebrating Our Successful Closed Beta Phase
          </Header>
        ),
        content: [
          <Box variant="p">
            In the past three months, AI Escape's closed beta piqued the interest of over 300 ardent
            players, tirelessly challenging the AI to create escapes that left them at the edge of
            their seats. Their feedback and support helped us optimize experiences, understand
            players' preferences, and build a system robust enough to cater to a larger audience.
          </Box>,
          <Box variant="p">Check out just a few of these amazing escape rooms below!</Box>,
          <Box variant="p"> </Box>,
          <RoomDisplay roomIds={exampleRooms} compact />,
          <Box variant="p"> </Box>,
          <Box variant="p">
            Be sure to head over to the{' '}
            <Link
              onFollow={(e) => {
                e.preventDefault();
                navigate('/feed');
              }}
            >
              Community Feed
            </Link>{' '}
            to see more of the amazing escapes our players have created!
          </Box>,
        ],
      },
      {
        header: (
          <Header description="Extra escape!" variant="h3">
            AI Escape Plus+
          </Header>
        ),
        content: [
          <Box variant="p">
            Our AI Escape Plus+ membership also took off, with our first enthusiastic members
            gaining exclusive access to the top-tier AI models and breathtaking AI-generated images.
            With AI Escape Plus+, you can enjoy the most advanced AI Escape experience, with the
            most challenging puzzles, the most captivating storylines, and the most stunning
            visuals. Plus, you can customize your escape room experience with various image styles,
            themes, and difficulty levels, ensuring a fresh and engaging adventure every time.
          </Box>,
          <Box variant="p">
            Join AI Escape Plus+ today and see where your imagination takes you!
          </Box>,
          <Box variant="p"> </Box>,
          <ColumnLayout columns={2}>
            <FreeSubscriptionInfo />
            <PlusSubscriptionInfo />
          </ColumnLayout>,
        ],
      },
      {
        header: (
          <Header description="Come one, come all!" variant="h3">
            Join The Adventure Today! Open Beta Now Available
          </Header>
        ),
        content: [
          <Box variant="p">
            The wait is finally over, and the AI Escape open beta is officially live! Join the
            global community of AI Escape users, sign up, and embark on your first escape. Explore
            puzzles and narratives, and share your virtual escapes in the{' '}
            <Link
              onFollow={(e) => {
                e.preventDefault();
                navigate('/feed');
              }}
            >
              Community Feed
            </Link>
            . Get a chance to be featured among the top players and enjoy a continuous stream of new
            challenges. Remember, your adventure is unique because it's shaped by your decisions and
            interactions within the game. So, take the leap, and let's escape with AI Escape: Where
            imagination escapes!
          </Box>,
          <Box variant="p" />,
          <JoinSignup />,
        ],
      },
    ],
  };

  return post;
}
