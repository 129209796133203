import {
  Container,
  Box,
  Header,
  ColumnLayout,
  SpaceBetween,
  Button,
  Modal,
  Form,
  FormField,
  Input,
} from '@cloudscape-design/components';
import { useState } from 'react';

import { useApiNoBody, useApiNoResponse } from '../../../common/api';
import { UserListResponse } from '../../../common/types';

export type UserDetailsProps = {
  user: UserListResponse;
  setUser: (user: UserListResponse) => void;
};

export function UserDetails(props: UserDetailsProps) {
  const { user } = props;
  const [addCreditsOpen, setAddCreditsOpen] = useState(false);
  const [creditsAmount, setCreditsAmount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [creditsApi, creditsError, creditsLoading] = useApiNoResponse(
    `/users/${user.id}/credits/add`,
    'POST',
  );
  const [userApi, userError, userLoading] = useApiNoBody<UserListResponse>(
    '/users',
    'GET',
    props.setUser,
  );

  const error = creditsError || userError;

  const addCredits = async () => {
    if (loading) return;
    setLoading(true);
    const response = await creditsApi({
      credits: creditsAmount,
    });

    if (!response.error) {
      const result = await userApi(undefined, user.uuid);

      if (!result.error) {
        setAddCreditsOpen(false);
      }
    }
    setLoading(false);
  };

  return (
    <SpaceBetween size="l">
      <Container
        header={
          <Header
            actions={
              <Button
                onClick={() => {
                  setAddCreditsOpen(true);
                }}
              >
                Add Credits
              </Button>
            }
            variant="h2"
          >
            User Details
          </Header>
        }
      >
        <ColumnLayout columns={1} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Email</Box>
            <div>{user.email}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Given Name</Box>
            <div>{user.given_name}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Family Name</Box>
            <div>{user.family_name}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Birthdate</Box>
            <div>{new Date(user.birthdate + 'T12:00:00.000Z').toLocaleDateString()}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Credits</Box>
            <div>{user.credits}</div>
          </div>
        </ColumnLayout>
        <Modal
          closeAriaLabel="Cancel"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={() => setAddCreditsOpen(false)}>
                  Cancel
                </Button>
                <Button disabled={loading} variant="primary" onClick={addCredits}>
                  Add
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Add credits to user"
          visible={addCreditsOpen}
          onDismiss={() => setAddCreditsOpen(false)}
        >
          <form onSubmit={(event) => event.preventDefault()}>
            <Form errorText={error}>
              <SpaceBetween size="l">
                <FormField description="Select amount of credits to add to user" label="Credits">
                  <Input
                    disabled={loading}
                    inputMode="numeric"
                    type="number"
                    value={`${creditsAmount}`}
                    onChange={({ detail }) => setCreditsAmount(parseInt(detail.value))}
                  />
                </FormField>
              </SpaceBetween>
            </Form>
          </form>
        </Modal>
      </Container>
    </SpaceBetween>
  );
}
