import {
  Container,
  Box,
  Grid,
  SpaceBetween,
  Button,
  GridProps,
  Modal,
  Link,
  Header,
} from '@cloudscape-design/components';
import { useState } from 'react';
import { LazyLoadImage, ScrollPosition } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';

import { useApiNoResponse } from '../../common/api';
import { MessageFeedback, MessageFeedbackRequest } from '../../common/feedback';
import { parseCaption } from '../../common/caption';

import { EscapeChatTTS } from './tts';
import { EscapeTurn } from './types';

import 'react-lazy-load-image-component/src/effects/blur.css';

export type EscapeChatTurnProps = {
  index: number;
  message: EscapeTurn;
  roomId?: string;
  userRoom?: boolean;
  undo?: (index: number) => Promise<void>;
  redo?: (index: number) => Promise<void>;
  loading?: boolean;
  escaped?: boolean;
  redos?: number;
  onLoad?: () => void;
  header?: React.ReactNode;
  children?: React.ReactNode;
  compact?: boolean;
  size?: 's' | 'm' | 'l';
  insideContainer?: boolean;
  scrollPosition?: ScrollPosition;
};

export function EscapeChatTurn(props: EscapeChatTurnProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const message = props.message;
  const index = props.index;
  const size = props.size ?? (props.compact ? 'm' : 'l');

  let messageText = message.content;

  if (props.compact && messageText.length > 550) {
    messageText = messageText.slice(0, 550) + '...';
  }

  const text = messageText.split('\n').map((line, line_idx) => (
    <Box key={`turn-${index}-line-${line_idx}`} className="cut-text" variant="p">
      {line}
    </Box>
  ));
  const author = (
    <Box variant="small">
      {message.role === 'user' ? (props.userRoom ? 'You' : 'Player') : 'AI'}
      {message.timestamp ? ` | ${new Date(message.timestamp + 'Z').toLocaleString()}` : ''}
    </Box>
  );

  const [feedback, setFeedback] = useState<MessageFeedback | undefined>(message.feedback);

  const [feedbackApi] = useApiNoResponse<MessageFeedbackRequest>('/session', 'POST');

  const goodFeedback = async () => {
    if (feedback === 'good') {
      setFeedback(undefined);
      await feedbackApi(
        {
          feedback: 'none',
        },
        `${props.roomId}/messages/${message.id}/feedback`,
      );
    } else {
      setFeedback('good');
      await feedbackApi(
        {
          feedback: 'good',
        },
        `${props.roomId}/messages/${message.id}/feedback`,
      );
    }
  };

  const badFeedback = async () => {
    if (feedback === 'bad') {
      setFeedback(undefined);
      await feedbackApi(
        {
          feedback: 'none',
        },
        `${props.roomId}/messages/${message.id}/feedback`,
      );
    } else {
      setFeedback('bad');
      await feedbackApi(
        {
          feedback: 'bad',
        },
        `${props.roomId}/messages/${message.id}/feedback`,
      );
    }
  };

  const feedbackForm = props.userRoom ? (
    message.role === 'assistant' ? (
      <SpaceBetween direction="horizontal" size="m">
        <Button
          disabled={
            props.loading || props.escaped || index === 0 || (props.redos ? props.redos <= 0 : true)
          }
          iconAlt="Redo response"
          iconName="redo"
          variant="link"
          onClick={async () => {
            if (props.redo) {
              await props.redo(props.index);
            }
          }}
        >
          Redo ({props.redos} left)
        </Button>
        <Button
          iconAlt="Good response"
          iconName={feedback === 'good' ? 'thumbs-up-filled' : 'thumbs-up'}
          variant="link"
          onClick={goodFeedback}
        />
        <Button
          iconAlt="Bad response"
          iconName={feedback === 'bad' ? 'thumbs-down-filled' : 'thumbs-down'}
          variant="link"
          onClick={badFeedback}
        />
      </SpaceBetween>
    ) : (
      <SpaceBetween direction="horizontal" size="m">
        <Button
          disabled={
            props.loading || index === 0 || props.escaped || (props.redos ? props.redos <= 0 : true)
          }
          iconAlt="Redo action"
          iconName="redo"
          variant="link"
          onClick={async () => {
            if (props.undo) {
              await props.undo(props.index);
            }
          }}
        >
          Redo ({props.redos} left)
        </Button>
      </SpaceBetween>
    )
  ) : null;

  const caption = parseCaption(message.caption);

  const gridDefinition: ReadonlyArray<GridProps.ElementDefinition> =
    size === 's'
      ? [{ colspan: { default: 12, xxs: 5 } }, { colspan: { default: 12, xxs: 7 } }]
      : size === 'm'
      ? [{ colspan: { default: 12, xxs: 7 } }, { colspan: { default: 12, xxs: 5 } }]
      : [{ colspan: { default: 12, xs: 5 } }, { colspan: { default: 12, xs: 7 } }];

  const content = !message.image_url ? (
    <SpaceBetween direction="vertical" size="xs">
      {text}
      {message.tts_url ? <EscapeChatTTS autoPlay={message.autoPlay} src={message.tts_url} /> : null}
      {feedbackForm}
    </SpaceBetween>
  ) : (
    <>
      <Grid gridDefinition={gridDefinition}>
        <Link
          href={props.compact && props.roomId ? `/rooms/${props.roomId}` : undefined}
          variant="info"
          onFollow={(e) => {
            if (props.compact && props.roomId) {
              e.preventDefault();
              navigate(`/rooms/${props.roomId}`);
            } else {
              e.preventDefault();
              setOpen(true);
            }
          }}
        >
          <div className="chat-image">
            <LazyLoadImage
              alt={caption}
              effect="blur"
              height="auto"
              placeholder={
                <img
                  alt={caption}
                  height="auto"
                  src="https://cdn.aiescape.io/loading.png"
                  title={caption}
                  width="100%"
                />
              }
              scrollPosition={props.scrollPosition}
              src={message.image_url}
              threshold={500}
              title={caption}
              width="100%"
              onLoad={props.onLoad}
            />
          </div>
        </Link>
        <SpaceBetween direction="vertical" size="xs">
          {text}
          <SpaceBetween direction="horizontal" size="xs">
            {message.tts_url ? (
              <EscapeChatTTS autoPlay={message.autoPlay} src={message.tts_url} />
            ) : null}
            {feedbackForm ? <div className="chat-feedback-s">{feedbackForm}</div> : null}
          </SpaceBetween>
        </SpaceBetween>
      </Grid>
      {props.compact && props.roomId ? undefined : (
        <Modal
          closeAriaLabel="Close"
          size="large"
          // header="Add credits to user"
          visible={open}
          onDismiss={() => setOpen(false)}
        >
          <SpaceBetween direction="vertical" size="m">
            <LazyLoadImage
              alt={caption}
              effect="blur"
              height="auto"
              placeholder={
                <img
                  alt={caption}
                  height="auto"
                  src="https://cdn.aiescape.io/loading.png"
                  title={caption}
                  width="100%"
                />
              }
              scrollPosition={props.scrollPosition}
              src={message.image_url}
              threshold={500}
              title={caption}
              width="100%"
            />
            <SpaceBetween direction="vertical" size="xxxs">
              <Header variant="h3">Description</Header>
              <Box variant="p">{caption}</Box>
            </SpaceBetween>
          </SpaceBetween>
        </Modal>
      )}
    </>
  );

  return props.insideContainer ? (
    <>
      {author}
      {content}
      {props.children}
    </>
  ) : (
    <Container key={`turn-${index}`} header={props.header}>
      {author}
      {content}
      {props.children}
    </Container>
  );
}
