import { Button, Container, Grid, Input, SpaceBetween } from '@cloudscape-design/components';
import { useState } from 'react';

import { useApi, useApiDirectBody } from '../../common/api';
import { parseCaption } from '../../common/caption';
import EscapeProgressBar from '../../common/progress-bar';

import { TurnAudioRecorder } from './audio';
import {
  EscapeRoomAudioResponse,
  EscapeRoomResponse,
  EscapeRoomStatus,
  EscapeTurn,
  EscapeTurnRequest,
} from './types';

export type RoomChatBarProps = {
  room: EscapeRoomStatus;
  messages: EscapeTurn[];
  setMessages: (messages: EscapeTurn[]) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  error: string;
  setError: (error: string) => void;
  autoPlay: boolean;
};

export function RoomChatBar(props: RoomChatBarProps) {
  const { room, messages, setMessages, loading, setLoading, error, setError, autoPlay } = props;
  const [audioFile, setAudioFile] = useState<Blob | null>(null);
  const [audioDuration, setAudioDuration] = useState(0);
  const [message, setMessage] = useState('');
  const escaped = messages[messages.length - 1]?.escaped;

  const [stepApi] = useApi<EscapeTurnRequest, EscapeRoomResponse>(
    `/session/${room.id}/messages`,
    'POST',
  );
  const [stepAudioApi] = useApiDirectBody<EscapeRoomAudioResponse>(
    `/session/${room.id}/audio`,
    'POST',
  );

  const sendTextMessage = async () => {
    const oldMessages = [...messages];
    const m: EscapeTurn = {
      role: 'user',
      content: message,
      timestamp: new Date().toISOString().replace('Z', ''),
      token_usage: messages[messages.length - 1]?.token_usage ?? 0,
    };
    const newMessages = [...messages, m];
    setMessages(newMessages);
    setMessage('');
    setError('');
    const response = await stepApi({
      role: 'user',
      content: message,
    });

    if (response.error) {
      setMessages(oldMessages);
      setMessage(message);
      setError(response.error);
    } else if (response.data) {
      const newMessage: EscapeTurn = {
        id: response.data.id,
        role: 'assistant',
        content: response.data.response,
        image_url: response.data.image_url,
        tts_url: response.data.tts_url,
        caption: parseCaption(response.data.image_prompt),
        escaped: response.data.escaped,
        hint: response.data.hint,
        timestamp: response.data.timestamp,
        token_usage: response.data.token_usage,
        model_duration: response.data.model_duration,
        image_duration: response.data.image_duration,
        tts_duration: response.data.tts_duration,
        autoPlay,
      };
      // need to use the id from the response
      m.id = newMessage.id;
      const responseMessages = [...newMessages, newMessage];
      setMessages(responseMessages);
      setError('');
    }
  };

  const sendAudioMessage = async () => {
    const response = await stepAudioApi(audioFile, undefined, {
      duration: audioDuration,
    });

    if (response.error) {
      setError(response.error);
    } else if (response.data) {
      setAudioFile(null);
      setAudioDuration(0);
      const newMessage: EscapeTurn = {
        id: response.data.id,
        role: 'user',
        content: response.data.message,
        timestamp: response.data.message_timestamp,
        token_usage: messages[messages.length - 1]?.token_usage ?? 0,
      };
      const newResponse: EscapeTurn = {
        id: response.data.id,
        role: 'assistant',
        content: response.data.response,
        image_url: response.data.image_url,
        tts_url: response.data.tts_url,
        caption: parseCaption(response.data.image_prompt),
        escaped: response.data.escaped,
        hint: response.data.hint,
        timestamp: response.data.timestamp,
        token_usage: response.data.token_usage,
        model_duration: response.data.model_duration,
        image_duration: response.data.image_duration,
        tts_duration: response.data.tts_duration,
        autoPlay,
      };
      const responseMessages = [...messages, newMessage, newResponse];
      setMessages(responseMessages);
      setError('');
    }
  };

  const sendMessage = async () => {
    if (loading) return;

    if (!message && !audioFile) {
      return;
    }
    setError('');
    setLoading(true);

    if (audioFile) {
      if (audioDuration < 0.5) {
        setError('Audio message must be at least half a second long');
        setLoading(false);

        return;
      } else if (audioDuration > 30) {
        setError('Audio message must be less than 30 seconds long');
        setLoading(false);

        return;
      }
      await sendAudioMessage();
    } else {
      await sendTextMessage();
    }
    setLoading(false);
  };

  return (
    <div className="escape-chat-box">
      <Container>
        <SpaceBetween size="m">
          <Grid gridDefinition={[{ colspan: { default: 10 } }, { colspan: { default: 2 } }]}>
            <Input
              disabled={loading || escaped || audioFile !== null}
              placeholder="Enter your next step..."
              value={message}
              autoFocus
              onChange={({ detail }) => setMessage(detail.value)}
              onKeyDown={(e) => {
                if (e.detail.key === 'Enter' && !loading && !escaped) {
                  sendMessage();
                }
              }}
            />
            <TurnAudioRecorder
              audioDuration={audioDuration}
              audioFile={audioFile}
              loading={loading}
              setAudioDuration={setAudioDuration}
              setAudioFile={setAudioFile}
              setError={setError}
            />
          </Grid>
          <Grid
            gridDefinition={[
              { colspan: { default: 8, xxs: 8, xs: 10 } },
              { colspan: { default: 4, xxs: 4, xs: 2 } },
            ]}
          >
            <EscapeProgressBar
              error={error}
              errorTitle="Error sending. If the error persists please try again later"
              imageTitle="Generating next image..."
              loading={loading}
              modelTitle="Generating next step..."
              readyTitle="Ready to send"
            />
            <Button
              disabled={escaped || (audioFile === null && !message)}
              loading={loading}
              loadingText="Sending..."
              wrapText={false}
              onClick={() => {
                if (!loading && !escaped) {
                  sendMessage();
                }
              }}
            >
              Send
            </Button>
          </Grid>
        </SpaceBetween>
      </Container>
    </div>
  );
}
