import { Link } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { PublicUser } from '../rooms/room/types';

export type ProfileNameProps = {
  user: PublicUser;
  hideLink?: boolean;
  hideIcon?: boolean;
  manage?: boolean;
};

export function ProfileName(props: ProfileNameProps) {
  const { user } = props;
  const navigate = useNavigate();
  const userName = user.given_name + ' ' + user.family_initial;

  // TODO add little icon for subscribers maybe
  // not sure, don't like <Icon name="add-plus" size='small' />
  // <Icon name="status-positive" size='small' />
  const link = props.manage ? `/manage/users/${user.uuid}` : `/profile/${user.uuid}`;

  return (
    <Link
      fontSize="inherit"
      href={props.hideLink ? undefined : link}
      variant="secondary"
      onFollow={(e) => {
        e.preventDefault();
        navigate(link);
      }}
    >
      {userName}
    </Link>
  );
}
