import { Box, Container, Header } from '@cloudscape-design/components';

import { EscapeChatTurn } from '../../rooms/room/turn';
import { EscapeTurn } from '../../rooms/room/types';
import { BlogPostProps } from '../blog';

export function IntroPost(): BlogPostProps {
  const chat: EscapeTurn[] = [
    {
      role: 'user',
      content: 'Look around. Where am I?',
    },
    {
      role: 'assistant',
      content:
        'You find yourself in a dimly lit room with stone walls, about 10 feet by 10 feet in size. There is a small wooden table against one wall, with a candle flickering on it. On the opposite wall, there is a sturdy wooden door with a metal lock. To the left, you see a bookshelf filled with various old books and scrolls. In the corner to the right, there is a dusty, cobweb-covered chest. There are no windows, and the only source of light is the candle on the table.',
    },
    {
      role: 'user',
      content: 'I approach the table.',
    },
    {
      role: 'assistant',
      content:
        "As you approach the table, you notice that the candle's flickering light reveals some items placed on the table. You see a small brass key, a piece of crumpled paper, and a magnifying glass. The table itself seems to be made of old wood and has some scratches and marks on its surface.",
    },
    {
      role: 'user',
      content: 'Describe the shape of the key',
    },
    {
      role: 'assistant',
      content:
        "The small brass key has a round bow at the top, making it easy to grip. The stem is straight, and it leads to the key's bit, which has a simple, yet unique pattern of notches and wards. The key looks old but well-preserved, and its compact size suggests it might fit a small lock.",
    },
    {
      role: 'user',
      content: 'Pick up the crumpled paper. What does it have on it?',
    },
    {
      role: 'assistant',
      content: `You pick up the crumpled paper and carefully unfold it. The paper is worn and slightly yellowed with age, but you can still read the text written on it. It appears to be a riddle:

      "In the room of stone and wood,
      A hidden passage can be viewed,
      Seek a book that's not like others,
      Its secret lies within its covers."
      
      The riddle seems to be providing a clue about something in the room. The paper has no other markings or text on it.`,
    },
    {
      role: 'user',
      content: 'Inspect the magnifying glass',
    },
    {
      role: 'assistant',
      content:
        'You pick up the magnifying glass and inspect it. The handle is made of a dark, polished wood and feels solid in your hand. The circular glass is framed by a brass rim, and it appears to be of good quality, without any scratches or cracks. The magnifying glass seems like it could be a useful tool for examining small details or reading fine print.',
    },
    {
      role: 'user',
      content: 'Look around the room for any books',
    },
    {
      role: 'assistant',
      content: `You turn your attention to the bookshelf on the left side of the room. The shelves are filled with various old books and scrolls. Some books have leather covers and are bound with fraying ropes, while others have hard, dusty covers with fading titles. The scrolls look brittle and have yellowed with age. The books and scrolls seem to be a mix of fiction and nonfiction, covering topics like history, alchemy, and myths.

      Remembering the riddle on the crumpled paper, you start to look for any book that stands out or appears to be different from the others.`,
    },
    {
      role: 'user',
      content: 'Move to the chest',
    },
    {
      role: 'assistant',
      content: `You walk over to the dusty, cobweb-covered chest in the right corner of the room. The chest is made of old, dark wood, and it has ornate metal hinges and a large, rusted padlock securing the lid. The chest is about three feet long, two feet wide, and two feet deep.

      As you approach the chest, you notice a faint smell of dampness and age coming from it. The dust on the chest suggests that it has not been opened for quite some time.`,
    },
    {
      role: 'user',
      content: "Check if the key from the desk fits the chest's lock",
    },
    {
      role: 'assistant',
      content: `You take the small brass key that you found on the table and insert it into the rusted padlock on the chest. However, the key doesn't fit. The lock appears to be much larger and more complex than the small key. It seems like you'll need to find another key or method to unlock this chest.`,
    },
    {
      role: 'user',
      content: 'Wipe off the cobwebs and inspect the chest',
    },
    {
      role: 'assistant',
      content: `You carefully brush away the cobwebs and dust from the surface of the chest, revealing more details about its construction. The old, dark wood is adorned with intricately carved patterns and symbols, some of which appear to be alchemical or magical in nature. The metal hinges and fittings are ornate, and the rusted padlock has an emblem of a serpent entwined around a keyhole.
      
      As you inspect the chest, you notice a faint outline of a hidden compartment on one side. It's cleverly concealed within the carved patterns, but the outline suggests a small, rectangular door secured by a tiny keyhole. This keyhole seems like a better match for the small brass key you found earlier.`,
    },
    {
      role: 'user',
      content: 'Insert the key and open the small door',
    },
    {
      role: 'assistant',
      content: `You take the small brass key and insert it into the tiny keyhole of the hidden compartment on the side of the chest. The key fits perfectly, and you turn it with a satisfying click. The small door pops open, revealing a secret compartment.

      Inside the compartment, you find a thin, leather-bound book with a plain cover. The book appears to be unique compared to the other books on the shelf, as it lacks any title or markings on the cover. Remembering the riddle from the crumpled paper, you suspect that this book may hold the secret to finding the hidden passage mentioned in the riddle.`,
    },
    {
      role: 'user',
      content: 'Open the book and look inside',
    },
    {
      role: 'assistant',
      content: `You carefully open the leather-bound book and begin to flip through the pages. The pages are old and slightly yellowed, but the text is still clearly legible. As you examine the book, you notice that it doesn't contain a continuous story or information like the other books on the shelf. Instead, each page has a single word written in a different language, along with a small illustration.

      As you continue to flip through the pages, you find one page that stands out. The word on this page is written in a language you recognize, and it says "Passage." The illustration below the word shows a bookshelf with a slightly ajar door hidden behind it. This seems to be the clue you've been looking for to reveal the hidden passage.`,
    },
  ];

  const post: BlogPostProps = {
    name: 'intro',
    title: 'Introducing AI Escape: The Future of Mind-Bending Adventure',
    date: 'April 10th, 2023',
    user: 'Max Weinzierl',
    body: [
      {
        header: (
          <Header description="Next Level Escape" variant="h3">
            Introducing: AI Escape!
          </Header>
        ),
        content: [
          <Box variant="p">
            Hello, fellow escape room enthusiasts and puzzle lovers! We're excited to introduce you
            to AI Escape, an innovative concept that combines the thrill of escape rooms with the
            power of artificial intelligence. This brainchild was born during a playful session with
            GPT-4, the latest and most advanced AI language model, right after its release. The idea
            quickly evolved into an exhilarating and immersive experience that's unlike anything
            you've ever seen before.
          </Box>,
          <Box variant="p">
            AI Escape offers a unique escape room experience, where each room is dynamically
            generated by our state-of-the-art AI. Gone are the days of repetitive puzzles and
            predictable solutions. Instead, you'll be greeted with endless possibilities, engaging
            storylines, and mind-boggling challenges that cater to your skill level.
          </Box>,
        ],
      },
      {
        header: (
          <Header description="or: How I Learned to Stop Worrying and Love the LLM" variant="h3">
            GPT-4
          </Header>
        ),
        content: [
          <Box variant="p">
            As we experimented with GPT-4, we were blown away by its ability to craft intricate
            narratives, create complex riddles, and even understand the nuances of a good escape
            room puzzle. We realized that this technology had the potential to revolutionize the
            escape room industry, offering players an ever-changing and evolving experience that
            keeps them on their toes.
          </Box>,
          <Box variant="p">
            In our first blog post of this exciting series, we wanted to share with you the story of
            how AI Escape came to life. It started with a simple idea: what if we could harness the
            power of AI to create an escape room experience that challenges players like never
            before? We began by testing GPT-4's capabilities in generating puzzles, riddles, and
            storylines, and the results exceeded our wildest expectations.
          </Box>,
          <Box variant="p">
            Below is our VERY FIRST conversation with GPT-4, where we asked it to generate a simple
            escape room puzzle. We were amazed by the quality of the puzzle, and how it was able to
            understand the context of the conversation and craft a compelling narrative around it.
            We were hooked.
          </Box>,
          <Box variant="p"> </Box>,
          <Container>
            {chat.map((turn, index) => (
              <EscapeChatTurn key={`chat-${index}`} index={index} message={turn} insideContainer />
            ))}
          </Container>,
          <Box variant="p"> </Box>,
          <Box variant="p">
            With each new challenge generated by GPT-4, we found ourselves completely immersed in
            the experience, eager to solve the puzzles and unravel the mysteries. It was during
            these moments of pure excitement and fun that the idea of AI Escape truly took shape.
          </Box>,
        ],
      },
      {
        header: (
          <Header description="is now!" variant="h3">
            The Future
          </Header>
        ),
        content: [
          <Box variant="p">
            The future of escape rooms is here, and we can't wait for you to dive into this world of
            intrigue, mystery, and adventure. Join us on this unforgettable journey as we explore
            the limitless possibilities of AI-generated escape rooms. Stay tuned for more blog posts
            in this series where we'll delve deeper into the fascinating world of AI Escape, sharing
            behind-the-scenes insights, updates, and more.
          </Box>,
          <Box variant="p">
            Are you ready to put your problem-solving skills to the test against the most advanced
            AI? Sign up for the AI Escape waitlist today, and prepare to embark on an adventure like
            no other. The clock is ticking, and the AI is waiting. Can you escape?
          </Box>,
        ],
      },
    ],
  };

  return post;
}
