import { Box, Container, Header, MixedLineBarChart } from '@cloudscape-design/components';
import { useEffect, useMemo, useState } from 'react';

import { ImageModel, Model } from '../../common/room';
import { EscapeRoom } from '../../rooms/types';
import { useApiNoBody } from '../../common/api';
import { EscapeTurn } from '../../rooms/room/types';
import { UserTransactionResponse } from '../../common/types';

export function CostGraph() {
  const [messages, setMessages] = useState<EscapeTurnFromRoom[]>([]);
  const [transactions, setTransactions] = useState<UserTransactionResponse[]>([]);

  const [roomsApi, roomsError, roomsLoading] = useApiNoBody<EscapeTurnFromRoom[]>(
    '/session/messages',
    'GET',
    setMessages,
  );

  const [transactionsApi, transactionsError, transactionsLoading] = useApiNoBody<
    UserTransactionResponse[]
  >('/transactions', 'GET', setTransactions);

  const loading = roomsLoading || transactionsLoading;
  const error = roomsError || transactionsError;

  const fetchRooms = async () => {
    await roomsApi();
  };

  const fetchTransactions = async () => {
    await transactionsApi();
  };

  useEffect(() => {
    fetchRooms();
    fetchTransactions();
  }, []);

  const [plusCosts, freeCosts, revenues] = useMemo(() => {
    const messageCosts = messages.map((m) => getRoomMessageCosts(m));
    const minDate = new Date(
      Math.min(
        ...messageCosts.map((message) => new Date(message.timestamp + 'Z').getTime()),
        ...transactions.map((transaction) => new Date(transaction.created_at + 'Z').getTime()),
      ),
    );
    const minMonth = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
    const maxDate = new Date(
      Math.max(
        ...messageCosts.map((message) => new Date(message.timestamp + 'Z').getTime()),
        ...transactions.map((transaction) => new Date(transaction.created_at + 'Z').getTime()),
      ),
    );
    const maxMonth = new Date(maxDate.getFullYear(), maxDate.getMonth(), 1);
    const months = [];

    for (let date = minMonth; date <= maxMonth; date.setMonth(date.getMonth() + 1)) {
      months.push(date.toISOString());
    }

    const plusCosts = months.map((month) => {
      const cost = messageCosts
        .filter((message) => {
          if (!message.tts_url) {
            return false;
          }

          const date = new Date(message.timestamp + 'Z');
          const monthDate = new Date(date.getFullYear(), date.getMonth(), 1);

          return monthDate.toISOString() === month;
        })
        .reduce((acc, message) => acc + message.totalCost, 0);

      return {
        x: month,
        y: cost,
      };
    });
    const freeCosts = months.map((month) => {
      const cost = messageCosts
        .filter((message) => {
          if (message.tts_url) {
            return false;
          }

          const date = new Date(message.timestamp + 'Z');
          const monthDate = new Date(date.getFullYear(), date.getMonth(), 1);

          return monthDate.toISOString() === month;
        })
        .reduce((acc, message) => acc + message.totalCost, 0);

      return {
        x: month,
        y: cost,
      };
    });
    const revenues = months.map((month) => {
      const cost = transactions
        .filter((transaction) => {
          const date = new Date(transaction.created_at + 'Z');
          const monthDate = new Date(date.getFullYear(), date.getMonth(), 1);

          return monthDate.toISOString() === month;
        })
        .reduce(
          (acc, transaction) => acc + parseFloat((parseFloat(transaction.price) / 100).toFixed(2)),
          0,
        );

      return {
        x: month,
        y: cost,
      };
    });

    return [plusCosts, freeCosts, revenues];
  }, [messages, transactions]);

  return (
    <Container
      header={
        <Header
          // actions={
          //   <Button variant="normal" onClick={() => navigate('/manage/metrics')}>
          //     View Details
          //   </Button>
          // }
          description={<Box variant="span">Direct costs and revenue for free & paying users.</Box>}
          variant="h3"
        >
          Costs & Revenue
        </Header>
      }
    >
      <MixedLineBarChart
        empty={
          <Box color="inherit" textAlign="center">
            <b>No data available</b>
            <Box color="inherit" variant="p">
              There is no data available
            </Box>
          </Box>
        }
        height={200}
        i18nStrings={{
          xTickFormatter: (e) =>
            new Date(e)
              .toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
              })
              .split(',')
              .join('\n'),
          yTickFormatter: function o(e) {
            return Math.abs(e) >= 1e9
              ? (e / 1e9).toFixed(1).replace(/\.0$/, '') + 'G'
              : Math.abs(e) >= 1e6
              ? (e / 1e6).toFixed(1).replace(/\.0$/, '') + 'M'
              : Math.abs(e) >= 1e3
              ? (e / 1e3).toFixed(1).replace(/\.0$/, '') + 'K'
              : e.toFixed(0);
          },
        }}
        series={[
          {
            title: 'Plus Costs',
            type: 'bar',
            data: plusCosts,
            valueFormatter: (e) => '$' + e.toFixed(2).toLocaleString(),
          },
          {
            title: 'Free Costs',
            type: 'bar',
            data: freeCosts,
            valueFormatter: (e) => '$' + e.toFixed(2).toLocaleString(),
          },
          {
            title: 'Revenue',
            type: 'line',
            data: revenues,
            valueFormatter: (e) => '$' + e.toFixed(2).toLocaleString(),
          },
        ]}
        statusType={error ? 'error' : loading ? 'loading' : 'finished'}
        xScaleType="categorical"
        xTitle="Month"
        yTitle="USD"
        emphasizeBaselineAxis
        hideFilter
        stackedBars
      />
    </Container>
  );
}

// Current constants for model costs and usage
export function getPromptCost(tokens: number) {
  // GPT-4o
  return 0.005 * (tokens / 1000);
}

export function getCompletionCost(tokens: number) {
  // GPT-4o
  return 0.015 * (tokens / 1000);
}

export function getCost(promptTokens: number, completionTokens: number) {
  return getPromptCost(promptTokens) + getCompletionCost(completionTokens);
}

export function getImageCost() {
  return 0.04;
}

export type RoomCost = {
  createdAt: Date;
  imageCost: number;
  promptCost: number;
  completionCost: number;
  totalCost: number;
};

export function getRoomCost(room: EscapeRoom) {
  const imageCost = getImageCost() * room.messages.filter((message) => message.image_url).length;
  const promptCost = room.messages.reduce(
    (acc, message) => acc + (message.prompt_tokens ? getPromptCost(message.prompt_tokens) : 0),
    0,
  );
  const completionCost = room.messages.reduce(
    (acc, message) =>
      acc + (message.completion_tokens ? getCompletionCost(message.completion_tokens) : 0),
    0,
  );
  const totalCost = imageCost + promptCost + completionCost;
  const cost: RoomCost = {
    createdAt: new Date(room.created_at + 'Z'),
    imageCost,
    promptCost,
    completionCost,
    totalCost,
  };

  return cost;
}

export type EscapeTurnFromRoom = EscapeTurn & {
  model: Model;
  image_model: ImageModel;
};

export type EscapeTurnCost = EscapeTurnFromRoom & {
  promptCost: number;
  imageCost: number;
  completionCost: number;
  totalCost: number;
};

export function getRoomMessageCosts(message: EscapeTurnFromRoom): EscapeTurnCost {
  const promptCost = message.prompt_tokens ? getPromptCost(message.prompt_tokens) : 0;
  const completionCost = message.completion_tokens
    ? getCompletionCost(message.completion_tokens)
    : 0;
  const imageCost = message.image_url ? getImageCost() : 0;
  const totalCost = promptCost + completionCost + imageCost;

  return {
    promptCost,
    imageCost,
    completionCost,
    totalCost,
    ...message,
  };
}
