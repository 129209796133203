import { Container, Box, Header, ColumnLayout, SpaceBetween } from '@cloudscape-design/components';

import { PublicUser } from '../rooms/room/types';

import { ProfileName } from './name';

export type ProfileDetailsProps = {
  user: PublicUser;
};

export function ProfileDetails(props: ProfileDetailsProps) {
  const { user } = props;

  return (
    <SpaceBetween size="l">
      <Container
        header={
          <Header variant="h3">
            <ProfileName user={user} hideLink />
            's Profile
          </Header>
        }
      >
        {/* TODO figure out what to display here! */}
        <ColumnLayout columns={1} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Joined</Box>
            <div>{new Date(user.created_at + 'Z').toLocaleDateString()}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">Plan</Box>
            <div>{user.subscribed ? 'AI Escape Plus+' : 'AI Escape Free'}</div>
          </div>
          {user.sessions ? (
            <div>
              <Box variant="awsui-key-label">Rooms</Box>
              <div>{user.sessions?.toLocaleString()}</div>
            </div>
          ) : null}
          {user.escapes ? (
            <div>
              <Box variant="awsui-key-label">Escapes</Box>
              <div>{user.escapes?.toLocaleString()}</div>
            </div>
          ) : null}
        </ColumnLayout>
      </Container>
    </SpaceBetween>
  );
}
