import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { UserSubscription } from '../common/types';

export type SubscriptionItemProps = {
  sub?: UserSubscription | null;
};

export function SubscriptionItem(props: SubscriptionItemProps) {
  const sub = props.sub;
  const navigate = useNavigate();

  return (
    <SpaceBetween size="l">
      {sub ? (
        <Container
          header={
            <Header
              actions={
                <Button
                  href="https://billing.stripe.com/p/login/28o2awe6cdTN7Ac9AA"
                  target="_blank"
                  variant="normal"
                >
                  Manage
                </Button>
              }
              variant="h2"
            >
              Your Purchases
            </Header>
          }
        >
          <ColumnLayout columns={1} variant="text-grid">
            <div>
              <Box variant="awsui-key-label">Plan</Box>
              <div>
                <Link
                  href="/subscription"
                  onFollow={(e) => {
                    e.preventDefault();
                    navigate('/subscription');
                  }}
                >
                  {sub.role === 'advanced' ? 'AI Escape Plus+' : 'unknown'}
                </Link>
              </div>
            </div>
            <div>
              <Box variant="awsui-key-label">Renews or Expires at</Box>
              <div>{new Date(sub.expires_at + 'Z').toLocaleString()}</div>
            </div>
          </ColumnLayout>
        </Container>
      ) : (
        <Container
          header={
            <Header
              actions={
                <Button
                  href="https://billing.stripe.com/p/login/28o2awe6cdTN7Ac9AA"
                  target="_blank"
                  variant="normal"
                >
                  Manage
                </Button>
              }
              variant="h2"
            >
              Your Purchases
            </Header>
          }
        >
          <Box variant="p">You don't have a subscription yet!</Box>
          <Box variant="p">
            <Link
              href="/subscription"
              onFollow={(e) => {
                e.preventDefault();
                navigate('/subscription');
              }}
            >
              Check out our plans page
            </Link>{' '}
            to get started.
          </Box>
        </Container>
      )}
    </SpaceBetween>
  );
}
