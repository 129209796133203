import { Box, Container, Header, AreaChart } from '@cloudscape-design/components';
import { useEffect, useMemo, useState } from 'react';

import { useApiNoBody } from '../../common/api';
import { UserInviteResponse } from '../../common/types';

export function UsersGraph() {
  const [invites, setInvites] = useState<UserInviteResponse[]>([]);

  const [invitesApi, error, loading] = useApiNoBody<UserInviteResponse[]>(
    '/invite',
    'GET',
    setInvites,
  );

  const fetchInvites = async () => {
    await invitesApi();
  };

  useEffect(() => {
    fetchInvites();
  }, []);

  const [invited, joined, totalUsers] = useMemo(() => {
    const minDate = new Date(Math.min(...invites.map((i) => new Date(i.sent_at + 'Z').getTime())));
    const minMonth = new Date(minDate.getFullYear(), minDate.getMonth(), 1);
    const maxDate = new Date(Math.max(...invites.map((i) => new Date(i.sent_at + 'Z').getTime())));
    const maxMonth = new Date(maxDate.getFullYear(), maxDate.getMonth(), 1);
    const months = [];

    for (let date = minMonth; date <= maxMonth; date.setMonth(date.getMonth() + 1)) {
      months.push(date.toISOString());
    }

    let totalInvited = 0;
    const invited = months.map((month) => {
      const count = invites.filter((i) => {
        if (i.redeemed_at) {
          return false;
        }

        const date = new Date(i.sent_at + 'Z');
        const monthDate = new Date(date.getFullYear(), date.getMonth(), 1);

        return monthDate.toISOString() === month;
      }).length;
      totalInvited += count;

      return {
        x: new Date(month),
        y: totalInvited,
      };
    });
    let totalJoined = 0;
    const joined = months.map((month) => {
      const count = invites.filter((i) => {
        if (!i.redeemed_at) {
          return false;
        }

        const date = new Date(i.sent_at + 'Z');
        const monthDate = new Date(date.getFullYear(), date.getMonth(), 1);

        return monthDate.toISOString() === month;
      }).length;
      totalJoined += count;

      return {
        x: new Date(month),
        y: totalJoined,
      };
    });
    let total = 0;
    const totalUsers = months.map((month) => {
      const count = invites.filter((i) => {
        const date = new Date(i.sent_at + 'Z');
        const monthDate = new Date(date.getFullYear(), date.getMonth(), 1);

        return monthDate.toISOString() === month;
      }).length;
      total += count;

      return {
        x: new Date(month),
        y: total,
      };
    });

    return [invited, joined, totalUsers];
  }, [invites]);

  return (
    <Container
      header={
        <Header
          // actions={
          //   <Button variant="normal" onClick={() => navigate('/manage/metrics')}>
          //     View Details
          //   </Button>
          // }
          description={<Box variant="span">Users were invited after providing their email.</Box>}
          variant="h3"
        >
          User Acquisition
        </Header>
      }
    >
      <AreaChart
        empty={
          <Box color="inherit" textAlign="center">
            <b>No data available</b>
            <Box color="inherit" variant="p">
              There is no data available
            </Box>
          </Box>
        }
        height={200}
        i18nStrings={{
          xTickFormatter: (e) =>
            e
              .toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
              })
              .split(',')
              .join('\n'),
          yTickFormatter: function o(e) {
            return e.toLocaleString();
          },
        }}
        series={[
          {
            title: 'Joined Users',
            data: joined,
            type: 'area',
            valueFormatter: (e) => e.toLocaleString(),
          },
          {
            title: 'Invited Users',
            data: invited,
            type: 'area',
            valueFormatter: (e) => e.toLocaleString(),
          },
        ]}
        statusType={error ? 'error' : loading ? 'loading' : 'finished'}
        xScaleType="time"
        xTitle="Month"
        yTitle="Users"
        hideFilter
      />
    </Container>
  );
}
