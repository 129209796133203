import {
  Container,
  Header,
  FormField,
  SpaceBetween,
  Input,
  Toggle,
  Hotspot,
  Textarea,
} from '@cloudscape-design/components';
import { useContext } from 'react';

import { getPermissionsPrefix } from '../../../common/auth';
import { RoomBasicInfo } from '../../../common/room';
import UserContext from '../../../common/user';

export type BasicInfoStepProps = {
  info: RoomBasicInfo;
  onChange: (info: RoomBasicInfo) => void;
};

export default function BasicInfoStep(props: BasicInfoStepProps) {
  const info = props.info;
  const onChange = props.onChange;
  const { user } = useContext(UserContext);
  const themes = getPermissionsPrefix('themes.', user);

  return (
    <Container header={<Header variant="h2">Room Info</Header>}>
      <SpaceBetween size="l">
        <FormField
          description="Provide a name for your room. The AI does not use the name of the room, this is simply for you and other players to remember the room. You can always change this later!"
          label="Name"
        >
          <Hotspot hotspotId="room-name" side="right">
            <Input
              value={info.name}
              onChange={({ detail }) => onChange({ ...info, name: detail.value })}
            />
          </Hotspot>
        </FormField>
        <FormField
          description={
            // TODO add link to examples
            // TODO add link to subscription page
            'Type up your own custom theme, or just let the AI decide. This will guide the AI to generate a themed escape room. For example, you could use the theme "Space Ship" and the AI would generate a space ship themed escape room. Be as creative as you want!' +
            (themes.includes('custom') ? '' : ' (upgrade to AI Escape Plus+ to use custom themes)')
          }
          label="Theme"
        >
          <Hotspot hotspotId="room-theme" side="right">
            <Textarea
              disabled={!themes.includes('custom')}
              placeholder="A deep, dark dungeon..."
              rows={5}
              value={info.theme}
              autoComplete
              spellcheck
              onChange={({ detail }) => onChange({ ...info, theme: detail.value })}
            />
          </Hotspot>
        </FormField>
        <FormField
          description="Allows other users to watch you play, and allows you to share the room with others. Also includes room events in the public feed, so others can like when you escape! You can always change this later!"
          label="Visibility"
        >
          <Toggle
            checked={info.public}
            onChange={({ detail }) => onChange({ ...info, public: detail.checked })}
          >
            <Hotspot hotspotId="room-visibility" side="right">
              {info.public ? 'Public' : 'Private'}
            </Hotspot>
          </Toggle>
        </FormField>
        {/* <FormField description="Add any notes you want to remember about this room." label="Notes">
          <Textarea
            value={info.notes}
            onChange={({ detail }) => onChange({ ...info, notes: detail.value })}
          />
        </FormField> */}
      </SpaceBetween>
    </Container>
  );
}
