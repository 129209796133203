import {
  Container,
  Header,
  AppLayout,
  ContentLayout,
  SpaceBetween,
  Grid,
  Button,
  Toggle,
  Form,
  Alert,
  FormField,
  StatusIndicator,
} from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApi, useApiNoBody } from '../../common/api';
import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../common/navigation';
import { UserEmail } from '../../common/types';

export default function AccountEmail() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Account', href: '/account' },
            { text: 'Email', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Your Email Preferences</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 4,
                  s: 4,
                },
              },
            ]}
          >
            <EmailSettings />
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}

export function EmailSettings() {
  const [currentEmail, setCurrentEmail] = useState<UserEmail>();
  const [nextEmail, setNextEmail] = useState<UserEmail>();

  const changed =
    currentEmail?.email_monthly !== nextEmail?.email_monthly ||
    currentEmail?.email_promotions !== nextEmail?.email_promotions ||
    currentEmail?.email_news !== nextEmail?.email_news ||
    currentEmail?.email_updates !== nextEmail?.email_updates;

  const [getApi, getError, getLoading] = useApiNoBody<UserEmail>(
    '/user/email',
    'GET',
    (response) => {
      setCurrentEmail(response);
      setNextEmail(response);
    },
  );

  const [updateApi, error, loading] = useApi<UserEmail, UserEmail>(
    '/user/email',
    'POST',
    (response) => {
      setCurrentEmail(response);
      setNextEmail(response);
    },
  );

  const navigate = useNavigate();

  const load = async () => {
    await getApi();
  };

  useEffect(() => {
    load();
  }, []);

  const update = async () => {
    if (!changed) {
      return;
    }

    await updateApi(nextEmail);
  };

  return getError ? (
    <Alert header="Email Preferences Error" statusIconAriaLabel="Error" type="error">
      {getError}
    </Alert>
  ) : !nextEmail ? (
    <StatusIndicator type="loading">Loading...</StatusIndicator>
  ) : (
    <form onSubmit={(event) => event.preventDefault()}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={() => {
                navigate(`/account`);
              }}
            >
              Cancel
            </Button>
            <Button disabled={!changed} loading={loading} variant="primary" onClick={update}>
              Update
            </Button>
          </SpaceBetween>
        }
        errorText={error}
      >
        <SpaceBetween size="l">
          <Container header={<Header variant="h2">Update Email Preferences</Header>}>
            <SpaceBetween size="l">
              <FormField>
                <Toggle
                  checked={nextEmail.email_news}
                  description="Receive emails about news surrounding AI Escape."
                  disabled={loading}
                  onChange={({ detail }) =>
                    setNextEmail({ ...nextEmail, email_news: detail.checked })
                  }
                >
                  News
                </Toggle>
              </FormField>
              <FormField>
                <Toggle
                  checked={nextEmail.email_updates}
                  description="Receive updates about new features and improvements to AI Escape."
                  disabled={loading}
                  onChange={({ detail }) =>
                    setNextEmail({ ...nextEmail, email_updates: detail.checked })
                  }
                >
                  Updates
                </Toggle>
              </FormField>
              <FormField>
                <Toggle
                  checked={nextEmail.email_monthly}
                  description="Receive monthly emails about the coolest things happening in the AI Escape community, such as amazing escape rooms and creative solutions to puzzles."
                  disabled={loading}
                  onChange={({ detail }) =>
                    setNextEmail({ ...nextEmail, email_monthly: detail.checked })
                  }
                >
                  Monthly Community Contributions
                </Toggle>
              </FormField>
              <FormField>
                <Toggle
                  checked={nextEmail.email_promotions}
                  description="Receive AI Escape promotions and deals on AI Escape products and services."
                  disabled={loading}
                  onChange={({ detail }) =>
                    setNextEmail({ ...nextEmail, email_promotions: detail.checked })
                  }
                >
                  Promotions & Deals
                </Toggle>
              </FormField>
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </Form>
    </form>
  );
}
