import { Button, SpaceBetween } from '@cloudscape-design/components';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApiNoBodyNoResponse } from '../common/api';
import UserContext from '../common/user';

export type LikeEventProps = {
  eventId: string;
  eventLikes: number;
  liked: boolean;
};

export function LikeEvent(props: LikeEventProps) {
  const roomId = props.eventId;

  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [likes, setLikes] = useState<number>(props.eventLikes);
  const [liked, setLiked] = useState<boolean>(props.liked);
  const [likeApi, likeError, likeLoading] = useApiNoBodyNoResponse(`/feed/${roomId}/like`, 'POST');
  const [unlikeApi, unlikeError, unlikeLoading] = useApiNoBodyNoResponse(
    `/feed/${roomId}/like`,
    'DELETE',
  );

  const loading = likeLoading || unlikeLoading;
  // TODO display error
  const error = likeError || unlikeError;

  const like = async () => {
    if (loading) return;

    if (!user) {
      const redirect = encodeURIComponent(window.location.pathname + window.location.search);
      navigate('/login?redirect=' + redirect);

      return;
    }

    const oldLiked = liked;
    const oldLikes = likes;

    setLiked(!liked);

    if (oldLiked) {
      setLikes((likes) => (likes === 0 ? 0 : likes - 1));
      const result = await unlikeApi();

      if (result.error) {
        setLiked(oldLiked);
        setLikes(oldLikes);
      }
    } else {
      setLikes(likes + 1);
      const result = await likeApi();

      if (result.error) {
        setLiked(oldLiked);
        setLikes(oldLikes);
      }
    }
  };

  return (
    <SpaceBetween direction="horizontal" size="m">
      <Button
        iconAlt="Like this event"
        iconName={liked ? 'thumbs-up-filled' : 'thumbs-up'}
        variant="link"
        onClick={like}
      >
        {likes.toLocaleString()}
      </Button>
    </SpaceBetween>
  );
}
