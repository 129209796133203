import { Box, Spinner } from '@cloudscape-design/components';
import { useContext } from 'react';

import UserContext from '../../common/user';
import { CreditsLink } from '../../credits/link';
import { SubscriptionLink } from '../../subscription/link';
import { plusCredits } from '../../common/settings';

export type RoomCostProps = {
  loading: boolean;
  error?: string;
  action?: string;
};

export function RoomCost(props: RoomCostProps) {
  const { user, credits } = useContext(UserContext);

  const action = props.action || 'create';

  return (
    <Box variant="p">
      This room will cost 1 credit to {action}, and you have{' '}
      {props.loading || props.error ? (
        <Spinner />
      ) : credits === 1 ? (
        '1 credit'
      ) : (
        `${credits.toLocaleString()} credits`
      )}{' '}
      remaining. Consider <CreditsLink />
      {user?.subscription ? null : (
        <Box variant="span">
          , or consider subscribing to <SubscriptionLink /> for {plusCredits} credits per month and
          much more!
        </Box>
      )}
    </Box>
  );
}
