import { Box, Link } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

export function SubscriptionLink() {
  const navigate = useNavigate();

  return (
    <Link
      href="/subscription"
      onFollow={(e) => {
        e.preventDefault();
        navigate('/subscription');
      }}
    >
      <Box color="inherit" variant="strong">
        AI Escape Plus+
      </Box>
    </Link>
  );
}
