import { TutorialPanelProps } from '@cloudscape-design/components';

export type User = {
  id: string;
  email: string;
  given_name: string;
  family_name: string;
  birthdate: string;
  sessions: string[];
};

export type UserSignup = {
  email?: string;
  password: string;
  given_name: string;
  family_name: string;
  birthdate: string;
  code?: string;
};
export type UserUpdate = {
  password?: string;
  given_name?: string;
  family_name?: string;
  birthdate?: string;
};

export type CreditsResponse = {
  credits: number;
  max_credits: number;
  credited_at: string;
  next_credited_at: string;
};

export type UserSubscription = {
  id: string;
  role: string;
  created_at: string;
  expires_at: string;
  renew: boolean;
};

export type UserSubscriptionUpdate = {
  renew: boolean;
};

export type UserEmail = {
  email_news: boolean;
  email_promotions: boolean;
  email_updates: boolean;
  email_monthly: boolean;
};

export type CreditsPurchaseRequest = {
  credits: number;
};

export type CreditsPurchaseResponse = {
  session_url: string;
};
export type SubscriptionPurchaseResponse = {
  session_url: string;
};

export type CreditsPurchaseStatusRequest = {
  session_id: string;
};

export type SubscriptionPurchaseStatusRequest = {
  session_id: string;
};

export type SubscriptionPurchaseStatusResponse = {
  active: boolean;
};

export type CreditsPurchaseStatusResponse = {
  purchased: number;
  credits: number;
};

export type UserPasswordResetChange = {
  email: string;
  code: string;
  password: string;
};

export type UserPasswordReset = {
  email: string;
};

export type UserInvite = {
  email: string;
  roles?: string[];
};

export type UserInviteResponse = {
  id: string;
  email: string;
  code: string;
  // roles: string[];
  redeemed: boolean;
  sent_at: string;
  redeemed_at?: string;
};

export type WaitlistStatus = 'pending' | 'approved' | 'rejected' | 'unknown';

export type SignupStatus = 'pending' | 'approved' | 'unknown';

export type UserWaitlistResponse = {
  id: string;
  email: string;
  status: WaitlistStatus;
  joined_at: string;
  reviewed_at?: string;
};

export type SignupResponse = {
  status: SignupStatus;
};

export type UserLogin = {
  email: string;
  password: string;
};

export type EmailRequest = {
  email: string;
};

export type UserFeedback = {
  title: string;
  body: string;
};

export type UserResponse = {
  id: string;
  token: string; // JWT token with User data
  refresh_token: string;
};

export type TokenRefreshResponse = {
  id: string;
  token: string;
};

export type UserListResponse = {
  id: string;
  uuid: string;
  email: string;
  given_name: string;
  family_name: string;
  birthdate: string;
  credits: number;
  created_at: string;
  roles: string[];
  sessions: number;
  subscription: UserSubscription | null;
};

export type Role = 'basic' | 'advanced' | 'admin';
export type RoleStatus = {
  name: Role;
  enabled: boolean;
  display: string;
};

export const ROLE_INFO: RoleStatus[] = [
  { name: 'basic', enabled: true, display: 'Basic' },
  { name: 'advanced', enabled: false, display: 'Testing' },
  { name: 'admin', enabled: false, display: 'Admin' },
];
export type RoleUpdateRequest = {
  roles: Role[];
};
export type UserTransactionResponse = {
  id: string;
  user_id: string;
  uuid: string;
  price: string;
  type: string;
  units: number;
  invoice_id: string;
  created_at: string;
};

export type WaitlistResponse = {
  status: WaitlistStatus;
};

export type UserTokenInfo = {
  id: string;
  uuid: string;
  type: string;
  exp: number;
  email: string;
  given_name: string;
  family_name: string;
  birthdate: string;
  roles: string[];
  permissions: string[];
  subscription: UserSubscription | null;
};

export type AuthRole = {
  id: number;
  name: string;
  permissions?: string[];
};

export type AuthPermission = {
  id: number;
  name: string;
};

export type TutorialWithDetails = TutorialPanelProps.Tutorial & {
  id: string;
  path: string;
};
