import { useContext } from 'react';
import { Header, AppLayout, ContentLayout, Grid } from '@cloudscape-design/components';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';

import { JoinSignup } from './join';

export default function Signup() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Sign Up', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Sign Up for AI Escape</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  xs: 6,
                },
              },
            ]}
          >
            <JoinSignup />
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
