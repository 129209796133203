import {
  Box,
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  Spinner,
  Button,
  Modal,
  SpaceBetween,
  Form,
  FormField,
  Input,
  Alert,
} from '@cloudscape-design/components';
import { useParams } from 'react-router-dom';
import { createRef, useContext, useEffect, useState } from 'react';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import { ScrollPosition } from 'react-lazy-load-image-component';

import { Breadcrumbs, LocalNavigation, NavigationContext } from '../../common/navigation';
import { useApi, useApiNoBody, useApiNoBodyNoResponse, useApiNoResponse } from '../../common/api';
import UserContext from '../../common/user';
import { ProfileName } from '../../profile/name';
import { parseCaption } from '../../common/caption';

import {
  EscapeRoomResponse,
  EscapeRoomSessionEvent,
  EscapeRoomStatus,
  EscapeRoomUpdate,
  EscapeTurn,
  EscapeTurnRequest,
} from './types';
import { RoomSettings } from './info';
import { RoomFeedback } from './feedback';
import { RoomChatBar } from './chatbar';
import { EscapeChatTurn } from './turn';
import { RoomActions } from './actions';

export type RoomProps = {
  scrollPosition: ScrollPosition;
};

export function Room(props: RoomProps) {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const params = useParams();
  // Room UUID
  const roomId = params.roomId ?? '';
  const [room, setRoom] = useState<EscapeRoomStatus>();

  const { user } = useContext(UserContext);
  const userRoom = user && room ? user.id === room.user_id : false;
  const [newName, setNewName] = useState<string>('');
  const [autoPlay, setAutoPlay] = useState(false);
  const [messages, setMessages] = useState<EscapeTurn[]>([]);

  const [sessionApi, sessionError, sessionLoading] = useApiNoBody<EscapeRoomStatus>(
    `/session/${roomId}/lookup`,
    'GET',
    setRoom,
  );
  const [updateOpen, setUpdateOpen] = useState<boolean>(false);
  const [updateApi, updateErr, updateLoading] = useApiNoResponse<EscapeRoomUpdate>(
    '/session',
    'POST',
  );
  const [updateError, setUpdateError] = useState<string>('');
  const chatBottomRef = createRef<HTMLDivElement>();
  const escaped = messages[messages.length - 1]?.escaped;
  const [hasEscaped, setHasEscaped] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const [undoApi] = useApiNoBodyNoResponse('/session', 'POST');
  const [redoApi] = useApi<EscapeTurnRequest, EscapeRoomResponse>('/session', 'POST');

  const [undoVisible, setUndoVisible] = useState(false);
  const [redoVisible, setRedoVisible] = useState(false);
  const [undoIndex, setUndoIndex] = useState(0);
  const [redoIndex, setRedoIndex] = useState(0);
  const [redos, setRedos] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const imageMessages = messages.filter((m) => m.image_url);

  const fetchRoom = async () => {
    await sessionApi();
  };

  useEffect(() => {
    if (room) {
      setNewName(room.name);
      setAutoPlay(room.auto_play);
      setHasEscaped(room.escaped);
      setMessages(
        room.messages.map((m, idx) => ({
          ...m,
          autoPlay: autoPlay && userRoom && idx === 0 && room.messages.length === 1,
        })),
      );
      setRedos(room.redos);
    }
  }, [room]);

  useEffect(() => {
    if (roomId) {
      fetchRoom();
    }
  }, [roomId]);

  const editName = async () => {
    if (!room || !user || !userRoom || updateLoading) return;
    setUpdateError('');

    if (newName === room.name) {
      setUpdateError('Name must be different');

      return;
    }

    if (newName.length === 0) {
      setUpdateError('Name must not be empty');

      return;
    }

    const results = await updateApi(
      {
        name: newName,
      },
      `${room.id}`,
    );

    if (!results.error) {
      setRoom({
        ...room,
        name: newName,
      });
      setUpdateOpen(false);
    } else {
      setUpdateError(results.error);
    }
  };

  const undoRequest = async (index: number) => {
    if (loading) return;

    if (redos <= 0) return;
    setLoading(true);
    setError('');
    // Undo means we remove the message and all messages after it
    // This only occurs on user messages
    const message = messages[index];
    const oldMessages = [...messages];
    const newMessages = messages.slice(0, index);
    setMessages(newMessages);
    const result = await undoApi(undefined, `${room?.id}/messages/${message.id}/undo`);

    if (!result.error) {
      setError('');
      setRedos(redos - 1);
    } else {
      setMessages(oldMessages);
      setError(result.error);
    }
    setLoading(false);
  };

  const redoRequest = async (index: number) => {
    if (loading) return;

    if (redos <= 0) return;
    setLoading(true);
    setError('');
    // Redo is like undo, but we keep re-generate the next message
    const message = messages[index - 1];
    const oldMessages = [...messages];
    const newMessages = messages.slice(0, index);
    setMessages(newMessages);
    const response = await redoApi(
      {
        content: message.content,
        role: 'user',
      },
      `${room?.id}/messages/${message.id}/redo`,
    );

    if (response.error) {
      setMessages(oldMessages);
      setError(response.error);
    } else if (response.data) {
      const newMessage: EscapeTurn = {
        id: response.data.id,
        role: 'assistant',
        content: response.data.response,
        image_url: response.data.image_url,
        caption: parseCaption(response.data.image_prompt),
        escaped: response.data.escaped,
        timestamp: response.data.timestamp,
        token_usage: response.data.token_usage,
        model_duration: response.data.model_duration,
        image_duration: response.data.image_duration,
        autoPlay,
      };
      // need to use the id from the response
      message.id = newMessage.id;
      const responseMessages = [...newMessages, newMessage];
      setMessages(responseMessages);
      setError('');
      setRedos(redos - 1);
    }
    setLoading(false);
  };

  const undo = async (index: number) => {
    if (index === messages.length - 2) {
      await undoRequest(index);
    } else {
      setUndoIndex(index);
      setUndoVisible(true);
    }
  };

  const redo = async (index: number) => {
    if (index === messages.length - 1) {
      await redoRequest(index);
    } else {
      setRedoIndex(index);
      setRedoVisible(true);
    }
  };

  useEffect(() => {
    if (userRoom && !escaped && !scrolling) {
      setScrolling(true);
      chatBottomRef.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'start',
      });
      setScrolling(false);
    }
  }, [messages]);

  // TODO eventually make sure we can collect / update feedback if the user leaves the page
  useEffect(() => {
    if (!userRoom) return;

    if (escaped && !hasEscaped) {
      setHasEscaped(true);
      // Wait 10 seconds before showing the feedback popup
      // to give the user time to read the escape message
      const timer = setTimeout(() => {
        setFeedbackOpen(true);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [messages]);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Rooms', href: '/rooms' },
            {
              text: room ? room.name : sessionLoading ? '' : 'Error',
              href: '',
            },
          ]}
        />
      }
      className="app-layout"
      content={
        room ? (
          <ContentLayout
            header={
              <Grid
                gridDefinition={[
                  { colspan: { default: 12, m: 10 } },
                  { colspan: { default: 12, m: 10 } },
                ]}
              >
                <Header
                  actions={
                    userRoom ? null : (
                      <RoomActions
                        event={
                          {
                            type: 'created',
                            id: '',
                            uuid: '',
                            timestamp: room.created_at,
                            title: room.name,
                            text: room.messages[0].content,
                            image_url: room.messages[0].image_url,
                            caption: parseCaption(room.messages[0].caption),
                            text_timestamp: room.created_at,
                            likes: 0,
                            liked: false,
                            session_uuid: roomId,
                          } as EscapeRoomSessionEvent
                        }
                        roomId={room.uuid}
                        scrollPosition={props.scrollPosition}
                        insideRoom
                      />
                    )
                  }
                  description={
                    <Box color="inherit" variant="span">
                      Created by <ProfileName user={room.user} hideLink />
                    </Box>
                  }
                  info={
                    userRoom ? <Button iconName="edit" onClick={() => setUpdateOpen(true)} /> : null
                  }
                  variant="h1"
                >
                  {room.name}
                </Header>

                <RoomSettings
                  autoPlay={autoPlay}
                  loading={loading}
                  messages={messages}
                  room={room}
                  setAutoPlay={setAutoPlay}
                  setError={setError}
                  setLoading={setLoading}
                  setMessages={setMessages}
                  userRoom={userRoom}
                />
              </Grid>
            }
          >
            <Grid gridDefinition={[{ colspan: { default: 12, m: 10 } }]}>
              <SpaceBetween size="m">
                {messages.map((item, index) => (
                  <div key={`turn-div-${index}`}>
                    {index === messages.length - 1 ? (
                      <div key="bottom" ref={chatBottomRef} />
                    ) : null}
                    <EscapeChatTurn
                      key={`turn-${index}`}
                      escaped={escaped ?? false}
                      index={index}
                      loading={loading}
                      message={item}
                      redo={redo}
                      redos={redos}
                      roomId={room.id}
                      scrollPosition={props.scrollPosition}
                      undo={undo}
                      userRoom={userRoom}
                      onLoad={
                        item.image_url === imageMessages[imageMessages.length - 1].image_url
                          ? () => {
                              if (userRoom && !escaped && !scrolling) {
                                setScrolling(true);
                                chatBottomRef.current?.scrollIntoView({
                                  block: 'start',
                                  behavior: 'smooth',
                                  inline: 'start',
                                });
                                setScrolling(false);
                              }
                            }
                          : undefined
                      }
                    />
                  </div>
                ))}
                {/* TODO make this floating */}
                {userRoom && !escaped ? (
                  <RoomChatBar
                    autoPlay={autoPlay}
                    error={error}
                    loading={loading}
                    messages={messages}
                    room={room}
                    setError={setError}
                    setLoading={setLoading}
                    setMessages={setMessages}
                  />
                ) : null}
                <div
                  style={{
                    height: '100px',
                  }}
                />
                <Modal
                  closeAriaLabel="Cancel"
                  footer={
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => setUndoVisible(false)}>
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={async () => {
                            setUndoVisible(false);
                            await undoRequest(undoIndex);
                          }}
                        >
                          Redo
                        </Button>
                      </SpaceBetween>
                    </Box>
                  }
                  header="Redo Step"
                  visible={undoVisible}
                  onDismiss={() => setUndoVisible(false)}
                >
                  <Header variant="h3">Redo Multiple Steps?</Header>
                  <Box variant="p">
                    Are you sure you want to redo this step and all steps past this step?
                  </Box>
                </Modal>
                <Modal
                  closeAriaLabel="Cancel"
                  footer={
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => setRedoVisible(false)}>
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={async () => {
                            setRedoVisible(false);
                            await redoRequest(redoIndex);
                          }}
                        >
                          Redo
                        </Button>
                      </SpaceBetween>
                    </Box>
                  }
                  header="Redo Step"
                  visible={redoVisible}
                  onDismiss={() => setRedoVisible(false)}
                >
                  <Header variant="h3">Redo Multiple Steps?</Header>
                  <Box variant="p">
                    Are you sure you want to redo this step and all steps past this step?
                  </Box>
                </Modal>
                <RoomFeedback
                  feedbackOpen={feedbackOpen}
                  room={room}
                  setFeedbackOpen={setFeedbackOpen}
                />
              </SpaceBetween>
            </Grid>
            <Modal
              closeAriaLabel="Cancel"
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="link" onClick={() => setUpdateOpen(false)}>
                      Cancel
                    </Button>
                    <Button loading={updateLoading} variant="primary" onClick={editName}>
                      Save
                    </Button>
                  </SpaceBetween>
                </Box>
              }
              header="Change Name"
              visible={updateOpen}
              onDismiss={() => setUpdateOpen(false)}
            >
              <form onSubmit={(event) => event.preventDefault()}>
                <Form errorText={updateError}>
                  <FormField description="Change room name." label="Room Name">
                    <Input
                      disabled={updateLoading}
                      inputMode="text"
                      type="text"
                      value={newName}
                      onChange={({ detail }) => setNewName(detail.value)}
                    />
                  </FormField>
                </Form>
              </form>
            </Modal>
          </ContentLayout>
        ) : sessionLoading ? (
          <ContentLayout header={<Header variant="h2">Loading</Header>}>
            <Spinner size="large" />
          </ContentLayout>
        ) : (
          <ContentLayout header={<Header variant="h2">Error</Header>}>
            <Alert
              action={
                <Button
                  loading={sessionLoading}
                  variant="normal"
                  onClick={() => {
                    fetchRoom();
                  }}
                >
                  Retry
                </Button>
              }
              header="Room Error"
              statusIconAriaLabel="Error"
              type="error"
            >
              {sessionError}
            </Alert>
          </ContentLayout>
        )
      }
      contentType="default"
      headerSelector=".top-navigation"
      maxContentWidth={Number.MAX_VALUE}
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}

export default trackWindowScroll(Room);
