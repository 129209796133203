export function parseCaption(text?: string): string | undefined {
  let caption = text;

  if (caption) {
    if (caption.startsWith('{"prompt"')) {
      try {
        caption = JSON.parse(caption).prompt;
      } catch (e) {
        // do nothing
      }
    }
  }

  return caption;
}
