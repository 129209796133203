import Link, { LinkProps } from '@cloudscape-design/components/link';

export type InfoLinkProps = {
  id?: string;
  ariaLabel?: string;
  onFollow: LinkProps['onFollow'];
}
export function InfoLink(props: InfoLinkProps) {
  return (
    <Link variant="info" {...props}>
      Info
    </Link>
  );
}
