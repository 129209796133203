import { Link } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

export type RoomLinkProps = {
  roomId?: string;
  title: string;
};

export function RoomLink(props: RoomLinkProps) {
  const navigate = useNavigate();

  if (!props.roomId) {
    return null;
  }

  return (
    <Link
      fontSize="inherit"
      href={`/rooms/${props.roomId}`}
      variant="secondary"
      onFollow={(e) => {
        e.preventDefault();
        navigate(`/rooms/${props.roomId}`);
      }}
    >
      {props.title}
    </Link>
  );
}
