import { Container, Header, SpaceBetween } from '@cloudscape-design/components';

import { EscapeRoomEvent } from '../rooms/room/types';
import { ProfileName } from '../profile/name';

import { LikeEvent } from './like';

export type EscapeEventProps = {
  event: EscapeRoomEvent;
  title: string;
  children: React.ReactNode;
};

export function EscapeEvent(props: EscapeEventProps) {
  const event = props.event;
  const user = event.user;

  return (
    <Container
      header={
        <Header description={new Date(event.timestamp + 'Z').toLocaleString()} variant="h2">
          <ProfileName user={user} /> {props.title}
        </Header>
      }
    >
      <SpaceBetween direction="vertical" size="s">
        {props.children}
        <LikeEvent eventId={event.id} eventLikes={event.likes} liked={event.liked} />
      </SpaceBetween>
    </Container>
  );
}
