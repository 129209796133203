import { PropertyFilterQuery } from '@cloudscape-design/collection-hooks';

import { UserTransactionResponse } from '../../../common/types';

function filterOperator(a: boolean, b: boolean, operator: 'and' | 'or') {
  return operator === 'and' ? a && b : a || b;
}

export function filterTransactions(
  transaction: UserTransactionResponse,
  query: PropertyFilterQuery,
) {
  let matches = query.operation === 'and';

  for (const token of query.tokens) {
    if (token.propertyKey === 'type') {
      if (token.operator === '=') {
        matches = filterOperator(
          matches,
          transaction.type.toLowerCase() === token.value.toLowerCase(),
          query.operation,
        );
      } else if (token.operator === '!:') {
        matches = filterOperator(
          matches,
          transaction.type.toLowerCase() !== token.value.toLowerCase(),
          query.operation,
        );
      }
    }
  }

  return matches;
}
