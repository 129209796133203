import { createContext } from 'react';

import { TutorialWithDetails } from './types';

export type TutorialContextInfo = {
  tutorials: ReadonlyArray<TutorialWithDetails>;
  setTutorials: (tutorials: ReadonlyArray<TutorialWithDetails>) => void;
  currentTutorial: TutorialWithDetails | null;
  setCurrentTutorial: (tutorial: TutorialWithDetails | null) => void;
  loadingTutorials: boolean;
  setLoadingTutorials: (loading: boolean) => void;
};

const TutorialContext = createContext<TutorialContextInfo>({
  currentTutorial: null,
  setCurrentTutorial: (tutorial: TutorialWithDetails | null) => {
    return;
  },
  tutorials: [],
  setTutorials: (tutorials: ReadonlyArray<TutorialWithDetails>) => {
    return;
  },
  loadingTutorials: true,
  setLoadingTutorials: (loading: boolean) => {
    return;
  },
});

export default TutorialContext;
