import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppLayout, ContentLayout } from '@cloudscape-design/components';

import { LocalNavigation, NavigationContext } from '../common/navigation';
import UserContext from '../common/user';

export default function Logout() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  useEffect(() => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/');
  }, []);

  return (
    <AppLayout
      className="app-layout"
      content={<ContentLayout />}
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
