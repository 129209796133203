import { useContext, useEffect, useState } from 'react';
import {
  Container,
  Header,
  AppLayout,
  ContentLayout,
  Form,
  SpaceBetween,
  Button,
  FormField,
  Input,
  DatePicker,
  Grid,
  Box,
  Link,
} from '@cloudscape-design/components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import jwt from 'jwt-decode';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../common/navigation';
import { UserResponse, UserSignup, UserTokenInfo } from '../../common/types';
import UserContext from '../../common/user';
import { useApi } from '../../common/api';

function calculateAge(dob: Date) {
  const today = new Date();
  const birthDate = new Date(dob);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

export default function Signup() {
  const navigate = useNavigate();
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const [queryParameters] = useSearchParams();

  const email = queryParameters.get('email') ?? undefined;
  const code = queryParameters.get('code') ?? undefined;

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [givenName, setGivenName] = useState<string>('');
  const [familyName, setFamilyName] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>('');

  const [passwordErrorText, setPasswordErrorText] = useState<string>('');
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState<string>('');
  const [givenNameErrorText, setGivenNameErrorText] = useState<string>('');
  const [familyNameErrorText, setFamilyNameErrorText] = useState<string>('');
  const [birthdateErrorText, setBirthdateErrorText] = useState<string>('');

  useEffect(() => {
    if (!email || !code) {
      navigate('/signup');
    }
  }, []);

  const { setUser } = useContext(UserContext);

  const [signupApi, error, loading] = useApi<UserSignup, UserResponse>(
    '/auth/signup',
    'POST',
    (response) => {
      const token = response.token;
      const refresh_token = response.refresh_token;
      localStorage.setItem('token', token);
      localStorage.setItem('refresh_token', refresh_token);
      setUser(token ? (jwt(token) as UserTokenInfo) : null);

      navigate(`/rooms`);
    },
  );

  const signup = async () => {
    let formError = false;

    if (!password) {
      setPasswordErrorText('Password is required');
      formError = true;
    } else if (password.length < 12) {
      setPasswordErrorText('Password must be at least 12 characters');
      formError = true;
    } else if (password.toUpperCase() === password) {
      setPasswordErrorText('Password must contain at least one lowercase letter');
      formError = true;
    } else if (password.toLowerCase() === password) {
      setPasswordErrorText('Password must contain at least one uppercase letter');
      formError = true;
    } else if (password.search(/[0-9]/) < 0) {
      setPasswordErrorText('Password must contain at least one number');
      formError = true;
    } else if (password.search(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/) < 0) {
      setPasswordErrorText('Password must contain at least one special character');
      formError = true;
    } else {
      setPasswordErrorText('');
    }

    if (!confirmPassword) {
      setConfirmPasswordErrorText('Confirm password is required');
      formError = true;
    } else if (password !== confirmPassword) {
      setConfirmPasswordErrorText('Passwords do not match');
      formError = true;
    } else {
      setConfirmPasswordErrorText('');
    }

    if (!givenName) {
      setGivenNameErrorText('Given name is required');
      formError = true;
    } else {
      setGivenNameErrorText('');
    }

    if (!familyName) {
      setFamilyNameErrorText('Family name is required');
      formError = true;
    } else {
      setFamilyNameErrorText('');
    }

    if (!birthdate) {
      setBirthdateErrorText('Birth date is required');
      formError = true;
    } else {
      const age = calculateAge(new Date(birthdate));

      if (age < 18) {
        setBirthdateErrorText('You must be at least 18 years old to sign up');
        formError = true;
      } else {
        setBirthdateErrorText('');
      }
    }

    if (formError) {
      return;
    }

    const info: UserSignup = {
      email,
      password,
      given_name: givenName,
      family_name: familyName,
      birthdate,
      code,
    };

    await signupApi(info);
  };

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Sign Up', href: '/signup' },
            { text: 'Confirmation', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Sign Up for AI Escape</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  xs: 6,
                },
              },
            ]}
          >
            <form onSubmit={(event) => event.preventDefault()}>
              <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="link"
                      onClick={() => {
                        navigate(`/`);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button disabled={loading} loading={loading} variant="primary" onClick={signup}>
                      Sign Up
                    </Button>
                  </SpaceBetween>
                }
                errorText={error}
              >
                <SpaceBetween size="l">
                  <Container header={<Header variant="h2">Create an Account</Header>}>
                    <Box variant="p">
                      By creating an account, you agree to our{' '}
                      <Link
                        href="/terms"
                        onFollow={(e) => {
                          e.preventDefault();
                          navigate('/terms');
                        }}
                      >
                        Terms of Service
                      </Link>{' '}
                      and{' '}
                      <Link
                        href="/privacy"
                        onFollow={(e) => {
                          e.preventDefault();
                          navigate('/privacy');
                        }}
                      >
                        Privacy Policy
                      </Link>
                      .
                    </Box>
                    <SpaceBetween size="l">
                      <FormField description="Your email address." label="Email">
                        <Input disabled={loading} type="email" value={email ?? ''} readOnly />
                      </FormField>
                      <FormField
                        description="Enter your first name / given name."
                        errorText={givenNameErrorText}
                        label="Given Name"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Enter given name..."
                          type="text"
                          value={givenName}
                          onChange={({ detail: { value } }) => setGivenName(value)}
                        />
                      </FormField>
                      <FormField
                        description="Enter your last name / family name."
                        errorText={familyNameErrorText}
                        label="Family Name"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Enter family name..."
                          type="text"
                          value={familyName}
                          onChange={({ detail: { value } }) => setFamilyName(value)}
                        />
                      </FormField>
                      <FormField
                        description="Enter a password for your account. Your password must be at least 12 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character."
                        errorText={passwordErrorText}
                        label="Password"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Enter password..."
                          type="password"
                          value={password}
                          onChange={({ detail: { value } }) => setPassword(value)}
                        />
                      </FormField>
                      <FormField
                        description="Re-enter your password to confirm."
                        errorText={confirmPasswordErrorText}
                        label="Confirm Password"
                      >
                        <Input
                          disabled={loading}
                          placeholder="Re-enter password..."
                          type="password"
                          value={confirmPassword}
                          onChange={({ detail: { value } }) => setConfirmPassword(value)}
                        />
                      </FormField>
                      <FormField
                        className="date-time-container"
                        constraintText="Use YYYY/MM/DD format."
                        description="Provide your birth date."
                        errorText={birthdateErrorText}
                      >
                        <DatePicker
                          disabled={loading}
                          nextMonthAriaLabel="Next month"
                          openCalendarAriaLabel={(selectedDate) =>
                            'Choose birth date' +
                            (selectedDate ? `, selected date is ${selectedDate}` : '')
                          }
                          placeholder="YYYY/MM/DD"
                          previousMonthAriaLabel="Previous month"
                          todayAriaLabel="Today"
                          value={birthdate}
                          onChange={({ detail: { value } }) => setBirthdate(value)}
                        />
                      </FormField>
                    </SpaceBetween>
                  </Container>
                </SpaceBetween>
              </Form>
            </form>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
