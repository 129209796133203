import {
  Container,
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  Spinner,
  Alert,
  Button,
} from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useApiNoBody } from '../common/api';
import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';
import Feed from '../feed/feed';
import { PublicUser } from '../rooms/room/types';

import { ProfileDetails } from './details';
import { ProfileName } from './name';

export default function ProfilePage() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  const params = useParams();
  // user UUID
  const userId = params.userId ?? '';
  const [user, setUser] = useState<PublicUser>();

  const [userApi, error, loading] = useApiNoBody<PublicUser>(
    `/users/${userId}/profile`,
    'GET',
    setUser,
  );

  const fetchUser = async () => {
    await userApi();
  };

  useEffect(() => {
    if (userId) {
      fetchUser();
    }
  }, [userId]);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Profile', href: '' },
            {
              text: userId,
              href: '',
            },
          ]}
        />
      }
      className="app-layout"
      content={
        user ? (
          <ContentLayout
            header={
              <Header variant="h1">
                <ProfileName user={user} hideLink />
              </Header>
            }
          >
            <Grid
              gridDefinition={[
                { colspan: { default: 12, s: 4 } },
                { colspan: { default: 12, s: 8 } },
              ]}
            >
              <ProfileDetails user={user} />
              <Feed userId={user.id} />
            </Grid>
          </ContentLayout>
        ) : loading ? (
          <ContentLayout header={<Header variant="h2">Loading</Header>}>
            <Grid gridDefinition={[{ colspan: { s: 10, default: 12 } }]}>
              <Container>
                <Spinner size="big" />
              </Container>
            </Grid>
          </ContentLayout>
        ) : (
          <ContentLayout header={<Header variant="h2">Error</Header>}>
            <Grid gridDefinition={[{ colspan: { s: 10, default: 12 } }]}>
              <Alert
                action={
                  <Button
                    loading={loading}
                    variant="normal"
                    onClick={() => {
                      fetchUser();
                    }}
                  >
                    Retry
                  </Button>
                }
                header="Profile Error"
                statusIconAriaLabel="Error"
                type="error"
              >
                {error}
              </Alert>
            </Grid>
          </ContentLayout>
        )
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
