import {
  Box,
  Container,
  Header,
  FormField,
  RadioGroup,
  SpaceBetween,
  Input,
  Hotspot,
  ColumnLayout,
} from '@cloudscape-design/components';
import { useContext } from 'react';

import { getPermissionsPrefix } from '../../../common/auth';
import { Difficulty, RoomDifficultyInfo } from '../../../common/room';
import UserContext from '../../../common/user';
import { SubscriptionLink } from '../../../subscription/link';
import { DIFFICULTY_OPTIONS } from '../steps-config';

export type DifficultyInfoStepProps = {
  info: RoomDifficultyInfo;
  onChange: (info: RoomDifficultyInfo) => void;
  difficulty: Difficulty;
  setDifficulty: (difficulty: Difficulty) => void;
};

export default function DifficultyInfoStep(props: DifficultyInfoStepProps) {
  const info = props.info;
  const onChange = props.onChange;
  const { user } = useContext(UserContext);
  const difficulties = getPermissionsPrefix('difficulty.', user);

  return (
    <Container header={<Header variant="h2">Room Difficulty</Header>}>
      <SpaceBetween size="l">
        <Hotspot hotspotId="room-difficulty" side="right">
          <FormField
            description="Select a difficulty for your room. Instructs the AI to build a puzzles at a certain difficulty level."
            errorText={
              difficulties.includes(props.difficulty)
                ? undefined
                : 'Upgrade to AI Escape Plus+ to use this difficulty'
            }
            label="Difficulty"
          >
            <RadioGroup
              items={DIFFICULTY_OPTIONS}
              value={props.difficulty}
              onChange={({ detail }) => {
                props.setDifficulty(detail.value as Difficulty);

                if (difficulties.includes(detail.value as Difficulty)) {
                  onChange({ ...info, difficulty: detail.value as Difficulty });
                }
              }}
            />
          </FormField>
        </Hotspot>
        {user?.subscription ? null : (
          <Box variant="p">
            Consider subscribing to <SubscriptionLink /> for harder difficulties, more puzzles per
            room, and much more!
          </Box>
        )}
      </SpaceBetween>
    </Container>
  );
}
