import { AnnotationContext, Box, TutorialPanelProps } from '@cloudscape-design/components';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import TutorialContext from './tutorials';
import { TutorialWithDetails } from './types';
import UserContext from './user';

export const initialTutorials: TutorialWithDetails[] = [
  {
    id: '0',
    completed: false,
    path: '/rooms/create',
    title: 'Create a room',
    description: <Box variant="p">Create a custom escape room.</Box>,
    // TODO create endpoint to get and update completed status of tutorials.
    completedScreenDescription: <Box variant="p">Completed</Box>,
    tasks: [
      {
        title: 'Room info',
        steps: [
          {
            title: 'Name the room',
            content: (
              <Box variant="p">
                Create a name for your room. This is just for you and other players to remember the
                room.
              </Box>
            ),
            hotspotId: 'room-name',
          },
          {
            title: 'Create a theme',
            content: (
              <Box variant="p">
                Create a custom theme for your escape room! This will guide the AI to generate a
                themed escape room. Be creative! For example, you could use the theme "Thanksgiving
                dinner from the perspective of a turkey trying to escape" and the AI would generate
                a Thanksgiving themed escape room from the perspective of a turkey trying to escape!
              </Box>
            ),
            hotspotId: 'room-theme',
          },
          {
            title: 'Visibility',
            content: (
              <Box variant="p">
                Make your room public or private. Public rooms can be viewed by anyone, and private
                rooms can only be viewed by you.
              </Box>
            ),
            hotspotId: 'room-visibility',
          },
        ],
      },
      {
        title: 'Room difficulty',
        steps: [
          {
            title: 'Select a difficulty',
            content: (
              <Box variant="p">
                Select a difficulty for your room. This provides the AI a general difficulty level
                when creating puzzles.
              </Box>
            ),
            hotspotId: 'room-difficulty',
          },
          {
            title: 'Minimum puzzles',
            content: (
              <Box variant="p">
                Select the minimum number of puzzles for your room. This provides the AI a range of
                possible puzzle configurations to choose from.
              </Box>
            ),
            hotspotId: 'room-puzzles-min',
          },
          {
            title: 'Maximum puzzles',
            content: (
              <Box variant="p">
                Select the maximum number of puzzles for your room. This provides the AI a range of
                possible puzzle configurations to choose from.
              </Box>
            ),
            hotspotId: 'room-puzzles-max',
          },
        ],
      },
      {
        title: 'Choose your AI',
        steps: [
          {
            title: 'Select your chat AI',
            content: (
              <Box variant="p">
                Select the AI that will be used to create the room. This will determine the quality
                and speed of the AI creating the puzzles in the escape room.
              </Box>
            ),
            hotspotId: 'room-chat-ai',
          },
        ],
      },
      {
        title: "Pick your AI's voice",
        steps: [
          {
            title: 'Select your speech AI',
            content: (
              <Box variant="p">
                Select the AI that will be used to translate text to speech and read the puzzles in
                the escape room. This will determine the quality of the AI reading the puzzles in
                the escape room. You can also choose to disable speech.
              </Box>
            ),
            hotspotId: 'room-speech-ai',
          },
          {
            title: 'Choose a voice',
            content: (
              <Box variant="p">
                Pick a voice for your speech AI. You can preview the voice by clicking the play
                button in the room preview.
              </Box>
            ),
            hotspotId: 'room-speech-voice',
          },
        ],
      },
      {
        title: 'Visualize your room',
        steps: [
          {
            title: 'Select your image AI',
            content: (
              <Box variant="p">
                Select the AI that will be used to visualize the room with generated images. This
                will determine the quality of the AI generating images for the escape room.
              </Box>
            ),
            hotspotId: 'room-image-ai',
          },
          {
            title: 'Choose a style',
            content: (
              <Box variant="p">
                Pick a style for the images generated by your image AI. You can preview the style in
                the room preview.
              </Box>
            ),
            hotspotId: 'room-image-style',
          },
        ],
      },
      {
        title: 'Review and create',
        steps: [
          {
            title: 'Review your room',
            content: <Box variant="p">Review your room and make sure everything looks good!</Box>,
            hotspotId: 'room-review',
          },
          {
            title: 'Generate your room',
            content: (
              <Box variant="p">Click the "Create escape room" button to generate your room!</Box>
            ),
            hotspotId: 'room-create',
          },
        ],
      },
    ],
  },
];

export type TutorialAnnotationContextProps = {
  children: React.ReactNode;
};

export function TutorialAnnotationContext(props: TutorialAnnotationContextProps) {
  const { user } = useContext(UserContext);
  const {
    currentTutorial,
    setCurrentTutorial,
    tutorials,
    setTutorials,
    loadingTutorials,
    setLoadingTutorials,
  } = useContext(TutorialContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      return;
    }
    setLoadingTutorials(true);
    // TODO get tutorials from user profile.
    setTutorials(initialTutorials);
    setLoadingTutorials(false);
  }, [user]);

  return (
    <AnnotationContext
      currentTutorial={currentTutorial}
      i18nStrings={{
        stepCounterText: (stepIndex, totalStepCount) =>
          'Step ' + (stepIndex + 1) + '/' + totalStepCount,
        taskTitle: (taskIndex, taskTitle) => 'Task ' + (taskIndex + 1) + ': ' + taskTitle,
        labelHotspot: (openState, stepIndex, totalStepCount) =>
          openState
            ? 'close annotation for step ' + (stepIndex + 1) + ' of ' + totalStepCount
            : 'open annotation for step ' + (stepIndex + 1) + ' of ' + totalStepCount,
        nextButtonText: 'Next',
        previousButtonText: 'Previous',
        finishButtonText: 'Finish',
        labelDismissAnnotation: 'dismiss annotation',
      }}
      onExitTutorial={(e) => {
        // TODO update active tutorial.
        setCurrentTutorial(null);
      }}
      onFinish={(e) => {
        // TODO update completed status of tutorial.
        console.log(e);

        if (!currentTutorial) {
          return;
        }
        currentTutorial.completed = true;
        setCurrentTutorial(null);
      }}
      onStartTutorial={(e) => {
        // TODO update active tutorial.
        // set tutorial completed to false
        e.detail.tutorial.completed = false;
        const tutorial = e.detail.tutorial as TutorialWithDetails;
        setCurrentTutorial(tutorial);
        navigate(tutorial.path);
      }}
    >
      {props.children}
    </AnnotationContext>
  );
}
