import { Box, Link } from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

export function CreditsLink() {
  const navigate = useNavigate();

  return (
    <Link
      href="/credits"
      onFollow={(e) => {
        e.preventDefault();
        navigate('/credits');
      }}
    >
      <Box color="inherit" variant="strong">
        purchasing more credits
      </Box>
    </Link>
  );
}
