import {
  Container,
  Header,
  FormField,
  RadioGroup,
  SpaceBetween,
  Box,
  Select,
  Hotspot,
} from '@cloudscape-design/components';
import { useContext } from 'react';

import { getPermissionsPrefix } from '../../../common/auth';
import { RoomModelInfo, TTSModel } from '../../../common/room';
import UserContext from '../../../common/user';
import { SubscriptionLink } from '../../../subscription/link';
import { TTS_VOICE_OPTIONS } from '../steps-config';

export type TTSInfoStepProps = {
  info: RoomModelInfo;
  onChange: (info: RoomModelInfo) => void;
  tts_voice?: string;
  setTTSVoice: (tts_voice?: string) => void;
};

export default function TTSInfoStep(props: TTSInfoStepProps) {
  const info = props.info;
  const onChange = props.onChange;
  const { user } = useContext(UserContext);
  const userTTSModels = getPermissionsPrefix('tts.models.', user);

  const ttsVoices = userTTSModels.includes('polly-neural') ? TTS_VOICE_OPTIONS : [];
  const userVoices = ttsVoices.map((voice) => voice.value);

  return (
    <Container header={<Header variant="h2">Escape Room Speech AI</Header>}>
      <SpaceBetween size="l">
        <FormField
          description="Select the Text-to-Speech voice read the text for your room."
          errorText={
            !props.tts_voice || userVoices.includes(props.tts_voice) || props.tts_voice === 'none'
              ? undefined
              : 'Upgrade to AI Escape Plus+ to use this text-to-speech model'
          }
          label="AI Voice"
        >
          <Hotspot hotspotId="room-speech-voice" side="right">
            <Select
              filteringType="auto"
              options={TTS_VOICE_OPTIONS}
              selectedOption={TTS_VOICE_OPTIONS.filter((s) => s.value === props.tts_voice)[0]}
              onChange={({ detail }) => {
                props.setTTSVoice(detail.selectedOption.value);

                if (!detail.selectedOption.value) {
                  return;
                }

                if (userVoices.includes(detail.selectedOption.value)) {
                  onChange({
                    ...info,
                    tts_voice: detail.selectedOption.value,
                  });
                }
              }}
            />
          </Hotspot>
        </FormField>
        {user?.subscription ? null : (
          <Box variant="p">
            Consider subscribing to <SubscriptionLink /> for access to the best speech AI, better
            voices, and much more!
          </Box>
        )}
      </SpaceBetween>
    </Container>
  );
}
