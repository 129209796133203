import { useContext } from 'react';
import { Header, AppLayout, ContentLayout, Grid } from '@cloudscape-design/components';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../common/navigation';
import Feed from '../../feed/feed';
import FreeSubscriptionInfo from '../free';

export default function FreeExamples() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Plans', href: '/subscription' },
            { text: 'Free', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Examples of Free Plan Rooms</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 4,
                  s: 4,
                },
              },
              {
                colspan: {
                  default: 12,
                  l: 8,
                  s: 8,
                },
              },
            ]}
          >
            <FreeSubscriptionInfo hideExamples moreInfo />
            <Feed eventType="created" userId="8" hideUsers />
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
