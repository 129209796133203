import { Button, SpaceBetween } from '@cloudscape-design/components';
import { useEffect } from 'react';
import { useAudioRecorder } from 'react-audio-voice-recorder';

export type TurnAudioRecorderProps = {
  audioFile: Blob | null;
  setAudioFile: (audioFile: Blob | null) => void;
  audioDuration: number;
  setAudioDuration: (audioDuration: number) => void;
  loading: boolean;
  setError: (error: string) => void;
};

export function TurnAudioRecorder(props: TurnAudioRecorderProps) {
  const { audioFile, setAudioFile, loading, audioDuration, setAudioDuration, setError } = props;
  const { startRecording, stopRecording, recordingBlob, isRecording, recordingTime } =
    useAudioRecorder(undefined, undefined, {
      mimeType: 'audio/webm',
    });

  useEffect(() => {
    if (audioDuration > 30) {
      setAudioFile(null);
      setAudioDuration(0);
      setError('Audio recording cannot be longer than 30 seconds');

      return;
    }

    if (recordingBlob) {
      setAudioFile(recordingBlob);
    }
  }, [recordingBlob]);

  useEffect(() => {
    if (isRecording) {
      setAudioDuration(recordingTime);

      if (recordingTime > 30) {
        stopRecording();
      }
    }
  }, [recordingTime]);

  return (
    <SpaceBetween direction="horizontal" size="xxs">
      {isRecording ? (
        <div className="audio-recording">
          <Button disabled={loading} iconName="status-stopped" onClick={() => stopRecording()} />
        </div>
      ) : audioFile === null ? (
        <Button
          disabled={loading}
          iconName="microphone"
          onClick={() => {
            setError('');
            setAudioFile(null);
            setAudioDuration(0);
            startRecording();
          }}
        />
      ) : (
        <Button
          disabled={audioFile === null || loading}
          iconName="remove"
          onClick={() => {
            setError('');
            setAudioFile(null);
            setAudioDuration(0);
          }}
        />
      )}
    </SpaceBetween>
  );
}
