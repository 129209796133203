import { useContext, useEffect, useState } from 'react';
import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  Button,
  Grid,
  Link,
  Spinner,
  SpaceBetween,
  Alert,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';
import { CreditsPurchaseRequest, CreditsPurchaseResponse, CreditsResponse } from '../common/types';
import UserContext from '../common/user';
import { useApi, useApiNoBody } from '../common/api';
import { creditCost } from '../common/settings';

export default function CreditsView() {
  const { credits, setCredits } = useContext(UserContext);
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const navigate = useNavigate();
  const [maxCredits, setMaxCredits] = useState<number>(0);
  const [purchaseCredits, setPurchaseCredits] = useState<number>(0);

  const [creditsApi, creditsError, creditsLoading] = useApiNoBody<CreditsResponse>(
    '/user/credits',
    'GET',
    (data) => {
      setCredits(data.credits);
      setMaxCredits(data.max_credits);
    },
  );

  const [purchaseApi, purchaseError, purchaseLoading] = useApi<
    CreditsPurchaseRequest,
    CreditsPurchaseResponse
  >('/user/credits/purchase', 'POST', (data) => {
    window.location.replace(data.session_url);
  });

  const purchase = async (credits: number) => {
    setPurchaseCredits(credits);
    await purchaseApi({
      credits,
    });
  };

  useEffect(() => {
    creditsApi();
  }, []);

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Credits', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">AI Escape Credits</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 4,
                  s: 4,
                },
              },
              {
                colspan: {
                  default: 12,
                  l: 4,
                  s: 4,
                },
              },
            ]}
          >
            <SpaceBetween size="s">
              <Container header={<Header variant="h2">Your Credits</Header>}>
                <Box variant="p">
                  You have{' '}
                  {creditsLoading || creditsError ? (
                    <Spinner />
                  ) : credits === 1 ? (
                    '1 credit'
                  ) : (
                    `${credits.toLocaleString()} credits`
                  )}
                  .
                </Box>
                <Box variant="p">
                  That means you can play{' '}
                  {creditsLoading || creditsError ? (
                    <Spinner />
                  ) : credits === 1 ? (
                    '1 AI Escape room'
                  ) : (
                    `${credits.toLocaleString()} AI Escape rooms`
                  )}
                  ! Each room costs 1 credit, and you can take as long as you want to complete it.
                </Box>
                <Box variant="p">
                  You can only have a maximum of{' '}
                  {creditsLoading || creditsError ? (
                    <Spinner />
                  ) : maxCredits === 1 ? (
                    '1 credit'
                  ) : (
                    `${maxCredits.toLocaleString()} credits`
                  )}{' '}
                  at a time.
                </Box>
                <Box variant="p">
                  <Link
                    href="/rooms/create"
                    variant="secondary"
                    onFollow={(event) => {
                      event.preventDefault();
                      navigate('/rooms/create');
                    }}
                  >
                    Create
                  </Link>{' '}
                  a room to get started solving puzzles!
                </Box>
              </Container>
              {creditsError ? (
                <Alert
                  action={
                    <Button
                      loading={creditsLoading}
                      variant="normal"
                      onClick={async () => await creditsApi()}
                    >
                      Retry
                    </Button>
                  }
                  header="Credits Error"
                  statusIconAriaLabel="Error"
                  type="error"
                >
                  {creditsError}
                </Alert>
              ) : null}
            </SpaceBetween>
            <SpaceBetween size="s">
              <Container header={<Header variant="h2">Purchase Credits</Header>}>
                <SpaceBetween size="s">
                  <Box variant="p">
                    Want more AI-generated escape rooms? You can purchase credits here!
                  </Box>
                  <Box variant="p">
                    Each credit is {creditCost}. You can purchase 1, 5, or 10 credits at a time.
                    Credits are non-refundable, non-transferable, and can only be used on AI Escape.
                    Purchased credits never expire, so you can use them whenever you want! Click the
                    buttons below to purchase credits!
                  </Box>
                  <Button
                    disabled={purchaseLoading && purchaseCredits !== 1}
                    loading={purchaseLoading && purchaseCredits === 1}
                    variant="normal"
                    onClick={async () => purchase(1)}
                  >
                    Purchase 1 Credit
                  </Button>
                  <Button
                    disabled={purchaseLoading && purchaseCredits !== 5}
                    loading={purchaseLoading && purchaseCredits === 5}
                    variant="normal"
                    onClick={async () => purchase(5)}
                  >
                    Purchase 5 Credits
                  </Button>
                  <Button
                    disabled={purchaseLoading && purchaseCredits !== 10}
                    loading={purchaseLoading && purchaseCredits === 10}
                    variant="normal"
                    onClick={async () => purchase(10)}
                  >
                    Purchase 10 Credits
                  </Button>
                </SpaceBetween>
              </Container>
              {purchaseError ? (
                <Alert header="Purchase Error" statusIconAriaLabel="Error" type="error">
                  {purchaseError}
                </Alert>
              ) : (
                purchaseLoading
              )}
            </SpaceBetween>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
