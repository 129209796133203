import { Container, Header, AppLayout, ContentLayout, Grid } from '@cloudscape-design/components';
import { useContext } from 'react';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';

const raw_html = `
<div class="document-previewer-wrapper-a717db">
   <style>[data-custom-class='body'],[data-custom-class='body'] *{background: transparent !important;
      }[data-custom-class='title'],[data-custom-class='title'] *{font-family: Arial !important;
      font-size: 26px !important;
      }[data-custom-class='subtitle'],[data-custom-class='subtitle'] *{font-family: Arial !important;
      font-size: 14px !important;
      }[data-custom-class='heading_1'],[data-custom-class='heading_1'] *{font-family: Arial !important;
      font-size: 19px !important;
      }[data-custom-class='heading_2'],[data-custom-class='heading_2'] *{font-family: Arial !important;
      font-size: 17px !important;
      }[data-custom-class='body_text'],[data-custom-class='body_text'] *{
      font-size: 14px !important;
      font-family: Arial !important;
      }[data-custom-class='link'],[data-custom-class='link'] *{
      font-size: 14px !important;
      font-family: Arial !important;
      word-break: break-word !important;
      }
   </style>
   <div data-custom-class="body">
      <div align="center" style="text-align: left;">
         <div class="MsoNormal" data-custom-class="title" style="line-height: 1.5;">
            <bdt class="block-component"><span style="font-size: 19px;"></span></bdt>
            <span style="font-size: 19px;">
               <strong>TERMS OF USE</strong>
               <bdt class="else-block"></bdt>
            </span>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="subtitle" style="line-height: 1.5;">
            <strong>Last updated</strong> 
            <bdt class="question"><strong>April 19, 2023</strong></bdt>
         </div>
         <div class="MsoNormal" style="line-height: 115%;"><br></div>
         <div class="MsoNormal" style="line-height: 115%;"><br></div>
         <div style="line-height: 1.5;"><strong><span data-custom-class="heading_1">AGREEMENT TO OUR LEGAL TERMS</span></strong></div>
      </div>
      <div align="center" style="text-align: left;">
         <div class="MsoNormal" id="agreement" style="line-height: 1.5;"><a name="_6aa3gkhykvst"></a></div>
      </div>
      <div align="center" style="line-height: 1;"><br></div>
      <div align="center" style="text-align: left;">
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
               Calibri;mso-themetint:166;">
               We are 
               <bdt class="block-container question question-in-editor" data-id="9d459c4e-c548-e5cb-7729-a118548965d2" data-type="question">AI Escape</bdt>
               <bdt class="block-component"></bdt>
               (
               <bdt class="block-component"></bdt>
               "<strong>Company</strong>," "<strong>we</strong>," "<strong>us</strong>," "<strong>our</strong>"
               <bdt class="statement-end-if-in-editor"></bdt>
               )
               <span style="font-size:11.0pt;line-height:115%;
                  Arial;mso-fareast-font-family:Calibri;
                  mso-themetint:166;">
                  <span style="font-size:11.0pt;line-height:115%;
                     Arial;mso-fareast-font-family:Calibri;
                     mso-themetint:166;">
                     <span style="font-size:11.0pt;line-height:115%;
                        Arial;mso-fareast-font-family:Calibri;
                        mso-themetint:166;">
                        <bdt class="question">
                           <bdt class="block-component">.</bdt>
                        </bdt>
                     </span>
                     <bdt class="block-component"></bdt>
                  </span>
               </span>
            </span>
         </div>
      </div>
      <div align="center" style="line-height: 1;"><br></div>
      <div align="center" style="text-align: left;">
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
               Calibri;mso-themetint:166;">
               We operate 
               <bdt class="block-component"></bdt>
               <bdt class="block-component"></bdt>
               , as well as any other related products and services that refer or link to these legal terms (the 
               <bdt class="block-component"></bdt>
               "<strong>Legal Terms</strong>"
               <bdt class="statement-end-if-in-editor"></bdt>
               ) (collectively, the 
               <bdt class="block-component"></bdt>
               "<strong>Services</strong>"
               <bdt class="statement-end-if-in-editor"></bdt>
               ).
               <bdt class="block-component"></bdt>
            </span>
         </div>
         <div class="MsoNormal" style="line-height: 1;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
               Calibri;mso-themetint:166;">
               You can contact us by 
               <bdt class="block-component">
                  email at 
                  <bdt class="question">support@aiescape.io</bdt>
                  .
               </bdt>
            </span>
         </div>
         <div class="MsoNormal" style="line-height: 1;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
               Calibri;mso-themetint:166;">
               These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity (
               <bdt class="block-component"></bdt>
               "<strong>you</strong>"
               <bdt class="statement-end-if-in-editor"></bdt>
               ), and 
               <bdt class="question">AI Escape</bdt>
               , concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
               <bdt class="block-component"></bdt>
            </span>
         </div>
         <div class="MsoNormal" style="line-height: 1;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
               Calibri;mso-themetint:166;">
               Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms 
               <bdt class="block-component"></bdt>
               at any time and for any reason
               <bdt class="statement-end-if-in-editor"></bdt>
               . We will alert you about any changes by updating the 
               <bdt class="block-component"></bdt>
               "Last updated"
               <bdt class="statement-end-if-in-editor"></bdt>
               date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted.
               <bdt class="else-block"></bdt>
            </span>
         </div>
      </div>
      <div align="center" style="line-height: 1;"><br></div>
      <div align="center" style="text-align: left;">
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <bdt class="block-container if" data-type="if" id="a2595956-7028-dbe5-123e-d3d3a93ed076">
               <bdt data-type="conditional-block">
                  <bdt data-type="body">
                     <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
                        Calibri;mso-themetint:166;">
                        <bdt class="block-component"></bdt>
                     </span>
                  </bdt>
               </bdt>
            </bdt>
         </div>
         <div class="MsoNormal" style="line-height: 1;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">We recommend that you print a copy of these Legal Terms for your records.</div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><strong>TABLE OF CONTENTS</strong></div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" style="line-height: 1.5;"><a href="#services"><span data-custom-class="link"><span style="font-size: 15px;"><span data-custom-class="body_text">1. OUR SERVICES</span></span></span></a></div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#ip"><span data-custom-class="body_text">2. INTELLECTUAL PROPERTY RIGHTS</span></a></div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#userreps"></a><a data-custom-class="link" href="#userreps"><span style="font-size: 15px;"><span data-custom-class="body_text">3. USER REPRESENTATIONS</span></span></a></div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <span style="font-size: 15px;">
               <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
               </span>
            </span>
            <a data-custom-class="link" href="#products"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <a data-custom-class="link" href="#products">
               <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                     <bdt class="block-component"></bdt>
                     <bdt class="block-component"></bdt>
                     <bdt class="statement-end-if-in-editor"></bdt>
                  </span>
               </span>
            </a>
            <a data-custom-class="link" href="#purchases"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <a data-custom-class="link" href="#purchases">
               <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                     <bdt class="block-component"></bdt>
                  </span>
               </span>
            </a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <span style="font-size: 15px;">
               <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
               </span>
            </span>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <span style="font-size: 15px;">
               <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
               </span>
            </span>
            <a data-custom-class="link" href="#software"></a> <a data-custom-class="link" href="#software"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <a data-custom-class="link" href="#software">
               <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                     <bdt class="block-component"></bdt>
                  </span>
               </span>
            </a>
            <a data-custom-class="link" href="#prohibited"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#prohibited"><span style="font-size: 15px;"><span data-custom-class="body_text">4. PROHIBITED ACTIVITIES</span></span></a> <a data-custom-class="link" href="#ugc"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#ugc"><span style="font-size: 15px;"><span data-custom-class="body_text">5. USER GENERATED CONTRIBUTIONS</span></span></a> <a data-custom-class="link" href="#license"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <a data-custom-class="link" href="#license">
               <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                     6. CONTRIBUTION 
                     <bdt class="block-component"></bdt>
                     LICENSE
                     <bdt class="statement-end-if-in-editor"></bdt>
                  </span>
               </span>
            </a>
            <a data-custom-class="link" href="#reviews"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <a data-custom-class="link" href="#reviews">
               <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                     <bdt class="block-component"></bdt>
                  </span>
               </span>
            </a>
            <a data-custom-class="link" href="#mobile"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <a data-custom-class="link" href="#mobile">
               <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                     <bdt class="block-component"></bdt>
                  </span>
               </span>
            </a>
            <a data-custom-class="link" href="#socialmedia"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <a data-custom-class="link" href="#socialmedia">
               <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                     <bdt class="block-component"></bdt>
                  </span>
               </span>
            </a>
            <a data-custom-class="link" href="#thirdparty"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <a data-custom-class="link" href="#thirdparty">
               <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                     <bdt class="block-component"></bdt>
                  </span>
               </span>
            </a>
            <a data-custom-class="link" href="#advertisers"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <a data-custom-class="link" href="#advertisers">
               <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                     <bdt class="block-component"></bdt>
                  </span>
               </span>
            </a>
            <a data-custom-class="link" href="#sitemanage"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#sitemanage"><span style="font-size: 15px;"><span data-custom-class="body_text">7. SERVICES MANAGEMENT</span></span></a> <a data-custom-class="link" href="#ppyes"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <a data-custom-class="link" href="#ppyes">
               <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                     <bdt class="block-component"></bdt>
                  </span>
               </span>
            </a>
            <a data-custom-class="link" href="#ppno"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <a data-custom-class="link" href="#ppno">
               <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                     <bdt class="block-component"></bdt>
                  </span>
               </span>
            </a>
            <a data-custom-class="link" href="#dmca"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <a data-custom-class="link" href="#dmca">
               <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                     <bdt class="block-component"></bdt>
                  </span>
               </span>
            </a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <span style="font-size: 15px;">
               <span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
               </span>
            </span>
            <a data-custom-class="link" href="#terms"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#terms"><span style="font-size: 15px;"><span data-custom-class="body_text">8. TERM AND TERMINATION</span></span></a> <a data-custom-class="link" href="#modifications"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#modifications"><span style="font-size: 15px;"><span data-custom-class="body_text">9. MODIFICATIONS AND INTERRUPTIONS</span></span></a> <a data-custom-class="link" href="#law"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#law"><span style="font-size: 15px;"><span data-custom-class="body_text">10. GOVERNING LAW</span></span></a> <a data-custom-class="link" href="#disputes"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#disputes"><span style="font-size: 15px;"><span data-custom-class="body_text">11. DISPUTE RESOLUTION</span></span></a> <a data-custom-class="link" href="#corrections"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#corrections"><span style="font-size: 15px;"><span data-custom-class="body_text">12. CORRECTIONS</span></span></a> <a data-custom-class="link" href="#disclaimer"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#disclaimer"><span style="font-size: 15px;"><span data-custom-class="body_text">13. DISCLAIMER</span></span></a> <a data-custom-class="link" href="#liability"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#liability"><span style="font-size: 15px;"><span data-custom-class="body_text">14. LIMITATIONS OF LIABILITY</span></span></a> <a data-custom-class="link" href="#indemnification"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#indemnification"><span style="font-size: 15px;"><span data-custom-class="body_text">15. INDEMNIFICATION</span></span></a> <a data-custom-class="link" href="#userdata"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#userdata"><span style="font-size: 15px;"><span data-custom-class="body_text">16. USER DATA</span></span></a> <a data-custom-class="link" href="#electronic"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#electronic"><span style="font-size: 15px;"><span data-custom-class="body_text">17. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span></span></a> <a data-custom-class="link" href="#california"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <a data-custom-class="link" href="#california">
               <span style="font-size: 15px;">
                  <span data-custom-class="body_text">
                     <bdt class="block-component"></bdt>
                  </span>
               </span>
            </a>
            <a data-custom-class="link" href="#misc"></a>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#misc"><span style="font-size: 15px;"><span data-custom-class="body_text">18. MISCELLANEOUS</span></span></a> <a data-custom-class="link" href="#contact"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <bdt class="block-component"></bdt>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <bdt class="block-component"></bdt>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <bdt class="block-component"></bdt>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <bdt class="block-component"></bdt>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <bdt class="block-component"></bdt>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <bdt class="block-component"></bdt>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <bdt class="block-component"></bdt>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <bdt class="block-component"></bdt>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <bdt class="block-component"></bdt>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;">
            <bdt class="block-component"></bdt>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><a data-custom-class="link" href="#contact"><span style="font-size: 15px;"><span data-custom-class="body_text">19. CONTACT US</span></span></a></div>
      </div>
      <div align="center" style="text-align: left;">
         <div class="MsoNormal" data-custom-class="heading_1" style="line-height: 1.5;"><a name="_b6y29mp52qvx"></a></div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="heading_1" id="services" style="line-height: 1.5;"><strong><span style="font-size: 19px;">1. OUR SERVICES</span></strong></div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px;">
               The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
               <bdt class="block-component"></bdt>
            </span>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
      </div>
      <div align="center" data-custom-class="heading_1" style="text-align: left; line-height: 1.5;"><strong><span id="ip" style="font-size: 19px;">2. INTELLECTUAL PROPERTY RIGHTS</span></strong></div>
      <div align="center" style="line-height: 1.5;"><br></div>
      <div align="center" style="text-align: left;">
         <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;"><strong>Our intellectual property</strong></div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
               Calibri;mso-themetint:166;">
               We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the 
               <bdt class="block-component"></bdt>
               "Content"
               <bdt class="statement-end-if-in-editor"></bdt>
               ), as well as the trademarks, service marks, and logos contained therein (the 
               <bdt class="block-component"></bdt>
               "Marks"
               <bdt class="statement-end-if-in-editor"></bdt>
               ).
            </span>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
            Calibri;mso-themetint:166;">Our Content and Marks are protected by copyright and trademark laws (and various
            other intellectual property rights and unfair competition laws) and treaties in the United
            States and around the world.</span>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
               Calibri;mso-themetint:166;">
               The Content and Marks are provided in or through the Services 
               <bdt class="block-component"></bdt>
               "AS IS"
               <bdt class="statement-end-if-in-editor"></bdt>
               for your 
               <bdt class="block-component"></bdt>
               personal, non-commercial use or internal business purpose
               <bdt class="statement-end-if-in-editor"></bdt>
               only.
            </span>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;"><strong>Your use of our Services</strong></div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px;">
               Subject to your compliance with these Legal Terms, including the 
               <bdt class="block-component"></bdt>
               "
               <bdt class="statement-end-if-in-editor"></bdt>
            </span>
            <a data-custom-class="link" href="#prohibited"><span style="font-size: 15px;">PROHIBITED ACTIVITIES</span></a>
            <span style="font-size: 15px;">
               <bdt class="block-component"></bdt>
               "
               <bdt class="statement-end-if-in-editor"></bdt>
               section below, we grant you a non-exclusive, non-transferable, revocable 
               <bdt class="block-component"></bdt>
               license
               <bdt class="statement-end-if-in-editor"></bdt>
               to:
            </span>
         </div>
         <ul>
            <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">access the Services; and</span></li>
            <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">download or print a copy of any portion of the Content to which you have properly gained access.</span></li>
         </ul>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
               Calibri;mso-themetint:166;">
               solely for your 
               <bdt class="block-component"></bdt>
               personal, non-commercial use or internal business purpose
               <bdt class="statement-end-if-in-editor"></bdt>
               .
            </span>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
            Calibri;mso-themetint:166;">Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced,
            aggregated, republished, uploaded, posted, publicly displayed, encoded,
            translated, transmitted, distributed, sold, licensed, or otherwise exploited
            for any commercial purpose whatsoever, without our express prior written
            permission.</span>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
               Calibri;mso-themetint:166;">
               If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: 
               <bdt class="question">support@aiescape.io</bdt>
               . If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
            </span>
         </div>
      </div>
      <div align="center" style="line-height: 1.5;"><br></div>
      <div align="center" style="text-align: left;">
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
            Calibri;mso-themetint:166;">We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.</span></div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
            Calibri;mso-themetint:166;">Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.</span></div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5;">
            <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
               Calibri;mso-themetint:166;">
               <strong>
                  Your submissions
                  <bdt class="block-component"></bdt>
               </strong>
            </span>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px;">
               Please review this section and the 
               <bdt class="block-component"></bdt>
               "
               <bdt class="statement-end-if-in-editor"></bdt>
               <a data-custom-class="link" href="#prohibited">PROHIBITED ACTIVITIES</a>
               <bdt class="block-component"></bdt>
               "
               <bdt class="statement-end-if-in-editor"></bdt>
               section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the Services.
            </span>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px;">
               <strong>Submissions:</strong> By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services (
               <bdt class="block-component"></bdt>
               "Submissions"
               <bdt class="statement-end-if-in-editor"></bdt>
               ), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.
               <bdt class="block-component"></bdt>
            </span>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size: 15px;">
               <strong>You are responsible for what you post or upload:</strong> By sending us Submissions
               <bdt class="block-component"></bdt>
               through any part of the Services
               <bdt class="block-component"></bdt>
               you:
            </span>
         </div>
         <ul>
            <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
               <span style="font-size: 15px;">
                  confirm that you have read and agree with our 
                  <bdt class="block-component"></bdt>
                  "
                  <bdt class="statement-end-if-in-editor"></bdt>
               </span>
               <a data-custom-class="link" href="#prohibited"><span style="font-size: 15px;">PROHIBITED ACTIVITIES</span></a>
               <span style="font-size: 15px;">
                  <bdt class="block-component"></bdt>
                  "
                  <bdt class="statement-end-if-in-editor"></bdt>
                  and will not post, send, publish, upload, or transmit through the Services any Submission
                  <bdt class="block-component"></bdt>
                  that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading;
               </span>
            </li>
            <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
               <span style="font-size: 15px;">
                  to the extent permissible by applicable law, waive any and all moral rights to any such Submission
                  <bdt class="block-component"></bdt>
                  ;
               </span>
            </li>
            <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
               <span style="font-size: 15px;">
                  warrant that any such Submission
                  <bdt class="block-component"></bdt>
                  are original to you or that you have the necessary rights and 
                  <bdt class="block-component"></bdt>
                  licenses
                  <bdt class="statement-end-if-in-editor"></bdt>
                  to submit such Submissions
                  <bdt class="block-component"></bdt>
                  and that you have full authority to grant us the above-mentioned rights in relation to your Submissions
                  <bdt class="block-component"></bdt>
                  ; and
               </span>
            </li>
            <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
               <span style="font-size: 15px;">
                  warrant and represent that your Submissions
                  <bdt class="block-component"></bdt>
                  do not constitute confidential information.
               </span>
            </li>
         </ul>
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            You are solely responsible for your Submissions
            <bdt class="block-component"></bdt>
            and you expressly agree to reimburse us for any and all losses that we may suffer because of your breach of (a) this section, (b) any third party’s intellectual property rights, or (c) applicable law.
            <bdt class="block-component"></bdt>
            <bdt class="block-component"></bdt>
         </div>
         <div class="MsoNormal" style="line-height: 1.5;"><br></div>
      </div>
      <div align="center" style="text-align: left;">
         <div class="MsoNormal" data-custom-class="heading_1" id="userreps" style="line-height: 1.5;"><a name="_5hg7kgyv9l8z"></a><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">3.</span></strong></span></strong></span>&nbsp;</strong>USER REPRESENTATIONS</span></strong></div>
      </div>
      <div align="center" style="line-height: 1.5;"><br></div>
      <div align="center" style="text-align: left;">
         <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
            <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
               Calibri;mso-themetint:166;">By using the Services, you represent and warrant that:</span>
            <bdt class="block-container if" data-type="if" id="d2d82ca8-275f-3f86-8149-8a5ef8054af6">
               <bdt data-type="conditional-block">
                  <bdt class="block-component" data-record-question-key="user_account_option" data-type="statement"></bdt>
                  &nbsp;
               </bdt>
               <span style="font-size: 11pt;">(</span><span style="font-size: 14.6667px;">1</span><span style="font-size: 11pt;">) you have the legal capacity and you agree to comply with these Legal Terms;</span>
               <bdt class="block-container if" data-type="if" id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13">
                  <bdt data-type="conditional-block">
                     <bdt class="block-component" data-record-question-key="user_u13_option" data-type="statement"></bdt>
                     &nbsp;
                  </bdt>
                  <span style="font-size: 11pt;">(</span><span style="font-size: 14.6667px;">2</span>
                  <span style="font-size: 11pt;">
                     ) you are not a
                     minor in the jurisdiction in which you reside
                     <bdt class="block-container if" data-type="if" id="76948fab-ec9e-266a-bb91-948929c050c9">
                        <bdt data-type="conditional-block">
                           <bdt class="block-component" data-record-question-key="user_o18_option" data-type="statement"></bdt>
                        </bdt>
                        ; (
                     </bdt>
                  </span>
                  <span style="font-size: 14.6667px;">3</span><span style="font-size: 11pt;">) you will not access the Services through automated or non-human means, whether through a bot, script or
                  otherwise; (</span><span style="font-size: 14.6667px;">4</span>
                  <span style="font-size: 11pt;">
                     ) you will not use the Services for any illegal or 
                     <bdt class="block-component"></bdt>
                     unauthorized
                     <bdt class="statement-end-if-in-editor"></bdt>
                     purpose; and (
                  </span>
                  <span style="font-size: 14.6667px;">5</span><span style="font-size: 11pt;">) your use of the Services will not violate any applicable law or regulation.</span><span style="font-size: 14.6667px;"></span>
               </bdt>
            </bdt>
         </div>
      </div>
      <div align="center" style="line-height: 1.5;"><br></div>
      <div align="center" style="text-align: left;">
         <div class="MsoNormal" style="text-align: justify; line-height: 115%;">
            <div class="MsoNormal" style="line-height: 17.25px;">
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 11pt; line-height: 16.8667px; ">If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).</span></div>
               <div class="MsoNormal" style="line-height: 1.1; text-align: left;">
                  <bdt class="block-component"></bdt>
               </div>
            </div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
               <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
               <span style="font-size: 15px;">
                  <bdt class="block-component"></bdt>
                  <bdt class="statement-end-if-in-editor"></bdt>
               </span>
            </div>
            <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
               <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5;">
               <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5;">
               <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
            </div>
            <div class="MsoNormal" style="line-height: 1.5;">
               <bdt class="block-component"></bdt>
            </div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1.5;">
               <span style="line-height: 115%; font-family: Arial; ">
                  <bdt data-type="conditional-block" style=" text-align: left;">
                     <bdt data-type="body">
                        <div class="MsoNormal" style="font-size: 15px; line-height: 1.5;"><br></div>
                     </bdt>
                  </bdt>
               </span>
               <div class="MsoNormal" data-custom-class="heading_1" id="prohibited" style="text-align: left; line-height: 1.5;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">4.</span></strong></span></strong></span>&nbsp;</strong>PROHIBITED ACTIVITIES</span></strong></div>
            </div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;">
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  <span style="font-size: 11pt; line-height: 16.8667px; ">
                     You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial 
                     <bdt class="block-component"></bdt>
                     endeavors
                     <bdt class="statement-end-if-in-editor"></bdt>
                     except those that are specifically endorsed or approved by us.
                  </span>
               </div>
            </div>
            <div class="MsoNormal" style="line-height: 1.5;"><br></div>
            <div class="MsoNormal" style="text-align: justify; line-height: 1;">
               <div class="MsoNormal" style="line-height: 17.25px;">
                  <div class="MsoNormal" style="line-height: 1.1;">
                     <div class="MsoNormal" style="line-height: 17.25px;">
                        <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 11pt; line-height: 16.8667px; ">As a user of the Services, you agree not to:</span></div>
                     </div>
                     <ul>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 11pt; line-height: 16.8667px; "><span style="font-family: sans-serif; font-size: 15px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Use any information obtained from the Services in order to harass, abuse, or harm another person.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Make improper use of our support services or submit false reports of abuse or misconduct.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px;  text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Use the Services in a manner inconsistent with any applicable laws or regulations.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                           <span style="font-size: 15px;">
                              <span style="line-height: 16.8667px; ">
                                 <span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                    <span style="line-height: 16.8667px; ">
                                       <span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                          Engage in 
                                          <bdt class="block-component"></bdt>
                                          unauthorized
                                          <bdt class="statement-end-if-in-editor"></bdt>
                                          framing of or linking to the Services.
                                       </span>
                                    </span>
                                 </span>
                              </span>
                           </span>
                        </li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Delete the copyright or other proprietary rights notice from any Content.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Attempt to impersonate another user or person or use the username of another user.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                           <span style="font-size: 15px;">
                              <span style="line-height: 16.8667px; ">
                                 <span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                    <span style="line-height: 16.8667px; ">
                                       <span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                          Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (
                                          <bdt class="block-component"></bdt>
                                          "gifs"
                                          <bdt class="statement-end-if-in-editor"></bdt>
                                          ), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as 
                                          <bdt class="block-component"></bdt>
                                          "spyware" or "passive collection mechanisms" or "pcms"
                                          <bdt class="statement-end-if-in-editor"></bdt>
                                          ).
                                       </span>
                                    </span>
                                 </span>
                              </span>
                           </span>
                        </li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                           <span style="font-size: 15px;">
                              <span style="line-height: 16.8667px; ">
                                 <span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                    <span style="line-height: 16.8667px; ">
                                       <span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                          Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or use or launch any 
                                          <bdt class="block-component"></bdt>
                                          unauthorized
                                          <bdt class="statement-end-if-in-editor"></bdt>
                                          script or other software.
                                       </span>
                                    </span>
                                 </span>
                              </span>
                           </span>
                        </li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">Use a buying agent or purchasing agent to make purchases on the Services.</span></span></span></span></span></li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                           <span style="font-size: 15px;">
                              <span style="line-height: 16.8667px; ">
                                 <span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                    <span style="line-height: 16.8667px; ">
                                       <span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                          Make any 
                                          <bdt class="block-component"></bdt>
                                          unauthorized
                                          <bdt class="statement-end-if-in-editor"></bdt>
                                          use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false 
                                          <bdt class="block-component"></bdt>
                                          pretenses
                                          <bdt class="statement-end-if-in-editor"></bdt>
                                          .
                                       </span>
                                    </span>
                                 </span>
                              </span>
                           </span>
                        </li>
                        <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                           <span style="font-size: 15px;">
                              <span style="line-height: 16.8667px; ">
                                 <span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                    <span style="line-height: 16.8667px; ">
                                       <span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                          Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating 
                                          <bdt class="block-component"></bdt>
                                          endeavor
                                          <bdt class="statement-end-if-in-editor"></bdt>
                                          or commercial enterprise.
                                       </span>
                                       <span style="font-size: 11pt; line-height: 16.8667px; font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                          <bdt class="forloop-component"></bdt>
                                       </span>
                                    </span>
                                 </span>
                              </span>
                           </span>
                        </li>
                     </ul>
                  </div>
                  <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               </div>
               <div class="MsoNormal" style="line-height: 17.25px;">
                  <div class="MsoNormal" style="line-height: 1;">
                     <bdt class="block-container if" data-type="if" style="text-align: left;">
                        <bdt data-type="conditional-block">
                           <bdt data-type="body">
                              <div class="MsoNormal" data-custom-class="heading_1" id="ugc" style="line-height: 1.5;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">5.</span></strong></span></strong></span>&nbsp;</strong>USER GENERATED CONTRIBUTIONS</span></strong></div>
                           </bdt>
                        </bdt>
                     </bdt>
                  </div>
                  <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
                  <div class="MsoNormal" style="line-height: 1;">
                     <bdt class="block-container if" data-type="if" style="text-align: left;">
                        <bdt data-type="conditional-block">
                           <bdt data-type="body">
                              <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
                                 <span style="font-size: 11pt; line-height: 16.8667px; ">
                                    <bdt class="block-container if" data-type="if" id="24327c5d-a34f-f7e7-88f1-65a2f788484f" style="text-align: left;">
                                       <bdt data-type="conditional-block">
                                          <bdt class="block-component" data-record-question-key="user_post_content_option" data-type="statement"></bdt>
                                          <span style="font-size: 11pt; line-height: 16.8667px; ">
                                             The Services does not offer users to submit or post content.
                                             <bdt class="block-component"></bdt>
                                             We may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Services, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, 
                                             <bdt class="block-component"></bdt>
                                             "Contributions"
                                             <bdt class="statement-end-if-in-editor"></bdt>
                                             ). Contributions may be viewable by other users of the Services and through third-party websites.
                                             <bdt class="block-component"></bdt>
                                             When you create or make available any Contributions, you thereby represent and warrant that:
                                             <span style="font-size: 11pt; line-height: 16.8667px; ">
                                                <bdt class="statement-end-if-in-editor">
                                                   <bdt class="block-component"></bdt>
                                                </bdt>
                                             </span>
                                             <ul>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                               The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.
                                             </span></span></span></span></span></li>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                             You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Site, and other users of the Site to use your Contributions in any manner contemplated by the Site and these Terms of Use.
                                             </span></span></span></span></span></li>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                             You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Site and these Terms of Use.
                                             </span></span></span></span></span></li>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                             Your Contributions are not false, inaccurate, or misleading.
                                             </span></span></span></span></span></li>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                             Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.
                                             </span></span></span></span></span></li>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                             Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).
                                             </span></span></span></span></span></li>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                             Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
                                             </span></span></span></span></span></li>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                             Your Contributions do not advocate the violent overthrow of any government or incite, encourage, or threaten physical harm against another.
                                             </span></span></span></span></span></li>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                             Your Contributions do not violate any applicable law, regulation, or rule.
                                             </span></span></span></span></span></li>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                             Your Contributions do not violate the privacy or publicity rights of any third party.
                                             </span></span></span></span></span></li>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                             Your Contributions do not contain any material that solicits personal information from anyone under the age of 18 or exploits people under the age of 18 in a sexual or violent manner.
                                             </span></span></span></span></span></li>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                             Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors;
                                             </span></span></span></span></span></li>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                             Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.
                                             </span></span></span></span></span></li>
                                             <li class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 15px;"><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  "><span style="line-height: 16.8667px; "><span style="font-family: sans-serif; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: justify; text-indent: -29.4px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial;  ">
                                             Your Contributions do not otherwise violate, or link to material that violates, any provision of these Terms of Use, or any applicable law or regulation.
                                             </span></span></span></span></span></li>                                             
                                             </ul>
                                             Any use of the Site in violation of the foregoing violates these Terms of Use and may result in, among other things, termination or suspension of your rights to use the Site.
                                          </span>
                                       </bdt>
                                    </bdt>
                                 </span>
                              </div>
                           </bdt>
                        </bdt>
                     </bdt>
                  </div>
                  <div class="MsoNormal" style="line-height: 1.5;"><br></div>
                  <div class="MsoNormal" style="text-align: justify; line-height: 1;">
                     <bdt class="block-container if" data-type="if" style="text-align: left;">
                        <bdt data-type="conditional-block">
                           <bdt data-type="body">
                              <div class="MsoNormal" data-custom-class="heading_1" id="license" style="line-height: 1.5;">
                                 <strong>
                                    <span style="line-height: 24.5333px; font-size: 19px;">
                                       <strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">6.</span></strong></span></strong></span>&nbsp;</strong>CONTRIBUTION 
                                       <bdt class="block-component"></bdt>
                                       LICENSE
                                       <bdt class="statement-end-if-in-editor"></bdt>
                                    </span>
                                 </strong>
                              </div>
                           </bdt>
                        </bdt>
                     </bdt>
                  </div>
                  <div class="MsoNormal" style="line-height: 1.5;"><br></div>
                  <div class="MsoNormal" style="line-height: 1;">
                     <bdt class="block-container if" data-type="if" id="a088ddfb-d8c1-9e58-6f21-958c3f4f0709" style="text-align: left;">
                        <bdt data-type="conditional-block">
                           <bdt class="block-component" data-record-question-key="user_post_content_option" data-type="statement"></bdt>
                        </bdt>
                     </bdt>
                  </div>
                  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
                     <span style="font-size: 11pt; line-height: 16.8667px; ">
                        <span style="font-size: 11pt; line-height: 16.8667px; ">
                           You and Services agree that we may access, store, process, and use any information and personal data that you provide
                           <bdt class="block-component"></bdt>
                           and your choices (including settings).
                        </span>
                     </span>
                  </div>
                  <div class="MsoNormal" style="line-height: 1.5;"><br></div>
                  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
                     <span style="font-size: 11pt; line-height: 16.8667px; ">
                        By submitting suggestions or other feedback regarding the Services, you agree that we can use and share such feedback for any purpose without compensation to you.
                        <bdt class="block-component"></bdt>
                     </span>
                  </div>
                  <div class="MsoNormal" style="line-height: 1.5;"><br></div>
                  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
                     <span style="font-size: 11pt; line-height: 16.8667px; ">
                        We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the Services. You are solely responsible for your Contributions to the Services and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.
                        <bdt class="statement-end-if-in-editor"></bdt>
                     </span>
                  </div>
                  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;"><br></div>
                  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
                     <span style="font-size: 11pt; line-height: 16.8667px; ">
                        <span style="font-size: 11pt; line-height: 16.8667px; ">
                           <span style="font-size: 11pt; line-height: 16.8667px; ">
                              <bdt class="block-container if" data-type="if" style="text-align: left;">
                                 <bdt class="statement-end-if-in-editor" data-type="close"></bdt>
                              </bdt>
                           </span>
                        </span>
                     </span>
                  </div>
                  <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">
                     <bdt class="block-container if" data-type="if" style="text-align: left;">
                        <bdt data-type="conditional-block">
                           <bdt class="block-component" data-record-question-key="review_option" data-type="statement"></bdt>
                        </bdt>
                     </bdt>
                  </div>
                  <div class="MsoNormal" style="line-height: 1.5;">
                     <bdt class="block-container if" data-type="if" style="text-align: left;">
                        <bdt data-type="conditional-block">
                           <bdt class="block-component" data-record-question-key="mobile_app_option" data-type="statement"></bdt>
                        </bdt>
                     </bdt>
                  </div>
                  <div class="MsoNormal" style="line-height: 1.5;">
                     <bdt class="block-container if" data-type="if" style="text-align: left;">
                        <bdt data-type="conditional-block">
                           <bdt class="block-component" data-record-question-key="socialnetwork_link_option" data-type="statement"></bdt>
                        </bdt>
                     </bdt>
                  </div>
               </div>
               <div class="MsoNormal" style="line-height: 1.5;">
                  <bdt class="block-container if" data-type="if" style="text-align: left;">
                     <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="3rd_party_option" data-type="statement"></bdt>
                     </bdt>
                  </bdt>
               </div>
               <div class="MsoNormal" style="line-height: 1.5;">
                  <bdt class="block-container if" data-type="if" style="text-align: left;">
                     <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="advertiser_option" data-type="statement"></bdt>
                     </bdt>
                  </bdt>
               </div>
               <div class="MsoNormal" data-custom-class="heading_1" id="sitemanage" style="line-height: 1.5;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">7.</span></strong></span></strong></span>&nbsp;</strong>SERVICES MANAGEMENT</span></strong></div>
               <div class="MsoNormal" style="line-height: 1.5;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5;">We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.</div>
               <div class="MsoNormal" style="line-height: 1.5;"><br></div>
               <div class="MsoNormal" style="line-height: 1.5;">
                  <bdt class="block-container if" data-type="if" style="text-align: left;">
                     <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="privacy_policy_option" data-type="statement"></bdt>
                     </bdt>
                     <bdt class="block-container if" data-type="if">
                        <bdt data-type="conditional-block">
                           <bdt class="block-component" data-record-question-key="privacy_policy_followup" data-type="statement" style="font-size: 14.6667px;"></bdt>
                        </bdt>
                     </bdt>
                  </bdt>
               </div>
               <div class="MsoNormal" style="line-height: 1.5;">
                  <bdt class="block-container if" data-type="if" style="text-align: left;">
                     <bdt data-type="conditional-block">
                        <bdt class="block-component" data-record-question-key="copyright_agent_option" data-type="statement">
                           <bdt class="block-component"></bdt>
                        </bdt>
                     </bdt>
                  </bdt>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                  <bdt class="block-component"></bdt>
               </div>
               <div class="MsoNormal" data-custom-class="heading_1" id="terms" style="line-height: 1.5; text-align: left;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">8.</span></strong></span></strong></span>&nbsp;</strong>TERM AND TERMINATION</span></strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  <span style="font-size: 11pt; line-height: 16.8667px; ">
                     These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE 
                     <bdt class="block-container if" data-type="if" id="a6e121c2-36b4-5066-bf9f-a0a33512e768">
                        <bdt data-type="conditional-block">
                           <bdt class="block-component" data-record-question-key="user_account_option" data-type="statement"></bdt>
                        </bdt>
                        ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                     </bdt>
                  </span>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 11pt; line-height: 16.8667px; ">If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</span></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="heading_1" id="modifications" style="line-height: 1.5; text-align: left;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">9.</span></strong></span></strong></span>&nbsp;</strong>MODIFICATIONS AND INTERRUPTIONS</span></strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  <span style="font-size: 11pt; line-height: 16.8667px; ">
                     We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services.
                     <bdt class="block-component"></bdt>
                     We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.
                  </span>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size: 11pt; line-height: 16.8667px; ">We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to supply any corrections, updates, or releases in connection therewith.</span></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="heading_1" id="law" style="line-height: 1.5; text-align: left;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">10.</span></strong></span></strong></span>&nbsp;</strong>GOVERNING LAW</span></strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                  <span style="font-size: 11pt; line-height: 16.8667px; ">
                     <bdt class="block-component"></bdt>
                  </span>
               </div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  <span style="font-size: 11pt; line-height: 16.8667px; ">
                     These Legal Terms shall be governed by and defined following the laws of 
                     <bdt class="block-component"></bdt>
                     <bdt class="question">the United States</bdt>
                     <span style="font-size: 11pt; line-height: 16.8667px; ">
                        <bdt class="statement-end-if-in-editor"></bdt>
                     </span>
                     . 
                     <bdt class="question">AI Escape</bdt>
                     and yourself irrevocably consent that the courts of 
                     <span style="font-size: 11pt; line-height: 16.8667px; ">
                        <bdt class="block-component"></bdt>
                        <bdt class="question">the State of Texas</bdt>
                        <span style="font-size: 11pt; line-height: 16.8667px; ">
                           <bdt class="statement-end-if-in-editor"></bdt>
                        </span>
                     </span>
                     shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these Legal Terms.
                     <bdt class="statement-end-if-in-editor"></bdt>
                  </span>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="heading_1" id="disputes" style="line-height: 1.5; text-align: left;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">11.</span></strong></span></strong></span>&nbsp;</strong>DISPUTE RESOLUTION</span></strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                  <bdt class="block-component"></bdt>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                  <bdt class="block-component"></bdt>
               </div>
               <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5; text-align: left;"><strong>Informal Negotiations</strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  <span style="font-size: 15px;">
                     To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms (each a 
                     <bdt class="block-component"></bdt>
                     "Dispute" and collectively, the "Disputes"
                     <bdt class="statement-end-if-in-editor"></bdt>
                     ) brought by either you or us (individually, a 
                     <bdt class="block-component"></bdt>
                     "Party" and collectively, the "Parties"
                     <bdt class="statement-end-if-in-editor"></bdt>
                     ), the Parties agree to first attempt to negotiate any Dispute (except those Disputes expressly provided below) informally for at least 
                     <bdt class="question">thirty (30)</bdt>
                     days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.
                  </span>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                  <bdt class="statement-end-if-in-editor"></bdt>
               </div>
               <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5; text-align: left;"><strong>Binding Arbitration</strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
               </div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  Any dispute arising out of or in connection with these Legal Terms, including any question regarding its existence, validity, or termination, shall be referred to and finally resolved by the International Commercial Arbitration Court under the European Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according to the Rules of this ICAC, which, as a result of referring to it, is considered as the part of this clause. The number of arbitrators shall be 
                  <bdt class="question">three</bdt>
                  . The seat, or legal place, or arbitration shall be 
                  <bdt class="block-component"></bdt>
                  <bdt class="block-component"></bdt>
                  <bdt class="block-component"></bdt>
                  <bdt class="question">the State of Texas</bdt>
                  <bdt class="statement-end-if-in-editor">
                     <bdt class="statement-end-if-in-editor"></bdt>
                     <bdt class="statement-end-if-in-editor"></bdt>
                  </bdt>
                  . The language of the proceedings shall be 
                  <bdt class="question">English</bdt>
                  . The governing law of these Legal Terms shall be substantive law of 
                  <bdt class="block-component"></bdt>
                  <bdt class="block-component"></bdt>
                  <bdt class="question">the State of Texas</bdt>
                  <bdt class="statement-end-if-in-editor">
                     <bdt class="statement-end-if-in-editor"></bdt>
                  </bdt>
                  .
                  <bdt class="statement-end-if-in-editor">
                     <bdt class="statement-end-if-in-editor"></bdt>
                  </bdt>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5; text-align: left;"><strong>Restrictions</strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to 
                  <bdt class="block-component"></bdt>
                  utilize
                  <bdt class="statement-end-if-in-editor"></bdt>
                  class action procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other persons.
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="heading_2" style="line-height: 1.5; text-align: left;">
                  <bdt class="block-component"></bdt>
                  <strong>Exceptions to Informal Negotiations and Arbitration</strong>
                  <bdt class="statement-end-if-in-editor"></bdt>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  <bdt class="block-component"></bdt>
                  The Parties agree that the following Disputes are not subject to the above provisions concerning informal negotiations binding arbitration: (a) any Disputes seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or 
                  <bdt class="block-component"></bdt>
                  unauthorized
                  <bdt class="statement-end-if-in-editor"></bdt>
                  use; and (c) any claim for injunctive relief. If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the personal jurisdiction of that court.
                  <bdt class="statement-end-if-in-editor"></bdt>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                  <bdt class="statement-end-if-in-editor">
                     <bdt class="statement-end-if-in-editor"></bdt>
                  </bdt>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="heading_1" id="corrections" style="line-height: 1.5; text-align: left;"><strong><span style="font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">12.</span></strong></span></strong></span>&nbsp;</strong>CORRECTIONS</span></strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.</div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="heading_1" id="disclaimer" style="line-height: 1.5; text-align: left;"><span style="font-size: 19px; "><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">13.</span></strong></span></strong></span> DISCLAIMER</strong></span></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
                     Calibri;mso-themetint:166;">
                     THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY 
                     <bdt class="block-component"></bdt>
                     UNAUTHORIZED
                     <bdt class="statement-end-if-in-editor"></bdt>
                     ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST 
                     <bdt class="block-component"></bdt>
                     JUDGMENT
                     <bdt class="statement-end-if-in-editor"></bdt>
                     AND EXERCISE CAUTION WHERE APPROPRIATE.
                  </span>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="heading_1" id="liability" style="line-height: 1.5; text-align: left;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">14.</span></strong></span></strong></span>&nbsp;</strong>LIMITATIONS OF LIABILITY</span></strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                  <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
                     Calibri;mso-themetint:166;">
                     <span data-custom-class="body_text">IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span> 
                     <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                        <span data-custom-class="body_text">
                           <bdt data-type="conditional-block">
                              <bdt class="block-component" data-record-question-key="limitations_liability_option" data-type="statement"></bdt>
                              <bdt data-type="body">
                                 NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO 
                                 <bdt class="block-container if" data-type="if" id="73189d93-ed3a-d597-3efc-15956fa8e04e">
                                    <bdt data-type="conditional-block">
                                       <bdt class="block-component" data-record-question-key="limitations_liability_option" data-type="statement"></bdt>
                                       <bdt data-type="body">
                                          THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO US
                                          <bdt data-type="conditional-block">
                                             <bdt class="block-component" data-record-question-key="limilation_liability_time_option" data-type="statement"></bdt>
                                             OR 
                                             <bdt class="block-component"></bdt>
                                             <bdt class="block-component"></bdt>
                                          </bdt>
                                       </bdt>
                                    </bdt>
                                    <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
                                       Calibri;mso-themetint:166;">
                                       <bdt class="block-container if" data-type="if" id="3c3071ce-c603-4812-b8ca-ac40b91b9943">
                                          <bdt class="statement-end-if-in-editor" data-type="close"><span data-custom-class="body_text">
                                          $100.</span></bdt>
                                       </bdt>
                                       &nbsp;
                                    </span>
                                    <span data-custom-class="body_text">CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</span>
                                 </bdt>
                              </bdt>
                           </bdt>
                        </span>
                        <bdt class="statement-end-if-in-editor" data-type="close"><span data-custom-class="body_text"></span></bdt>
                     </bdt>
                  </span>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="heading_1" id="indemnification" style="line-height: 1.5; text-align: left;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">15.</span></strong></span></strong></span>&nbsp;</strong>INDEMNIFICATION</span></strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
                     Calibri;mso-themetint:166;">
                     You agree to
                     defend, indemnify, and hold us harmless, including our subsidiaries,
                     affiliates, and all of our respective officers, agents, partners, and
                     employees, from and against any loss, damage, liability, claim, or demand, including
                     reasonable attorneys’ fees and expenses, made by any third party due to or
                     arising out of: 
                     <bdt class="block-container if" data-type="if" id="475fffa5-05ca-def8-ac88-f426b238903c">
                        <bdt data-type="conditional-block">
                           <bdt class="block-component" data-record-question-key="user_post_content_option" data-type="statement"></bdt>
                        </bdt>
                        (<span style="font-size: 14.6667px;">1</span>) use of the Services; (<span style="font-size: 14.6667px;">2</span>) breach of these Legal Terms; (<span style="font-size: 14.6667px;">3</span>) any breach of your representations and warranties set forth in these Legal Terms; (<span style="font-size: 14.6667px;">4</span>) your violation of the rights of a third party, including but not limited to intellectual property rights; or (<span style="font-size: 14.6667px;">5</span>) any overt harmful act toward any other user of the Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive 
                        <bdt class="block-component"></bdt>
                        defense
                        <bdt class="statement-end-if-in-editor"></bdt>
                        and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our 
                        <bdt class="block-component"></bdt>
                        defense
                        <bdt class="statement-end-if-in-editor"></bdt>
                        of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
                     </bdt>
                  </span>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="heading_1" id="userdata" style="line-height: 1.5; text-align: left;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">16.</span></strong></span></strong></span>&nbsp;</strong>USER DATA</span></strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
                  Calibri;mso-themetint:166;">We will maintain
                  certain data that you transmit to the Services for the purpose of managing the
                  performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups
                  of data, you are solely responsible for all data that you transmit or that
                  relates to any activity you have undertaken using the Services. You agree
                  that we shall have no liability to you for any loss or corruption of any such
                  data, and you hereby waive any right of action against us arising from any such
                  loss or corruption of such data.</span>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="heading_1" id="electronic" style="line-height: 1.5; text-align: left;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">17.</span></strong></span></strong></span>&nbsp;</strong>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span></strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
                  Calibri;mso-themetint:166;">Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.</span></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                  <bdt class="block-component"></bdt>
               </div>
               <div class="MsoNormal" data-custom-class="heading_1" id="misc" style="line-height: 1.5; text-align: left;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">18.</span></strong></span></strong></span>&nbsp;</strong>MISCELLANEOUS</span></strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
                     Calibri;mso-themetint:166;">
                     These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive any and all 
                     <bdt class="block-component"></bdt>
                     defenses
                     <bdt class="statement-end-if-in-editor"></bdt>
                     you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.
                  </span>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;">
                  <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
               </div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="heading_1" id="contact" style="line-height: 1.5; text-align: left;"><strong><span style="line-height: 115%; font-family: Arial;"><span style="font-size: 19px;"><strong><span style="line-height: 24.5333px; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;"><strong><span style="line-height: 115%; font-family: Arial; font-size: 19px;">19.</span></strong></span></strong></span>&nbsp;</strong>CONTACT US</span></span></strong></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;"><span style="font-size:11.0pt;line-height:115%;font-family:Arial;
                  Calibri;mso-themetint:166;">In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at:</span></div>
               <div class="MsoNormal" style="line-height: 1.5; text-align: left;"><br></div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  <span style="font-size: 15px;">
                     <span style="">
                        <bdt class="question"><strong>support@aiescape.io</strong></bdt>
                        <strong>
                           <bdt class="block-component"></bdt>
                        </strong>
                     </span>
                     <strong>
                        <bdt class="block-component"></bdt>
                     </strong>
                  </span>
               </div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  <strong>
                     <strong>
                        <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
                           Calibri;mso-themetint:166;">
                           <strong>
                              <bdt class="block-component"></bdt>
                           </strong>
                        </span>
                     </strong>
                  </strong>
               </div>
               <div class="MsoNormal" data-custom-class="body_text" style="line-height: 1.5; text-align: left;">
                  <strong>
                     <strong>
                        <span style="font-size:11.0pt;line-height:115%;font-family:Arial;
                           Calibri;mso-themetint:166;">
                           <strong>
                              <bdt class="block-component"></bdt>
                           </strong>
                        </span>
                     </strong>
                  </strong>
               </div>
            </div>
         </div>
         <style>
            ul {
            list-style-type: square;
            }
            ul > li > ul {
            list-style-type: circle;
            }
            ul > li > ul > li > ul {
            list-style-type: square;
            }
            ol li {
            font-family: Arial ;
            }
         </style>
      </div>
   </div>
</div>
`;

export default function Terms() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Terms of Service', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">AI Escape Terms of Service</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 6,
                  s: 8,
                },
              },
            ]}
          >
            <Container>
              <div dangerouslySetInnerHTML={{ __html: raw_html }} />
            </Container>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
