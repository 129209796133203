import { useContext } from 'react';
import { AppLayout } from '@cloudscape-design/components';

import { Breadcrumbs, LocalNavigation, NavigationContext } from '../../common/navigation';

import { CreateRoomWizard } from './wizard';

export default function CreateRoom() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Rooms', href: '/rooms' },
            { text: 'Create', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={<CreateRoomWizard />}
      contentType="wizard"
      headerSelector=".top-navigation"
      maxContentWidth={Number.MAX_VALUE}
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
