import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Header,
  SpaceBetween,
  Button,
  Table,
  Pagination,
  PropertyFilter,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

import { UserListResponse, UserTransactionResponse } from '../../../common/types';
import { getTextFilterCounterText } from '../../../common/i18n-strings';
import { useApiNoBody } from '../../../common/api';
import { getHeaderCounterText } from '../../../common/full-header';

import { filterTransactions } from './filter';

export type UserDetailsProps = {
  user: UserListResponse;
};

export function UserTransactions(props: UserDetailsProps) {
  const { user } = props;
  const pageSize = 10;
  const resourceName = 'Transaction';
  const [transactions, setTransactions] = useState<UserTransactionResponse[]>([]);

  const [transactionsApi, error, loading] = useApiNoBody<UserTransactionResponse[]>(
    `/users/${user.id}/transactions`,
    'GET',
    setTransactions,
  );

  const fetchTransactions = async () => {
    await transactionsApi();
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const {
    items,
    actions,
    collectionProps,
    propertyFilterProps,
    paginationProps,
    filteredItemsCount,
  } = useCollection(transactions, {
    propertyFiltering: {
      filteringProperties: [
        {
          key: 'type',
          operators: ['=', '!='],
          propertyLabel: 'Type',
          groupValuesLabel: 'Type values',
        },
      ],
      filteringFunction: filterTransactions,
      empty: (
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          {error ? (
            <SpaceBetween size="xxs">
              <div>
                <b>Error retrieving {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  {error}
                </Box>
              </div>
              <Button onClick={fetchTransactions}>Retry</Button>
            </SpaceBetween>
          ) : (
            <SpaceBetween size="xxs">
              <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box color="inherit" variant="p">
                  No {resourceName.toLowerCase()}s found.
                </Box>
              </div>
            </SpaceBetween>
          )}
        </Box>
      ),
      noMatch: (
        <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
          <SpaceBetween size="xxs">
            <div>
              <b>No matches</b>
              <Box color="inherit" variant="p">
                We can&apos;t find a match.
              </Box>
            </div>
            <Button onClick={() => actions.setPropertyFiltering({ operation: 'and', tokens: [] })}>
              Clear filter
            </Button>
          </SpaceBetween>
        </Box>
      ),
    },
    pagination: { pageSize },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: 'created',
          sortingComparator(a, b) {
            const x = new Date(a.created_at);
            const y = new Date(b.created_at);

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        isDescending: true,
      },
    },
  });

  return (
    <Table
      {...collectionProps}
      {...propertyFilterProps}
      {...paginationProps}
      columnDefinitions={[
        {
          id: 'created',
          header: 'Created',
          cell: (e) => new Date(e.created_at + 'Z').toLocaleString(),
          sortingField: 'created',
          sortingComparator(a, b) {
            const x = new Date(a.created_at);
            const y = new Date(b.created_at);

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'price',
          header: 'Price',
          cell: (e) => `$${(parseFloat(e.price) / 100).toFixed(2)} USD`,
          sortingField: 'price',
          sortingComparator(a, b) {
            const x = parseFloat(a.price);
            const y = parseFloat(b.price);

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'Type',
          header: 'Type',
          cell: (e) => e.type,
          sortingField: 'type',
          sortingComparator(a, b) {
            const x = a.type.toLowerCase();
            const y = b.type.toLowerCase();

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
        {
          id: 'units',
          header: 'Units',
          cell: (e) => e.units,
          sortingField: 'units',
          sortingComparator(a, b) {
            const x = a.units;
            const y = b.units;

            return x < y ? -1 : x > y ? 1 : 0;
          },
        },
      ]}
      filter={
        <PropertyFilter
          {...propertyFilterProps}
          countText={getTextFilterCounterText(filteredItemsCount || 0)}
          disabled={loading}
          filteringOptions={[
            {
              propertyKey: 'type',
              value: 'Credits',
            },
          ]}
        />
      }
      header={
        <Header counter={getHeaderCounterText(transactions, collectionProps.selectedItems)}>
          Transactions
        </Header>
      }
      items={items}
      loading={loading}
      loadingText="Loading transactions"
      pagination={<Pagination {...paginationProps} disabled={loading} />}
      // selectedItems={selectedItems}
      // selectionType="multi"
      trackBy="uuid"
      // onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
    />
  );
}
