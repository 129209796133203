import { Button, SpaceBetween } from '@cloudscape-design/components';
import { useCallback, useEffect, useRef, useState } from 'react';

export type EscapeChatTTSProps = {
  autoPlay?: boolean;
  src: string;
};

export function EscapeChatTTS(props: EscapeChatTTSProps) {
  const src = props.src;
  const [isPlaying, setIsPlaying] = useState(props.autoPlay ? true : false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const progressBarRef = useRef<HTMLInputElement>(null);
  const playAnimationRef = useRef<number>();
  const [duration, setDuration] = useState(0);

  const repeat = useCallback(() => {
    if (audioRef.current && progressBarRef.current) {
      const currentTime = audioRef.current.currentTime;
      progressBarRef.current.value = currentTime.toString();
      progressBarRef.current.style.setProperty(
        '--range-progress',
        `${(parseFloat(progressBarRef.current.value) / duration) * 100}%`,
      );
    }

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, progressBarRef]);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }

    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [isPlaying, audioRef, repeat]);

  const onLoadedMetadata = () => {
    if (audioRef.current && progressBarRef.current) {
      setDuration(audioRef.current.duration);
      progressBarRef.current.max = audioRef.current.duration.toString();
    }
  };

  return (
    <div className="escape-chat-tts">
      <SpaceBetween direction="horizontal" size="xs">
        {isPlaying ? (
          <Button
            iconSvg={
              <svg key="0" focusable="false" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
                <polygon fill="currentColor" points="0,0 15,0 15,60 0,60" />
                <polygon fill="currentColor" points="25,0 40,0 40,60 25,60" />
              </svg>
            }
            variant="icon"
            onClick={() => setIsPlaying(false)}
          />
        ) : (
          <Button
            iconSvg={
              <svg key="0" focusable="false" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
                <polygon fill="currentColor" points="0,0 50,30 0,60" />
              </svg>
            }
            variant="icon"
            onClick={() => setIsPlaying(true)}
          />
        )}
        <div className="tts-progress">
          <input
            ref={progressBarRef}
            className="tts-progress-range"
            defaultValue={0}
            type="range"
            onChange={(e) => {
              if (audioRef.current) {
                audioRef.current.currentTime = parseFloat(e.target.value);
              }
            }}
          />
        </div>
      </SpaceBetween>
      <audio ref={audioRef} src={src} onLoadedMetadata={onLoadedMetadata} />
    </div>
  );
}
