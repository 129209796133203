import { useContext } from 'react';
import {
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  SpaceBetween,
  Container,
  Box,
} from '@cloudscape-design/components';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../common/navigation';

import { JoinWaitlist } from './join';

export default function WaitlistPage() {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  return (
    <AppLayout
      breadcrumbs={<Breadcrumbs items={[{ text: 'Waitlist', href: '' }]} />}
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Sign up for the AI Escape Waitlist</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 6,
                  s: 4,
                },
              },
              {
                colspan: {
                  default: 12,
                  l: 6,
                  s: 4,
                },
              },
            ]}
          >
            <JoinWaitlist />
            <SpaceBetween size="l">
              <Container header={<Header variant="h3">Why a Waitlist?</Header>}>
                <Box variant="p">
                  AI Escape is starting out in Beta, which means we are still working out the kinks.
                  While we are in Beta, we are limiting the number of users to ensure a smooth
                  experience. We want to make sure that we can provide the best possible AI-powered
                  escape room adventure for everyone.
                </Box>
              </Container>
              <Container header={<Header variant="h3">How it Works</Header>}>
                <Box variant="p">
                  We will be adding users to the waitlist in batches regularly. We will notify you
                  by email when you are able to join, so keep an eye on your inbox! You will receive
                  a link to join the waitlist, along with a special code you can use when you sign
                  up. After you sign up, you will be able to start playing immediately for free!
                </Box>
              </Container>
            </SpaceBetween>
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
