import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Box,
  Header,
  AppLayout,
  ContentLayout,
  Grid,
  Link,
  Spinner,
  SpaceBetween,
  Alert,
} from '@cloudscape-design/components';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LocalNavigation, Breadcrumbs, NavigationContext } from '../../common/navigation';
import { CreditsPurchaseStatusRequest, CreditsPurchaseStatusResponse } from '../../common/types';
import UserContext from '../../common/user';
import { useApi } from '../../common/api';

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function CreditsSuccess() {
  const { credits, setCredits } = useContext(UserContext);
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const session_id = params.get('session_id');

  const [purchasedCredits, setPurchasedCredits] = useState<number>(0);
  const [active, setActive] = useState<boolean>(false);

  const [purchaseApi, purchaseError, purchaseLoading] = useApi<
    CreditsPurchaseStatusRequest,
    CreditsPurchaseStatusResponse
  >('/user/credits/purchase', 'GET');

  // polling until credits are active
  const purchase = async () => {
    if (!session_id) {
      return;
    }

    while (!active) {
      await sleep(1000);
      const result = await purchaseApi(undefined, undefined, {
        session_id,
      });

      if (result.error) {
        break;
      }

      // poll until credits have been updated
      if (result.data && result.data.credits !== credits) {
        setCredits(result.data.credits);
        setPurchasedCredits(result.data.purchased);
        setActive(true);
        break;
      } else {
        await sleep(1000);
      }
    }
  };

  useEffect(() => {
    purchase();
  }, [session_id]);

  return (
    <AppLayout
      breadcrumbs={
        <Breadcrumbs
          items={[
            { text: 'Credits', href: '/credits' },
            { text: 'Purchased', href: '' },
          ]}
        />
      }
      className="app-layout"
      content={
        <ContentLayout header={<Header variant="h1">Credits Purchased</Header>}>
          <Grid
            gridDefinition={[
              {
                colspan: {
                  default: 12,
                  l: 6,
                  s: 4,
                },
              },
            ]}
          >
            {active ? (
              <Container header={<Header variant="h2">Successfully Purchased Credits</Header>}>
                <Box variant="p">
                  You have purchased{' '}
                  {purchasedCredits === 1
                    ? '1 credit'
                    : `${purchasedCredits.toLocaleString()} credits`}
                  !
                </Box>
                <Box variant="p">
                  You now have {credits === 1 ? '1 credit' : `${credits.toLocaleString()} credits`}{' '}
                  available to use!
                </Box>
                <Box variant="p">
                  <Link
                    href="/rooms/create"
                    variant="secondary"
                    onFollow={(event) => {
                      event.preventDefault();
                      navigate('/rooms/create');
                    }}
                  >
                    Create
                  </Link>{' '}
                  a room to get started solving puzzles!
                </Box>
              </Container>
            ) : (
              <SpaceBetween size="l">
                <Container header={<Header variant="h2">Purchasing Credits</Header>}>
                  <Box variant="p">Your credits are being purchased. Please wait...</Box>
                  <Spinner />
                </Container>
                {purchaseError ? (
                  <Alert header="Credits Status Error" statusIconAriaLabel="Error" type="error">
                    {purchaseError}
                  </Alert>
                ) : null}
              </SpaceBetween>
            )}
          </Grid>
        </ContentLayout>
      }
      contentType="default"
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
